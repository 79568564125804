import * as React from 'react'
import { LoadingSpinnerIcon } from '@/components/SvgIcon'

interface LoadingOverlayProps {
  isOpen: boolean
  text?: string
  size?: number
  color?: string
  className?: string
  progress?: number
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | 'center'
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isOpen,
  text = '出力中',
  size = 150,
  color = '#4B7AAA',
  className = '',
  progress = 0,
  position = 'center',
}) => {
  if (!isOpen) return null
  const positionStyles = {
    'top-right': 'top-4 right-4',
    'top-left': 'top-4 left-4',
    'bottom-right': 'bottom-4 right-4',
    'bottom-left': 'bottom-4 left-4',
    center: 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
  }

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-30 pointer-events-none z-40"
        aria-hidden="true"
      ></div>
      <div
        className={`
      fixed ${positionStyles[position]} z-50
      flex flex-col items-center justify-center p-6
      bg-white
      rounded-xl shadow-lg
      ${className}
    `}
      >
        <div className="flex flex-col items-center">
          <LoadingSpinnerIcon
            size={size}
            color={color}
            text={text}
            className="transform transition-all duration-300"
          />
        </div>
      </div>
    </>
  )
}

export default LoadingOverlay
