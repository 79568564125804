import { Button } from '@/components/Button'
import { GoogleMapViewer, MapHandles } from '@/components/GoogleMapViewer/Properties'
import { Input } from '@/components/Input'
import Base from '@/components/Layout/Base'
import { OCRLayoutPropertiesPage as OCRLayout } from '@/components/Layout/Ocr'
import { RoadAccessRow } from '@/components/Layout/RoadAccessRow'
import { TransportationRow } from '@/components/Layout/TransportationRow'
import { AreaInfoHandRow } from '@/components/Layout/AreaInfoHandRow'
import { Tab } from '@headlessui/react'

import {
  zoningAreaCodes,
  areaOfUseCodes,
  fireProtectionCodes,
  altitudeAreaCodes,
  shadowAreaCodes,
  getUnspecifiedText,
} from '@/models/areaInfoCodeshashToArray'
import { AcceptableMimeTypes } from '@/components/Ocr/file'
import { RadioButton } from '@/components/RadioButton'
import { Select } from '@/components/Select'
import { Row, Table, Td, Th } from '@/components/Table'
import { Textarea } from '@/components/Textarea'
import { previousCity } from '@/models/previousCity'

import {
  buildingStructureOptions,
  localSituationOptions,
  propertyCertificateOfInspectionOptions,
  propertyStatusOptions,
  propertyTypeOptions,
} from '@/models/property'
import { negotiationMethodOptions } from '@/models/proposal'
import type { LatLng, Memo, Property, Transportation, AreaInfoHandData } from '@/types/property'
import type { RoadAccess } from '@/types/roadAccess'
import type { User } from '@/types/user'
import { calculateTsubo } from '@/utils/area'
import {
  cammedFormat,
  dataPrecisionSetup,
  formatNumber,
  intFormat,
  formatDecimalToPercentage,
  formatPercentageToDecimal,
} from '@/utils/cammedFormat'
import { searchTransportaiton } from '@/utils/searchTransportaiton'
import { useJsApiLoader } from '@react-google-maps/api'
import { differenceInDays, format, parseISO, startOfDay, startOfToday } from 'date-fns'
import * as React from 'react'

import { Address } from '@/components/Address'
import { Breadcrumb } from '@/components/Breadcrumb'
import { CommentForm } from '@/components/CommentForm'
import { ConfirmDialog } from '@/components/ConfirmDialog'
import { DropDown, Option } from '@/components/DropDown/Index'
import { ErrorMessage } from '@/components/ErrorMessage'
import { FileUpload, FileUploadHandle } from '@/components/FileUpload'
import { ocrExecution } from '@/components/Ocr'
import { OCRPreviewZone } from '@/components/Ocr/FileUploader'
import { FileData } from '@/components/Ocr/file'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import { unit_m2 } from '../VolumeCheckRequests/volumeUnitFormat'
import AreaInfo from './AreaInfo'
import quickVolumeCheck from './quickVolumeCheck'
import { quickVolumeResult } from './quickVolumeResult'

const defaultTransportationData = {
  bus: 0,
  minutes_on_foot: 0,
  nearest_station: '',
  transportation: '',
  walking: 0,
}
const defaultRoadAccessData = {
  direction: 0,
  road_type: 0,
  width: '',
  frontage: '',
  building_standards_law_articles: 0,
}
const defaultAreaInfoHandData = {
  zoning_area: '',
  youto_chiiki_hand: '',
  bouka_chiiki_hand: '',
  koudo_chiku_hand: '',
  shadow_area_hand: '',
  building_coverage_ratio_hand: '',
  floor_area_ratio_hand: '',
  other_info: '',
}
const libraries = ['geometry', 'drawing', 'places']
interface RequiredItem {
  column_name: string
  items?: Record<string, string[]>
}
interface DisplayItem {
  column_name: string
  value: any
  item_type: number
}
type Props = {
  is_status_blank: boolean
  google_maps_api_key: string
  polygons_api_base_url: string
  current_user: User
  users: User[]
  property: Property
  shape?: LatLng[]
  memos: Memo[]
  status: string
  authenticity_token?: string
  is_edit: boolean
  m3_tsubo_ratio: number
  error_messages: string[]
  same_places: Property[]
  recepted_at?: string
  sources_list?: string[]
  sources_branch_list?: string[]
  sources_staff_list?: string[]
  is_enable_attachments: boolean
  trans_infos?: Transportation
  area_info_hands?: AreaInfoHandData
  road_accesses: RoadAccess[]
  instant_create: boolean
  ocr_mode_enabled: boolean
  ocr_area_info: string
  ocr_file_blob_id: string
  ocr_api_endpoint: string
  file_data: FileData
  bucket: string
  projects_layouts?: string[]
  projects_layouts_no_mapping?: string[]
  required_items: RequiredItem[]
  display_items: DisplayItem[]
}

const PropertiesEditAndNewPage: React.FC<Props> = ({
  is_status_blank = false,
  is_edit = false,
  m3_tsubo_ratio,
  google_maps_api_key,
  polygons_api_base_url,
  current_user,
  users,
  property,
  shape,
  memos,
  same_places,
  recepted_at,
  sources_list,
  sources_branch_list,
  sources_staff_list,
  is_enable_attachments,
  trans_infos,
  road_accesses,
  area_info_hands,
  instant_create,
  ocr_mode_enabled,
  ocr_area_info,
  ocr_file_blob_id,
  file_data,
  authenticity_token,
  error_messages,
  projects_layouts,
  projects_layouts_no_mapping,
  required_items,
  display_items,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: libraries,
  })
  const title = is_edit ? '物件編集' : '物件登録'
  const [latLng, setLatLng] = React.useState<{ lat: number; lng: number }>({})
  const [propertyLatLng, setPropertyLatLng] = React.useState<{ lat: number; lng: number }>({
    lat: property.lat,
    lng: property.lng,
  })
  const [propertyShape, setPropertyShape] = React.useState<string>(
    shape ? JSON.stringify(shape) : null
  )
  const attachedFileRef = React.useRef<FileUploadHandle>(null)

  const [propertyData, setPropertyData] = React.useState<Property>()
  const [currentSourceUser, setCurrentSourceUser] = React.useState<Option | null>(null)
  const [currentPropertyUser, setCurrentPropertyUser] = React.useState<Option | null>(null)
  const [currentNegotiationMethod, setCurrentNegotiationMethod] = React.useState<Option | null>(
    null
  )
  const [usersOptions, setUsersOptions] = React.useState<Option[]>([])
  const [tagsOptions, setTagsOptions] = React.useState<{ value: string; text: string }[]>([])
  const [businessTypeOptions, setBusinessTypeOptions] = React.useState<
    { value: string; text: string }[]
  >([])
  const mapRef = React.useRef<MapHandles>(null)
  const [fileName, setFileName] = React.useState('')
  const [inputKey, setInputKey] = React.useState(Date.now())
  const fileRef = React.useRef<HTMLInputElement>(null)
  const fileRefCreateForm = React.useRef<FileUploadHandle>(null)
  const [ocr, setOcr] = React.useState<ocrExecution>({
    localFileValidity: true,
  } as unknown as ocrExecution)
  React.useEffect(() => {
    const fileRef = fileRefCreateForm
    if (file_data && file_data.file_base64_data && file_data.file_name) {
      fileRef.current?.setFile(file_data?.file_base64_data, file_data?.file_name)
    } else if (ocr.localFile) {
      fileRef.current?.setFile(ocr.localFile, ocr.localFile.name)
    }
  })
  const receptedAtNew = recepted_at
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [chibanInfo, setChibanInfo] = React.useState(property?.chiban ?? '')
  const [inputChibanInfo, setInputChibanInfo] = React.useState('')
  const [newMemo, setNewMemo] = React.useState<string>('')
  const fileButtonStyles = {
    fileButton: `
      flex
      md:inline-flex
      align
      items-center
      justify-center
      gap-1.5
      rounded-sm
      leading-none
      duration-200
      transition-opacity
      hover:opacity-80
      rounded-sm
      border
      box-border
      border-primary-font
      text-primary-font
      py-[5px]
      md:py-[7px]
      px-4
      text-sm
      cursor-pointer
    `,
  }
  const tabStyles = {
    tab: `
      inline-flex
      outline-none
      select-none
      text-sm
      py-2
      px-8
      border-t-[1px]
      border-r-[1px]
      border-primary
    `,
    true: `
      bg-primary
      text-white
    `,
    false: `
      text-primary-font
      border-t-[1px]
      border-r-[1px]
      border-primary
    `,
  }

  // ドロップされたファイルを処理
  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    processFile(file)
  }

  // ドラッグオーバー時のデフォルト動作を無効化
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
  }

  // ファイルをバリデートして処理
  const processFile = (file) => {
    if (
      !file ||
      file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      alert('物件情報のインポートはExcelファイルのみ対応しています。')
      fileRef.current.value = '' // ファイルをクリア
      return
    }

    const fileName = file.name
    setFileName(fileName)
    requestPropertyDataFromExcel(file) // ファイルを処理する関数を呼び出し
  }

  const allTabs: readonly string[] = ['ocr', 'importSheet']

  if (
    ocr_mode_enabled &&
    (same_places.length > 0 || error_messages.length > 0) &&
    ocr.jobStatus != 'validationError'
  ) {
    setOcr((prevState) => {
      return { ...prevState, jobStatus: 'validationError', blobSignedId: ocr_file_blob_id }
    })
  }
  !chibanInfo && propertyData?.chiban && setChibanInfo(propertyData?.chiban)

  React.useEffect(() => {
    setInputChibanInfo(chibanInfo)
  }, [chibanInfo])

  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  React.useEffect(() => {
    if (propertyLatLng.lat === null || propertyLatLng.lng === null) {
      return
    }
    if (ocr?.isSkipSearchTra) {
      setOcr((prevState) => {
        return { ...prevState, isSkipSearchTra: false }
      })
      return
    }
    setPropertyData((prevData) => {
      const updatedData = {
        ...prevData,
        transportations: [defaultTransportationData],
      }
      if (!isLoaded) {
        return updatedData
      }
      searchTransportaiton(updatedData, setPropertyData, propertyLatLng)
      return updatedData
    })
  }, [propertyLatLng])

  const hasLimitedAreaInfoHands = React.useRef(false)

  React.useEffect(() => {
    const maxItems = 4
    if (!hasLimitedAreaInfoHands.current && propertyData?.area_info_hands?.length > maxItems) {
      const limitedData = propertyData.area_info_hands.slice(0, maxItems)
      setPropertyData((prevData) => ({
        ...prevData,
        area_info_hands: limitedData,
      }))
      hasLimitedAreaInfoHands.current = true
    }
  }, [propertyData?.area_info_hands])

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const latlng: LatLng = { lat: 0, lng: 0 }

    if (searchParams.get('lat')) {
      const lat = searchParams.get('lat')
      latlng.lat = Number(lat)
    } else {
      latlng.lat = property.address_geocoding[0] ?? (property.lat as number) ?? latLng.lat
    }

    if (searchParams.get('lng')) {
      const lng = searchParams.get('lng')
      latlng.lng = Number(lng)
    } else {
      latlng.lng = property.address_geocoding[1] ?? (property.lng as number) ?? latLng.lng
    }

    setLatLng({
      ...latlng,
    })

    let receptedAtDefault =
      is_edit && property.ongoing_proposal?.recepted_at
        ? parseISO(property.ongoing_proposal.recepted_at)
        : parseISO(new Date().toISOString())
    if (receptedAtNew) {
      receptedAtDefault = parseISO(receptedAtNew)
    }

    if (!property.ongoing_proposal && !receptedAtNew) {
      receptedAtDefault.setSeconds(0)
    }

    const sourced_at = format(receptedAtDefault, 'yyyy-MM-dd')

    const recepted_at = format(receptedAtDefault, 'yyyy-MM-dd HH:mm')

    const propertyData = {
      ...property,
      city: previousCity.includes(property?.city) ? `${property?.city}(再編前)` : property?.city,
      ongoing: is_status_blank ? 'ongoing' : property.ongoing_proposal ? 'ongoing' : 'yet',
      tag_value: property.tag_name,
      source_user_id: is_edit
        ? property.ongoing_proposal?.source_user_id ?? property.proposals[0]?.source_user_id ?? null
        : property.proposals[0]?.source_user_id || current_user.id,
      user_id: property.user_id ?? null,
      source_department_name: is_edit
        ? property.ongoing_proposal?.source_user?.department?.name ??
          property.proposals[0]?.source_user?.department?.name ??
          '(未設定)'
        : property.proposals[0]?.source_user?.department?.name || current_user.department?.name,
      department_name: property.ongoing_proposal
        ? property.user?.department?.name
        : current_user.department?.name ?? '(未設定)',
      source: is_edit
        ? property.ongoing_proposal?.source ?? ''
        : property.proposals[0]?.source ?? '',
      source_branch: is_edit
        ? property.ongoing_proposal?.source_branch ?? ''
        : property.proposals[0]?.source_branch ?? '',
      source_staff: is_edit
        ? property.ongoing_proposal?.source_staff ?? ''
        : property.proposals[0]?.source_staff ?? '',
      seller: property.ongoing_proposal?.seller,
      negotiation_method: is_edit
        ? property.ongoing_proposal?.negotiation_method ?? ''
        : property.proposals[0]?.negotiation_method ?? '',
      suggested_total_price: property?.ongoing_proposal?.suggested_total_price
        ? cammedFormat(Number(property?.ongoing_proposal?.suggested_total_price))
        : '',
      suggested_primary_unit_price: property?.ongoing_proposal?.suggested_primary_unit_price,
      purchase_price: property?.ongoing_proposal?.purchase_price
        ? cammedFormat(Number(property?.ongoing_proposal?.purchase_price))
        : '',
      purchase_primary_unit_price: property?.ongoing_proposal?.purchase_primary_unit_price,
      total_expense_cost_purchase: property?.total_expense_cost_purchase
        ? cammedFormat(Number(property?.total_expense_cost_purchase))
        : '',
      current_rent: property?.current_rent ? cammedFormat(Number(property?.current_rent)) : '',
      net_income: property?.net_income ? cammedFormat(Number(property?.net_income)) : '',
      note: is_edit ? property?.ongoing_proposal?.note : property.proposals[0]?.note,
      sourced_at: sourced_at,
      recepted_at: recepted_at,
      passed_day: property?.ongoing_proposal?.passed_day ?? 0,
      tenancy_rate: property?.tenancy_rate ? formatDecimalToPercentage(property?.tenancy_rate) : 0,
      number_of_buildings: property?.number_of_buildings
        ? cammedFormat(property?.number_of_buildings)
        : '',
      number_of_units: property?.number_of_units ? cammedFormat(property?.number_of_units) : '',
      transportations: trans_infos?.length > 0 ? trans_infos : [defaultTransportationData],
      road_accesses: road_accesses?.length > 0 ? road_accesses : [defaultRoadAccessData],
      area_info_hands: area_info_hands?.length > 0 ? area_info_hands : [defaultAreaInfoHandData],
      area_m3: property?.area_m3 ? cammedFormat(property?.area_m3, 2) : '',
      effective_area: property?.effective_area ? cammedFormat(property?.effective_area, 2) : '',
      total_floor_area: property?.total_floor_area
        ? cammedFormat(property?.total_floor_area, 2)
        : '',
      occupied_area: property?.occupied_area ? cammedFormat(property?.occupied_area, 2) : '',
      area_tsubo: property?.area_tsubo ? cammedFormat(property?.area_tsubo, 2) : '',
      effective_area_tsubo: property?.effective_area_tsubo
        ? cammedFormat(property?.effective_area_tsubo, 2)
        : '',
      total_floor_area_tsubo: property?.total_floor_area_tsubo
        ? cammedFormat(property?.total_floor_area_tsubo, 2)
        : '',
      occupied_area_tsubo: property?.occupied_area_tsubo
        ? cammedFormat(property?.occupied_area_tsubo, 2)
        : '',
      coverage_ratio: property?.coverage_ratio ? cammedFormat(property?.coverage_ratio, 2) : '',
      volume_rate: property?.volume_rate ? cammedFormat(property?.volume_rate, 2) : '',
      floors_above_ground: property?.floors_above_ground
        ? cammedFormat(property?.floors_above_ground)
        : '',
      floors_under_ground: property?.floors_under_ground
        ? cammedFormat(property?.floors_under_ground)
        : '',
      building_construction_cost: property?.building_construction_cost
        ? cammedFormat(property?.building_construction_cost)
        : '',
      land_price: property?.land_price ? cammedFormat(property?.land_price) : '',
      building_price: property?.building_price ? cammedFormat(property?.building_price) : '',
      total_expense_cost_price: property?.total_expense_cost_price
        ? cammedFormat(Number(property?.total_expense_cost_price))
        : '',
      business_income: property?.business_income ? cammedFormat(property?.business_income) : '',
      rent_per_tsubo: property?.rent_per_tsubo ? cammedFormat(property?.rent_per_tsubo) : '',
      total_rent: property?.total_rent ? cammedFormat(property?.total_rent) : '',
      prospective_noi: property?.prospective_noi ? cammedFormat(property?.prospective_noi) : '',
      area_info: property?.area_info ? property?.area_info : '',
      shape: property?.shape ? property?.shape : '',
      ocr_area_info: ocr_area_info ? JSON.parse(ocr_area_info) : '',
      instant_city_plannings: property?.instant_city_plannings
        ? property?.instant_city_plannings
        : [],
      property_type: is_edit
        ? property.property_type
        : property?.property_type
        ? property.property_type
        : checkRequiredItem('property_type')
        ? '土地'
        : null,
    }

    const suggestedUnitPriceChangedData = suggestedUnitPriceChanged(propertyData)
    const purchasePriceChangedData = purchasePriceChanged(propertyData)
    setPropertyData({
      ...propertyData,
      ...suggestedUnitPriceChangedData,
      ...purchasePriceChangedData,
    })

    const usersOptions = [
      ...users.map((user) => ({
        value: Number(user.id),
        label: (user.name ?? '') + ' / ' + (user.department?.name ?? '（なし）'),
      })),
    ]
    setUsersOptions(usersOptions)

    setCurrentSourceUser(usersOptions.find((user) => user.value === propertyData?.source_user_id))
    setCurrentPropertyUser(
      usersOptions.find((user) => user.value === (propertyData?.user_id || current_user.id))
    )
    setCurrentNegotiationMethod(
      negotiationMethodOptions.find((event) => event.value === propertyData.negotiation_method)
    )

    const tags = property?.company?.tags ?? []
    setTagsOptions([
      ...tags
        .sort((a, b) => a.order - b.order)
        .map((tag) => ({
          value: String(tag.id),
          text: tag.name,
        })),
    ])
    const business_types = current_user?.company?.business_types ?? []
    setBusinessTypeOptions([
      ...business_types
        .sort((a, b) => a.order - b.order)
        .map((business_type) => ({
          value: String(business_type.id),
          text: business_type.name,
        })),
    ])
  }, [])

  const onChangeCurrentSourceUser = (e) => {
    setCurrentSourceUser(e)
    if (!is_edit) {
      setCurrentPropertyUser(e)
    }
    const source_user_id = e?.value
    const user = current_user.company.users.find((user) => user.id === source_user_id)
    setPropertyData({
      ...propertyData,
      source_user_id,
      source_department_name: user?.department?.name ?? '',
      user_id: !is_edit ? source_user_id : propertyData.user_id,
      department_name: !is_edit ? user?.department?.name ?? '' : propertyData.department_name,
    })
  }

  const onChangeCurrentPropertyUser = (e) => {
    setCurrentPropertyUser(e)
    const user_id = e?.value
    const user = current_user.company.users.find((user) => user.id === user_id)
    setPropertyData({
      ...propertyData,
      user_id,
      department_name:
        propertyData?.ongoing === 'ongoing'
          ? user?.department?.name ?? current_user.department?.name ?? '(未設定)'
          : '(未設定)',
    })
  }

  const onChangeCurrentNegotiationMethod = (e) => {
    setCurrentNegotiationMethod(e)
    const negotiation_method = e?.value
    setPropertyData({
      ...propertyData,
      negotiation_method,
    })
  }

  const formatChiban = (value: string): string => {
    const match = value.match(/[0-9\-０-９ーー―‐ー−－,]/g)
    value = match === null ? '' : match.join('')
    value = value.replace(/[０-９ーー―‐ー−－]/g, function (c) {
      return c.match(/[ーー―‐ー−－]/) ? '-' : String.fromCharCode(c.charCodeAt(0) - 0xfee0)
    })
    const arr = value.split(',').filter((s) => s.length > 0)
    const set = new Set(arr)

    return Array.from(set).join(', ')
  }

  const dataNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    let match = value.match(/[0-9|０-９]/g)
    if (match === null) {
      e.target.value = ''
      return
    }
    match =
      e.target.id == 'property_chiban'
        ? value.match(/[0-9\-０-９ーー―‐ー−,，、。/\.]/g)
        : value.match(/[0-9\-０-９ーー―‐ー−]/g)
    value = match.join('')
    value = value
      .replace(/[０-９ーー―‐ー−]/g, function (c) {
        return c.match(/[ーー―‐ー−]/) ? '-' : String.fromCharCode(c.charCodeAt(0) - 0xfee0)
      })
      .replace(/^-+/, '')
      .replace(/-+$/, '')
      .replace(/-{2,}/g, '-')
    if (e.target.id == 'property_chiban') {
      value = value
        .replace(/[、，/\.]/g, ',')
        .replace(/,{2,}/g, ',')
        .replace(/-,/g, ',')
        .replace(/,-/g, ',')
        .replace(/,{2,}/g, ',')
        .replace(/^,/, '')
        .replace(/,$/, '')
      const arr = value.split(',')
      const set = new Set(arr)
      value = Array.from(set).join(', ')
    }
    return value
  }

  const suggestedUnitPriceChanged = (propertyData: Property, property_type = null) => {
    const unitPriceData = unitPriceChanged(
      propertyData?.suggested_total_price,
      propertyData?.suggested_primary_unit_price,
      propertyData?.volume_rate,
      property_type,
      getBaseArea('suggested_unit_price', propertyData)
    )

    return {
      suggested_unit_price: unitPriceData.unit_price,
      suggested_primary_unit_price: unitPriceData.primary_unit_price,
    }
  }

  const purchasePriceChanged = (propertyData: Property, property_type = null) => {
    const unitPriceData = unitPriceChanged(
      propertyData?.purchase_price,
      propertyData?.purchase_primary_unit_price,
      propertyData?.volume_rate,
      property_type,
      getBaseArea('purchase_price', propertyData)
    )

    return {
      purchase_unit_price: unitPriceData.unit_price,
      purchase_primary_unit_price: unitPriceData.primary_unit_price,
    }
  }

  const unitPriceChanged = (
    price?: number,
    primary_unit_price?: number,
    property_volume_rate?: number,
    property_type: string = null,
    property_base_area: number
  ) => {
    const suggested_total_price = price ? dataPrecisionSetup(String(price), 12, 0) : 0
    const volume_rate = property_volume_rate ? intFormat(String(property_volume_rate)) : 0
    let suggested_primary_unit_price = primary_unit_price
      ? dataPrecisionSetup(String(primary_unit_price), 12, 0)
      : 0
    let suggested_unit_price_value = 0
    let base_area = 0

    if (property_type === '土地') {
      base_area = intFormat(String(property['effective_area']))
    } else if (property_type === '土地＋建物（一棟）' || property_type === '土地＋建物（区分）') {
      base_area = intFormat(String(property['occupied_area']))
    } else {
      base_area = property_base_area
    }

    if (base_area > 0) {
      suggested_unit_price_value =
        suggested_total_price / (Math.floor(base_area * m3_tsubo_ratio * 100) / 100)
    }

    if (suggested_unit_price_value != 0 && Number(volume_rate) != 0) {
      suggested_primary_unit_price = Math.floor((suggested_unit_price_value / volume_rate) * 100)
    } else {
      suggested_primary_unit_price = 0
    }

    const data = {
      unit_price: suggested_unit_price_value || '',
      primary_unit_price: suggested_primary_unit_price || '',
    }

    return data
  }

  function totalFloorAreaChanged(propertyData: Property) {
    const data = {}

    const total_floor_area = propertyData?.total_floor_area
      ? dataPrecisionSetup(String(propertyData?.total_floor_area), 12, 0)
      : 0

    let occupied_area = propertyData?.occupied_area
      ? dataPrecisionSetup(String(propertyData?.occupied_area), 12, 0)
      : 0

    if (total_floor_area && !occupied_area) {
      occupied_area = total_floor_area
      data['occupied_area'] = occupied_area ? cammedFormat(occupied_area, 2) : ''
      data['occupied_area_tsubo'] = occupied_area ? calculateTsubo(occupied_area) : ''
    }

    return data
  }

  const calculateAmountOfSales = (propertyData: Property) => {
    const land_price = propertyData?.land_price
      ? dataPrecisionSetup(String(propertyData?.land_price), 12, 0)
      : 0
    const building_price = propertyData?.building_price
      ? dataPrecisionSetup(String(propertyData?.building_price), 12, 0)
      : 0
    if (land_price === 0 && building_price === 0) {
      return {
        selling_price_without_tax: '',
        selling_price: '',
        selling_unit_price: '',
        selling_primary_unit_price: '',
      }
    } else {
      const selling_price_without_tax = land_price + building_price
      const consumption_tax = ((propertyData?.consumption_tax as number) || 0) + 1
      const building_price_with_tax = Math.floor(building_price * consumption_tax)
      const selling_price = land_price + building_price_with_tax
      const volume_rate = (propertyData?.volume_rate as number) || 0

      let selling_unit_price = 0
      let selling_primary_unit_price = 0
      if (getBaseArea('selling_unit_price', propertyData) > 0) {
        selling_unit_price =
          selling_price /
          (Math.floor(getBaseArea('selling_unit_price', propertyData) * m3_tsubo_ratio * 100) / 100)
      } else {
        selling_unit_price = 0
      }
      if (building_price === 0 && selling_unit_price != 0 && Number(volume_rate) != 0) {
        selling_primary_unit_price = Math.floor((selling_unit_price / volume_rate) * 100)
      } else {
        selling_primary_unit_price = 0
      }

      const data = {
        selling_price_without_tax: selling_price_without_tax || '',
        selling_primary_unit_price: selling_primary_unit_price || '',
        selling_price: selling_price || '',
        selling_unit_price: selling_unit_price || '',
      }
      return data
    }
  }

  const calculateBusinessIncomeYield = (propertyData: Property) => {
    const business_income = propertyData?.business_income
      ? dataPrecisionSetup(String(propertyData?.business_income), 12, 0)
      : 0
    const land_price = propertyData?.land_price
      ? dataPrecisionSetup(String(propertyData?.land_price), 12, 0)
      : 0
    const building_price = propertyData?.building_price
      ? dataPrecisionSetup(String(propertyData?.building_price), 12, 0)
      : 0
    const selling_price_without_tax = land_price + building_price

    let business_income_yield = 0

    if (business_income === 0 || selling_price_without_tax === 0) {
      business_income_yield = 0
    } else {
      business_income_yield =
        Math.round((business_income / selling_price_without_tax) * 10000) / 100
    }

    const data = {
      business_income_yield: business_income_yield || '',
    }

    return data
  }

  const calculateProspectiveYield = (propertyData: Property) => {
    const total_rent = propertyData?.total_rent
      ? dataPrecisionSetup(String(propertyData?.total_rent), 12, 0)
      : 0
    const selling_price = propertyData?.selling_price
      ? dataPrecisionSetup(String(propertyData?.selling_price), 12, 0)
      : 0
    let prospective_yield = 0

    if (total_rent === 0 || selling_price === 0) {
      prospective_yield = 0
    } else {
      prospective_yield = Math.floor(((total_rent * 12) / selling_price) * 10000) / 100
    }
    const chageData = {
      prospective_yield: prospective_yield || '',
    }

    return chageData
  }

  const calculateProspectiveNoiYield = (propertyData: Property) => {
    const prospective_noi = propertyData?.prospective_noi
      ? dataPrecisionSetup(String(propertyData?.prospective_noi), 12, 0)
      : 0
    const selling_price = propertyData?.selling_price
      ? dataPrecisionSetup(String(propertyData?.selling_price), 12, 0)
      : 0
    let prospective_noi_yield = 0

    if (prospective_noi === 0 || selling_price === 0) {
      prospective_noi_yield = 0
    } else {
      prospective_noi_yield = Math.floor(((prospective_noi * 12) / selling_price) * 10000) / 100
    }

    const chageData = {
      prospective_noi_yield: prospective_noi_yield || '',
    }

    return chageData
  }

  const calculateCurrentProspectiveYield = (propertyData: Property) => {
    const current_rent = propertyData?.current_rent
      ? dataPrecisionSetup(String(propertyData?.current_rent), 12, 0)
      : 0
    const suggested_total_price = propertyData?.suggested_total_price
      ? dataPrecisionSetup(String(propertyData?.suggested_total_price), 12, 0)
      : 0
    const purchase_price = propertyData?.purchase_price
      ? dataPrecisionSetup(String(propertyData?.purchase_price), 12, 0)
      : 0
    let current_prospective_yield = 0

    if (current_rent !== 0 && purchase_price !== 0) {
      current_prospective_yield = Math.floor(((current_rent * 12) / purchase_price) * 10000) / 100
    } else if (current_rent === 0 || suggested_total_price === 0) {
      current_prospective_yield = 0
    } else {
      current_prospective_yield =
        Math.floor(((current_rent * 12) / suggested_total_price) * 10000) / 100
    }
    const chageData = {
      current_prospective_yield: current_prospective_yield || '',
    }
    return chageData
  }

  const calculateCurrentProspectiveNoiYield = (propertyData: Property) => {
    const net_income = propertyData?.net_income
      ? dataPrecisionSetup(String(propertyData?.net_income), 12, 0)
      : 0
    const suggested_total_price = propertyData?.suggested_total_price
      ? dataPrecisionSetup(String(propertyData?.suggested_total_price), 12, 0)
      : 0
    const purchase_price = propertyData?.purchase_price
      ? dataPrecisionSetup(String(propertyData?.purchase_price), 12, 0)
      : 0

    if (net_income === 0) {
      return { current_prospective_noi_yield: '' }
    }
    if (purchase_price !== 0) {
      return {
        current_prospective_noi_yield:
          Math.floor(((net_income * 12) / purchase_price) * 10000) / 100 || '',
      }
    }
    if (suggested_total_price !== 0) {
      return {
        current_prospective_noi_yield:
          Math.floor(((net_income * 12) / suggested_total_price) * 10000) / 100 || '',
      }
    }
    return { current_prospective_noi_yield: '' }
  }

  const calculateUnitPricePerUnitYield = (propertyData: Property) => {
    const number_of_units = propertyData?.number_of_units
      ? dataPrecisionSetup(String(propertyData?.number_of_units), 10, 0)
      : 0
    const suggested_total_price = propertyData?.suggested_total_price
      ? dataPrecisionSetup(String(propertyData?.suggested_total_price), 12, 0)
      : 0
    const purchase_price = propertyData?.purchase_price
      ? dataPrecisionSetup(String(propertyData?.purchase_price), 12, 0)
      : 0

    if (number_of_units === 0) {
      return { unit_price_per_unit_yield: '' }
    }
    if (purchase_price !== 0) {
      return { unit_price_per_unit_yield: Math.floor(purchase_price / number_of_units) || '' }
    }
    if (suggested_total_price !== 0) {
      return {
        unit_price_per_unit_yield: Math.floor(suggested_total_price / total_floor_area_tsubo) || '',
      }
    }
    return { unit_price_per_unit_yield: '' }
  }
  const calculateConstructionUnitPrice = (propertyData: Property) => {
    const total_floor_area_tsubo = intFormat(String(propertyData?.total_floor_area_tsubo))
    const building_construction_cost = intFormat(String(propertyData?.building_construction_cost))

    if (total_floor_area_tsubo !== 0 && building_construction_cost !== 0) {
      return {
        construction_unit_price:
          Math.floor(building_construction_cost / total_floor_area_tsubo) || '',
      }
    }
    return { construction_unit_price: '' }
  }

  const calculateBuildingUnitPriceYield = (propertyData: Property) => {
    const total_floor_area = propertyData?.total_floor_area
      ? dataPrecisionSetup(String(propertyData?.total_floor_area), 10, 0)
      : 0
    const suggested_total_price = propertyData?.suggested_total_price
      ? dataPrecisionSetup(String(propertyData?.suggested_total_price), 12, 0)
      : 0
    const purchase_price = propertyData?.purchase_price
      ? dataPrecisionSetup(String(propertyData?.purchase_price), 12, 0)
      : 0

    if (total_floor_area === 0) {
      return { building_unit_price_yield: '' }
    }
    if (purchase_price !== 0) {
      return {
        building_unit_price_yield:
          Math.floor(purchase_price / (total_floor_area * m3_tsubo_ratio)) || '',
      }
    }
    if (suggested_total_price !== 0) {
      return {
        building_unit_price_yield:
          Math.floor(suggested_total_price / (total_floor_area * m3_tsubo_ratio)) || '',
      }
    }
    return { building_unit_price_yield: '' }
  }

  const getBaseArea = (elem, property) => {
    let base_area_name: 'effective_area' | 'occupied_area' = 'effective_area'
    if (elem == 'suggested_unit_price' || 'purchase_price') {
      base_area_name = property?.property_type == '土地' ? 'effective_area' : 'occupied_area'
    } else {
      base_area_name = getBuildingPrice() === 0 ? 'effective_area' : 'occupied_area'
    }
    return intFormat(String(property[base_area_name]))
  }

  const getPropertyType = () => {
    return propertyData?.property_type ?? ''
  }

  const placeHolderText = {
    spaceMetrics: {
      土地: '計画建物の面積を記載してください',
      '土地＋建物（一棟）': '既存建物の面積を記載してください',
      '土地＋建物（区分）': '既存建物の面積を記載してください',
    },
    location_of_division: {
      土地: '',
      '土地＋建物（一棟）': '',
      '土地＋建物（区分）': '既存建物を記載してください',
    },
  }

  const getPlaceholderText = (propertyType, type = 'spaceMetrics') => {
    return placeHolderText[type]?.[propertyType] || ''
  }

  const getBuildingPrice = () => {
    return propertyData?.building_price ?? 0
  }

  const requestPropertyDataFromExcel = (file) => {
    if (!file) {
      return
    }
    const formData = new FormData()
    formData.append('file', file)

    fetch(`/api/properties/${property.hashid}/excel`, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': authenticity_token,
      },
    })
      .then(async (res) => {
        const json = await res.json()
        const messages =
          json.messages && json.messages.length > 0 ? json.messages : 'エラーが発生しました'
        if (res.ok) {
          assignPropertyData(json.res)
        } else {
          setInputKey(Date.now())
          setFileName('')
          fileRef.current.value = ''
          alert(messages)
        }
      })
      .catch((err) => {
        console.log(err)
        setInputKey(Date.now())
        setFileName('')
        fileRef.current.value = ''
        alert('エラーが発生しました')
      })
  }

  const assignPropertyData = (res) => {
    const columns = [
      'name',
      'location_of_division',
      'transportations',
      'purchace_applied_at',
      'purchace_contract_at',
      'purchace_settlement_at',
      'net_income',
      'current_rent',
      'note',
      'business_type_id',
      'building_structure',
      'floors_above_ground',
      'floors_under_ground',
      'building_construction_cost',
      'build_completed_at',
      'certificate_of_inspection',
      'selled_at',
      'land_price',
      'building_price',
      'selling_price_without_tax',
      'selling_price',
      'selling_unit_price',
      'selling_primary_unit_price',
      'business_income',
      'business_income_yield',
      'tenancy_rate',
      'rent_per_tsubo',
      'total_rent',
      'prospective_yield',
      'prospective_noi',
      'prospective_noi_yield',
      'prefecture',
      'city',
      'town',
      'chome',
      'chiban',
      'map_information',
      'property_type',
      'tag_id',
      'user_id',
      'local_situation',
      'total_expense_cost_purchase',
      'total_expense_cost_price',
      'buyer',
    ]
    const columnsFormat = [
      'area_m3',
      'area_tsubo',
      'effective_area',
      'effective_area_tsubo',
      'total_floor_area',
      'total_floor_area_tsubo',
      'occupied_area',
      'occupied_area_tsubo',
      'coverage_ratio',
      'volume_rate',
      'number_of_buildings',
      'number_of_units',
    ]
    const columnsProposal = [
      'source',
      'recepted_at',
      'source_branch',
      'source_staff',
      'source_user_id',
      'seller',
      'suggested_total_price',
      'suggested_unit_price',
      'suggested_primary_unit_price',
      'negotiation_method',
      'purchase_price',
    ]

    let property = { ...propertyData }

    // 「import」シートに値が設定されている場合は画面に反映する
    columns.forEach((column) => {
      if (res[column] && (column !== 'transportations' || res[column].length > 0)) {
        property[column] = res[column]
        if (column === 'user_id') {
          let user = usersOptions.find((user) => user.value === res[column])
          setCurrentPropertyUser(user)
          user = current_user.company.users.find((user) => user.id === res[column])
          property.department_name = user.department?.name
        }
      }
    })
    columnsFormat.forEach((column) => {
      if (res[column]) {
        const unit = ['number_of_buildings', 'number_of_units'].includes(column) ? 0 : 2
        property[column] = cammedFormat(res[column], unit)
      }
    })
    property.ongoing = res.ongoing_proposal ? 'ongoing' : 'yet'
    columnsProposal.forEach((column) => {
      if (res.ongoing_proposal[column]) {
        property[column] = property.ongoing_proposal[column] = res.ongoing_proposal[column]
        if (column === 'source_user_id') {
          let user = usersOptions.find((user) => user.value === res.ongoing_proposal[column])
          setCurrentSourceUser(user)
          user = current_user.company.users.find((user) => user.id === res.ongoing_proposal[column])
          property.source_department_name = user.department?.name
        } else if (column === 'negotiation_method') {
          const negotiation_method = negotiationMethodOptions.find(
            (option) => option.value === res.ongoing_proposal[column]
          )
          setCurrentNegotiationMethod(negotiation_method)
          property.negotiation_method = negotiation_method.value
        }
      }
    })
    property.road_accesses =
      road_accesses.length > 0
        ? property.road_accesses.concat(res.road_accesses)
        : res.road_accesses
    property.transportations =
      trans_infos.length > 0
        ? property.transportations.concat(res.transportations)
        : res.transportations
    property.area_info_hands =
      area_info_hands.length > 0
        ? property.area_info_hands.concat(res.area_info_hands)
        : res.area_info_hands
    property.area_info_hands = property.area_info_hands.map((changetext) => ({
      ...changetext,
      youto_chiiki_hand: getUnspecifiedText(changetext.youto_chiiki_hand, areaOfUseCodes),
      bouka_chiiki_hand: getUnspecifiedText(changetext.bouka_chiiki_hand, fireProtectionCodes),
      koudo_chiku_hand: getUnspecifiedText(changetext.koudo_chiku_hand, altitudeAreaCodes),
      shadow_area_hand: getUnspecifiedText(changetext.shadow_area_hand, shadowAreaCodes),
      zoning_area: getUnspecifiedText(changetext.zoning_area, zoningAreaCodes),
    }))
    if (res.memos.length > 0) {
      setNewMemo(res.memos[0].content)
    }
    if (res['tenancy_rate']) {
      property['tenancy_rate'] = formatDecimalToPercentage(res['tenancy_rate'])
    }

    const suggestedUnitPriceChangedData = suggestedUnitPriceChanged(property)
    setPropertyData({
      ...property,
      ...suggestedUnitPriceChangedData,
    })
  }

  const onCloseDialog = (result: boolean) => {
    setDialogOpen(false)

    if (result) {
      location.href = is_edit
        ? `/properties/${property.hashid}?tab=property_details`
        : '/properties'
    }
  }

  const existdArea = propertyData?.area_info
    ? unit_m2(JSON.parse(propertyData.area_info)?.['site']?.['area'])
    : property?.area_info
    ? unit_m2(JSON.parse(property?.area_info)?.['site']?.['area'])
    : ''
  const [requestUUID, setRequestUUID] = React.useState('')
  const [responseArea, setResponseArea] = React.useState('')
  const [prefectureName, setPrefectureName] = React.useState('')
  const responseAreaData = propertyData?.area_info ? JSON.parse(propertyData.area_info) : null
  const [existdAreaValue, setExistdAreaValue] = React.useState(existdArea)
  const [polygonChanged, setPolygonChanged] = React.useState(false)
  const [checkRetryTime, setCheckRetryTime] = React.useState(0)
  const [areaChecked, setAreaChecked] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState(
    current_user.company.ocr_enabled ? 'ocr' : 'importSheet'
  )
  const ocrValidStatuses: readonly string[] = ['uploadOnly', 'failed', 'successed']

  const { loading, loadingIcon } = quickVolumeResult(
    requestUUID,
    csrfToken.content,
    setResponseArea,
    checkRetryTime,
    setCheckRetryTime,
    propertyData,
    setPropertyData,
    propertyShape,
    displayNoAreaInfoMessage
  )

  const handlequickVolumeCheck = () => {
    setResponseArea('')
    setExistdAreaValue('')
    setAreaChecked(true)
    setPolygonChanged(false)
    quickVolumeCheck({
      isLoaded,
      propertyLatLng,
      propertyShape,
      setRequestUUID,
      csrfToken: csrfToken.content,
      setPrefectureName,
      setCheckRetryTime,
    })
  }

  const isclickDisabled = polygonChanged && !areaChecked && !!propertyShape

  const checkRequiredItem = (column_name) => {
    let required = false
    required_items?.map((item) => {
      if (item.column_name === column_name) {
        required = true
        return
      } else {
        if (item.items) {
          const propertyType = propertyData?.property_type ?? '土地'
          if (item.items[propertyType] && item.items[propertyType].includes(column_name)) {
            required = true
            return
          }
        }
      }
    })
    return required
  }

  const checkDisplayItem = (name: string) => {
    if (display_items.length === 0) {
      return true
    }
    let isDisplay = false
    const which_item_type = (() => {
      switch (propertyData?.property_type) {
        case '土地':
          return 0
        case '土地＋建物（一棟）':
          return 1
        case '土地＋建物（区分）':
          return 2
        default:
          return 0
      }
    })()
    display_items?.forEach((item) => {
      if (item.column_name === name && item.value && item.item_type === which_item_type) {
        isDisplay = true
      }
    })

    return isDisplay
  }
  React.useEffect(() => {
    //if (is_edit && (propertyShape === null || propertyShape === '')) {
    if (propertyShape === null || propertyShape === '') {
      setPropertyData((prevPropertyData) => {
        let newPropertyData = { ...prevPropertyData }
        delete newPropertyData.area_info
        return newPropertyData
      })
      setResponseArea('')
    }
  }, [propertyShape])

  const [showNoAreaInfoMessage, setShowNoAreaInfoMessage] = React.useState(false)

  const newMemoChange = (value) => {
    setNewMemo(value)
  }

  function displayNoAreaInfoMessage() {
    setShowNoAreaInfoMessage(true)
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        is_edit ? (
          <Breadcrumb
            link_texts={['物件管理', '物件詳細', title]}
            property_id={property.hashid}
            tab="property_details"
          />
        ) : (
          <Breadcrumb link_texts={['物件管理', '物件登録']} />
        )
      }
      submenuCategory={is_edit && 'properties'}
      city={property.prefecture + property.city}
    >
      <form
        className="mt-8"
        action={is_edit ? `/properties/${propertyData?.hashid}` : '/properties'}
        acceptCharset="UTF-8"
        method="post"
        encType="multipart/form-data"
      >
        <input type="hidden" name="_method" value={is_edit ? 'patch' : 'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        {is_edit &&
          current_user.company.ocr_enabled &&
          (!ocr.jobStatus || ocrValidStatuses.includes(ocr.jobStatus)) && (
            <Tab.Group
              defaultIndex={allTabs.indexOf(activeTab)}
              onChange={(index) => setActiveTab(allTabs[index])}
              className={'mt-2'}
            >
              <div className="flex text-sm overflow-x-auto whitespace-nowrap">
                <Tab.List className="flex items-center border-b-[1px] border-primary mb-2 md:w-full">
                  <Tab
                    className={`${tabStyles['tab']} ${
                      tabStyles[activeTab === 'ocr' ? 'true' : 'false']
                    } border-l-[1px]`}
                  >
                    生成AI-OCR読取
                  </Tab>
                  <Tab
                    className={`${tabStyles['tab']} ${
                      tabStyles[activeTab === 'importSheet' ? 'true' : 'false']
                    }`}
                  >
                    物件情報インポートシート
                  </Tab>
                </Tab.List>
              </div>
            </Tab.Group>
          )}
        <OCRLayout
          isEdit={is_edit}
          isActiveTab={activeTab === 'ocr'}
          ocrStatus={ocr.jobStatus}
          previewZone={
            <OCRPreviewZone
              isEdit={is_edit}
              ocr={ocr}
              setOcr={setOcr}
              formatType="acquisition"
              setProperty={setPropertyData}
              csrfToken={csrfToken.content}
              attachedFileRef={attachedFileRef}
            />
          }
        >
          {is_edit && activeTab === 'importSheet' && (
            <div className="mb-7 items-center gap-4 pb-1">
              <div
                className="md:flex bg-gray-light p-6 border-2 border-dashed cursor-pointer"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <Input
                  className="mb-3 md:mb-0 md:w-[246px] md:mr-3 pointer-events-none"
                  placeholder="選択されていません"
                  readOnly
                  accept=".xlsx"
                  value={fileName || ''}
                />
                <label htmlFor="excel_file" className={fileButtonStyles.fileButton}>
                  <input
                    key={inputKey}
                    type="file"
                    accept=".xlsx"
                    name="property[excel_file]"
                    id="excel_file"
                    className="absolute w-0 h-0 opacity-0 hidden"
                    ref={fileRef}
                    onChange={(e) => {
                      const file = e.target.files[0]
                      processFile(file)
                    }}
                  />
                  ファイル選択
                </label>
              </div>
              <p className="pt-2 pl-2 text-xs">
                物件情報インポートシートを
                <span className="font-bold">xlsx形式</span> でアップロードしてください
              </p>
            </div>
          )}
          {is_enable_attachments && (
            <p className="pb-4 pl-2 text-xs">
              関連資料のアップロードは
              <a
                className="text-primary"
                href={`/properties/${propertyData?.hashid}?tab=attachments`}
              >
                こちら
              </a>
              から行なえます
            </p>
          )}
          {!is_edit && same_places.length > 0 && (
            <div className="mb-10">
              <div className="mb-5">
                <div className="py-2 px-4 bg-red-error text-white flex items-center">
                  <ReportProblemOutlinedIcon className="mr-2" />
                  <span className="text-base">エラー</span>
                </div>
                <div className="text-red-error text-sm px-8 py-6 border border-red-error">
                  同一所在地の登録があります。すでに登録済みの物件と同一の場合は「入手情報」を追加してください。異なる場合は地番を追加して、登録を継続してください。
                </div>
              </div>
              <Table>
                <thead className="bg-red-light text-sm">
                  <tr>
                    <th scope="col" className="px-4 text-red-error">
                      所在地
                    </th>
                    <th scope="col" className="px-4 text-red-error text-center">
                      地番
                    </th>
                    <th scope="col" className="px-4 text-red-error text-center">
                      区分の場所
                    </th>
                    <th scope="col" className="px-4 text-red-error text-center">
                      物件種目
                    </th>
                    <th scope="col" className="px-4 text-red-error text-center">
                      敷地面積
                    </th>
                    <th scope="col" className="px-4 text-red-error text-center">
                      建物賃貸可能面積
                      <br />
                      （専有面積）
                    </th>
                    <th scope="col" className="px-4 text-red-error text-center">
                      入手件数
                    </th>
                    <th scope="col" className="px-4 text-red-error text-center">
                      その他
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {same_places.map((same_place, index) => (
                    <tr
                      key={index}
                      className="border-b border-red-light hover:bg-targetLine cursor-pointer"
                      onClick={(e) => {
                        const element = e.target as HTMLElement
                        if (element.tagName !== 'TD') return
                        location.href = `/properties/${same_place?.hashid}`
                      }}
                    >
                      <td className="py-2.5 px-4 text-red-error">
                        {same_place?.prefecture} {same_place?.city} {same_place?.town}{' '}
                        {same_place?.chome}
                      </td>
                      <td className="py-2.5 px-4 text-red-error text-center">
                        {same_place?.chiban}
                      </td>
                      <td className="py-2.5 px-4 text-red-error text-center">
                        {same_place?.location_of_division}
                      </td>
                      <td className="py-2.5 px-4 text-red-error text-center">
                        {same_place?.property_type}
                      </td>
                      <td className="py-2.5 px-4 text-red-error text-center">
                        {same_place?.area_m3 && (
                          <>{cammedFormat(same_place?.area_m3 as number, 2)}㎡</>
                        )}
                      </td>
                      <td className="py-2.5 px-4 text-red-error text-center">
                        {same_place?.occupied_area && (
                          <>{cammedFormat(same_place?.occupied_area as number, 2)}㎡</>
                        )}
                      </td>
                      <td className="py-2.5 px-4 text-red-error text-center">
                        {same_place?.proposals?.length ?? 0}件
                      </td>
                      <td className="py-2.5 px-4 text-red-error text-center">
                        <a
                          className="inline-block w-[30px] hover:text-deleteHover"
                          data-confirm={`${String(
                            same_place?.proposals?.length ?? 0
                          )}件の入手情報も同時に削除されます。よろしいですか？`}
                          rel="nofollow"
                          data-method="delete"
                          href={`/properties/${same_place.hashid}`}
                        >
                          <DeleteOutlined fontSize="small" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          <ErrorMessage error_messages={error_messages} />

          {is_edit && (
            <div
              onKeyDown={(e) => {
                const element = e.target as HTMLElement
                if (e.key === 'Enter' && element.id !== 'property_note') {
                  e.preventDefault()
                }
              }}
            >
              <Table
                className="block mb-16 overflow-x-visible"
                header={
                  <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                    検討状況
                  </div>
                }
              >
                <tbody className="block md:table-row-group">
                  <Row label={<Th required>ステータス</Th>}>
                    <Td>
                      <div className="flex gap-8">
                        {propertyStatusOptions.map((item, i) => (
                          <RadioButton
                            key={i}
                            name="property[status]"
                            id={`property_status_${i}`}
                            text={item.text}
                            value={item.value}
                            checked={item.value === propertyData?.ongoing}
                            disabled={!property.ongoing_proposal}
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                ongoing: e.target.value,
                              })
                            }
                          />
                        ))}
                      </div>
                    </Td>
                  </Row>
                  {(current_user.role === 'system_admin' || current_user.role === 'admin') &&
                    checkDisplayItem('local_situation') && (
                      <Row label={<Th projects_layouts={projects_layouts}>入手日時</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={property.ongoing_proposal ? propertyData?.recepted_at : ''}
                            name="property[sourced_at]"
                            id="property_sourced_at"
                            type="datetime-local"
                            disabled={!property.ongoing_proposal}
                            onChange={(e) => {
                              //const newDate = new Date(e.target.value)
                              const newDateStart = startOfDay(parseISO(e.target.value))
                              //const today = new Date()
                              const todayStart = startOfToday()
                              const sourced_at = format(parseISO(e.target.value), 'yyyy-MM-dd')
                              const recepted_at = format(
                                parseISO(e.target.value),
                                'yyyy-MM-dd HH:mm:ss'
                              )
                              const passed_day = differenceInDays(todayStart, newDateStart)

                              setPropertyData({
                                ...propertyData,
                                sourced_at,
                                recepted_at,
                                passed_day,
                              })
                            }}
                            maxCalendarYear={3}
                          />
                        </Td>
                      </Row>
                    )}
                  <Row label={<Th projects_layouts={projects_layouts}>タグ</Th>}>
                    <Td>
                      <Select
                        className="md:w-[300px]"
                        name="property[tag_id]"
                        id="property_tag_id"
                        value={propertyData?.tag_id ?? ''}
                        options={tagsOptions}
                        hasBlank
                        blankLabel="(未設定)"
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            tag_id: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Row>
                  <Row label={<Th projects_layouts={projects_layouts}>案件名</Th>}>
                    <Td>
                      <Input
                        className="md:w-[300px]"
                        value={propertyData?.ongoing !== 'ongoing' ? '' : propertyData?.name ?? ''}
                        readOnly={propertyData?.ongoing !== 'ongoing'}
                        name="property[name]"
                        id="property_name"
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            name: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th projects_layouts={projects_layouts} required>
                        情報入手者
                      </Th>
                    }
                  >
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <div className={'relative'}>
                          <DropDown
                            className="md:w-[300px]"
                            name="property[source_user_id]"
                            id="property_source_user_id"
                            value={currentSourceUser}
                            options={usersOptions}
                            hasBlank
                            disabled={
                              propertyData?.ongoing !== 'ongoing' ||
                              current_user.role === 'leader' ||
                              current_user.role === 'general'
                            }
                            placeholder="(未設定)"
                            onChange={onChangeCurrentSourceUser}
                          />
                        </div>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.source_department_name ?? '(未設定)'}
                          placeholder=""
                          name="source_department_name"
                          id="source_department_name"
                          readOnly
                          disabled={
                            propertyData?.ongoing !== 'ongoing' ||
                            current_user.role === 'leader' ||
                            current_user.role === 'general'
                          }
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th projects_layouts={projects_layouts} required>
                        案件担当者
                      </Th>
                    }
                  >
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <DropDown
                          className="md:w-[300px]"
                          name="property[user_id]"
                          id="property_user_id"
                          value={propertyData?.ongoing === 'ongoing' && currentPropertyUser}
                          options={usersOptions}
                          hasBlank
                          disabled={propertyData?.ongoing !== 'ongoing'}
                          placeholder="(未設定)"
                          onChange={onChangeCurrentPropertyUser}
                        />
                        {/* current_user.role !== 'system_admin' && current_user.role !== 'admin' && (
                        <input
                          type="hidden"
                          name="property[user_id]"
                          value={propertyData?.ongoing === 'ongoing' ? current_user.id : ''}
                        />
                      ) */}
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.ongoing !== 'ongoing'
                              ? '(未設定)'
                              : propertyData?.department_name ?? '(未設定)'
                          }
                          disabled={
                            propertyData?.ongoing !== 'ongoing' ||
                            current_user.role === 'leader' ||
                            current_user.role === 'general'
                          }
                          placeholder="(未設定)"
                          name="department_name"
                          id="department_name"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                </tbody>
              </Table>
              <FileUpload
                hidden={true}
                name="property[attached_files][]"
                accept={AcceptableMimeTypes}
                filenameInitial={ocr.localFile?.name}
                ref={attachedFileRef}
              />

              <Table
                className="border-t border-b block mb-16"
                header={
                  <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                    基本情報
                  </div>
                }
              >
                <tbody className="block md:table-row-group">
                  {current_user.role === 'system_admin' || current_user.role === 'admin' ? (
                    <Address
                      current_user={current_user}
                      prefectureInitial={propertyData?.prefecture ?? property.prefecture}
                      cityInitial={propertyData?.city ?? property.city}
                      townInitial={propertyData?.town ?? property.town}
                      name={'property'}
                      data={propertyData}
                      onChangeData={setPropertyData}
                      projects_layouts={projects_layouts}
                      requiredItems={required_items}
                      error_messages={error_messages}
                      is_edit={is_edit}
                    />
                  ) : (
                    <Row label={<Th projects_layouts={projects_layouts}>所在地（住居表示）</Th>}>
                      <Td>
                        <div className="md:w-[300px]">
                          {`${propertyData?.prefecture} ${propertyData?.city} ${propertyData?.town} ${propertyData?.chome}`}
                        </div>
                      </Td>
                    </Row>
                  )}
                  <Row label={<Th projects_layouts={projects_layouts}>丁目以降（地番）</Th>}>
                    <Td>
                      <Input
                        className="md:w-[300px]"
                        value={inputChibanInfo}
                        name="property[chiban]"
                        id="property_chiban"
                        placeholder="1-2-1, 1-2-2, 1-2-3"
                        onChange={(e) => {
                          setInputChibanInfo(e.target.value)
                          setPropertyData({
                            ...propertyData,
                            chiban: e.target.value,
                          })
                        }}
                        onBlur={(e) => {
                          const value = formatChiban(e.target.value)
                          setInputChibanInfo(value)
                          setPropertyData({
                            ...propertyData,
                            chiban: value,
                          })
                        }}
                      />
                    </Td>
                  </Row>
                  <Row label={<Th>建物名</Th>}>
                    <Td>
                      <Input
                        className="md:w-[300px]"
                        value={propertyData?.map_information || ''}
                        name="property[map_information]"
                        id="property_map_information"
                        placeholder="〜宅の東隣、○×宅　等"
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            map_information: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Row>
                  {current_user.role === 'system_admin' || current_user.role === 'admin' ? (
                    <Row
                      label={
                        <Th
                          projects_layouts={projects_layouts}
                          required={checkRequiredItem('property_type')}
                        >
                          物件種目
                        </Th>
                      }
                    >
                      <Td>
                        <div className="flex gap-8">
                          {propertyTypeOptions.map((item, i) => (
                            <RadioButton
                              key={i}
                              name="property[property_type]"
                              id={`property_property_type_${i}`}
                              text={item.text}
                              value={item.value}
                              checked={item.text === propertyData?.property_type}
                              onChange={(e) => {
                                const suggestedUnitPriceChangedData = suggestedUnitPriceChanged(
                                  propertyData,
                                  e.target.value
                                )
                                const purchasePriceChangedData = purchasePriceChanged(
                                  propertyData,
                                  e.target.value
                                )
                                let totalFloorAreaChangedData = {}
                                if (is_edit && e.target.value !== '土地') {
                                  totalFloorAreaChangedData = {
                                    ...totalFloorAreaChanged(propertyData),
                                  }
                                }
                                setPropertyData({
                                  ...propertyData,
                                  ...suggestedUnitPriceChangedData,
                                  ...purchasePriceChangedData,
                                  ...totalFloorAreaChangedData,
                                  property_type: e.target.value,
                                  location_of_division:
                                    e.target.value === '土地＋建物（区分）'
                                      ? propertyData.location_of_division
                                      : '',
                                })
                              }}
                            />
                          ))}
                        </div>
                      </Td>
                    </Row>
                  ) : (
                    <Row label={<Th projects_layouts={projects_layouts}>物件種目</Th>}>
                      <Td>
                        <div className="flex gap-8">{propertyData?.property_type}</div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('local_situation') && (
                    <Row label={<Th projects_layouts={projects_layouts}>現況</Th>}>
                      <Td>
                        <Select
                          className="md:w-[300px]"
                          name="property[local_situation]"
                          id="property_local_situation"
                          value={propertyData?.local_situation || ''}
                          options={localSituationOptions}
                          hasBlank
                          blankLabel="（未設定）"
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              local_situation: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  <Row
                    label={
                      <Th
                        projects_layouts={projects_layouts}
                        required={checkRequiredItem('area_m3')}
                      >
                        敷地面積
                      </Th>
                    }
                  >
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.area_m3 ?? ''}
                          suffix="㎡"
                          onChange={(e) => {
                            const area_m3 = e.target.value
                            setPropertyData({
                              ...propertyData,
                              area_m3,
                            })
                          }}
                          onBlur={(e) => {
                            const value = e.target.value
                              ? dataPrecisionSetup(e.target.value, 12, 2)
                              : 0
                            const data = {}
                            data['area_m3'] = value ? cammedFormat(value, 2) : ''
                            data['area_tsubo'] = value ? calculateTsubo(value) : ''
                            data['effective_area'] = propertyData?.effective_area
                              ? cammedFormat(intFormat(String(propertyData?.effective_area)), 2)
                              : 0

                            if (data['effective_area'] === 0) {
                              data['effective_area'] = value ? cammedFormat(value, 2) : ''
                              data['effective_area_tsubo'] = value ? calculateTsubo(value) : ''
                              data['suggested_unit_price'] =
                                value && propertyData?.property_type === '土地'
                                  ? intFormat(String(propertyData?.suggested_total_price)) /
                                    data['effective_area_tsubo']
                                  : propertyData?.suggested_unit_price
                            }
                            setPropertyData({
                              ...propertyData,
                              ...data,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          value={
                            propertyData?.area_m3 ? intFormat(String(propertyData?.area_m3)) : ''
                          }
                          name="property[area_m3]"
                          id="property_area_m3"
                        />
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.area_tsubo || ''}
                          name="property[area_tsubo]"
                          id="property_area_tsubo"
                          suffix="坪"
                          placeholder=""
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th
                        projects_layouts={projects_layouts}
                        required={checkRequiredItem('effective_area')}
                      >
                        有効敷地面積
                      </Th>
                    }
                  >
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.effective_area ?? ''}
                          suffix="㎡"
                          onChange={(e) => {
                            const effective_area = e.target.value
                            setPropertyData({
                              ...propertyData,
                              effective_area,
                            })
                          }}
                          onBlur={(e) => {
                            const value = dataPrecisionSetup(e.target.value, 12, 2)
                            const effective_area = value ? cammedFormat(value, 2) : ''
                            const effective_area_tsubo = value ? calculateTsubo(value) : ''
                            const suggestedUnitPriceChangedData =
                              suggestedUnitPriceChanged(propertyData)
                            const purchasePriceChangedData = purchasePriceChanged(propertyData)
                            const calculateAmountOfSalesData = calculateAmountOfSales(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...suggestedUnitPriceChangedData,
                              ...purchasePriceChangedData,
                              ...calculateAmountOfSalesData,
                              effective_area,
                              effective_area_tsubo,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          value={
                            propertyData?.effective_area
                              ? intFormat(String(propertyData?.effective_area))
                              : ''
                          }
                          name="property[effective_area]"
                          id="property_effective_area"
                        />
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.effective_area_tsubo || ''}
                          name="property[effective_area_tsubo]"
                          id="property_effective_area_tsubo"
                          suffix="坪"
                          placeholder=""
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th
                        projects_layouts={projects_layouts}
                        required={checkRequiredItem('total_floor_area')}
                      >
                        延床面積
                      </Th>
                    }
                  >
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.total_floor_area || ''}
                          suffix="㎡"
                          placeholder={getPlaceholderText(
                            propertyData?.property_type,
                            'spaceMetrics'
                          )}
                          onChange={(e) => {
                            const total_floor_area = e.target.value
                            setPropertyData({
                              ...propertyData,
                              total_floor_area: total_floor_area || '',
                            })
                          }}
                          onBlur={(e) => {
                            const occupiedAreaData = {}

                            const occupied_area = propertyData?.occupied_area
                              ? intFormat(String(propertyData?.occupied_area))
                              : 0

                            const value = dataPrecisionSetup(e.target.value, 12, 2)
                            const total_floor_area = value ? cammedFormat(value, 2) : ''
                            const total_floor_area_tsubo = value ? calculateTsubo(value) : ''
                            const calculateBuildingUnitPriceYieldData =
                              calculateBuildingUnitPriceYield(propertyData)
                            if (!occupied_area) {
                              occupiedAreaData['occupied_area'] = value
                                ? cammedFormat(value, 2)
                                : ''
                              occupiedAreaData['occupied_area_tsubo'] = value
                                ? calculateTsubo(value)
                                : ''
                            }
                            const newPropertyData = {
                              ...propertyData,
                              ...occupiedAreaData,
                              ...calculateBuildingUnitPriceYieldData,
                              total_floor_area,
                              total_floor_area_tsubo,
                            }
                            const calculateConstructionUnitPriceData =
                              calculateConstructionUnitPrice(newPropertyData)

                            setPropertyData({
                              ...newPropertyData,
                              ...calculateConstructionUnitPriceData,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          value={
                            propertyData?.total_floor_area
                              ? intFormat(String(propertyData?.total_floor_area))
                              : ''
                          }
                          name="property[total_floor_area]"
                          id="property_total_floor_area"
                        />
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.total_floor_area_tsubo || ''}
                          name="property[total_floor_area_tsubo]"
                          id="property_total_floor_area_tsubo"
                          suffix="坪"
                          placeholder=""
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th
                        projects_layouts={projects_layouts}
                        required={checkRequiredItem('occupied_area')}
                      >
                        建物賃貸可能面積（専有面積）
                      </Th>
                    }
                  >
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.occupied_area || ''}
                          suffix="㎡"
                          placeholder={getPlaceholderText(
                            propertyData?.property_type,
                            'spaceMetrics'
                          )}
                          onChange={(e) => {
                            const occupied_area = e.target.value
                            setPropertyData({
                              ...propertyData,
                              occupied_area,
                            })
                          }}
                          onBlur={(e) => {
                            const value = dataPrecisionSetup(e.target.value, 12, 2)
                            const occupied_area = value ? cammedFormat(value, 2) : ''
                            const occupied_area_tsubo = value ? calculateTsubo(value) : ''
                            const suggestedUnitPriceChangedData =
                              suggestedUnitPriceChanged(propertyData)
                            const purchasePriceChangedData = purchasePriceChanged(propertyData)
                            const calculateAmountOfSalesData = calculateAmountOfSales(propertyData)

                            setPropertyData({
                              ...propertyData,
                              ...suggestedUnitPriceChangedData,
                              ...purchasePriceChangedData,
                              ...calculateAmountOfSalesData,
                              occupied_area,
                              occupied_area_tsubo,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          value={
                            propertyData?.occupied_area
                              ? intFormat(String(propertyData?.occupied_area))
                              : ''
                          }
                          name="property[occupied_area]"
                          id="property_occupied_area"
                        />
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.occupied_area_tsubo || ''}
                          name="property[occupied_area_tsubo]"
                          id="property_occupied_area_tsubo"
                          suffix="坪"
                          placeholder=""
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th
                        projects_layouts={projects_layouts}
                        required={checkRequiredItem('location_of_division')}
                      >
                        区分の場所
                      </Th>
                    }
                  >
                    <Td>
                      <Input
                        className="md:w-[300px]"
                        value={propertyData?.location_of_division || ''}
                        name="property[location_of_division]"
                        id="property_location_of_division"
                        readOnly={propertyData?.property_type !== '土地＋建物（区分）'}
                        tabIndex={propertyData?.property_type !== '土地＋建物（区分）' && -1}
                        placeholder={getPlaceholderText(
                          propertyData?.property_type,
                          'location_of_division'
                        )}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            location_of_division: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Row>
                  {propertyData?.transportations.map((transportation, index) => (
                    <TransportationRow
                      index={index}
                      transportation={transportation}
                      data={propertyData}
                      setData={setPropertyData}
                      dataLatLng={propertyLatLng}
                      defaultTransportationData={defaultTransportationData}
                      requestParamName="property"
                      projectsLayouts={projects_layouts}
                    />
                  ))}
                  {propertyData?.road_accesses.map((road_access, index) => (
                    <RoadAccessRow
                      index={index}
                      road_access={road_access}
                      data={propertyData}
                      setData={setPropertyData}
                      dataLatLng={propertyLatLng}
                      defaultRoadAccessData={defaultRoadAccessData}
                      requestParamName="road_accesses"
                    />
                  ))}
                  {propertyData?.area_info_hands.map((areainfo, index) => (
                    <AreaInfoHandRow
                      index={index}
                      areainfo={areainfo}
                      data={propertyData}
                      setData={setPropertyData}
                      dataLatLng={propertyLatLng}
                      defaultAreaInfoHandData={defaultAreaInfoHandData}
                      requestParamName="property"
                      projectsLayouts={projects_layouts}
                    />
                  ))}
                </tbody>
              </Table>

              <Table
                className="border-t border-b block mb-16"
                header={
                  <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                    入手情報
                  </div>
                }
              >
                <tbody className="block md:table-row-group">
                  {checkDisplayItem('source') && (
                    <>
                      <Row
                        label={
                          <Th
                            projects_layouts={projects_layouts}
                            required={checkRequiredItem('source')}
                          >
                            入手先企業
                          </Th>
                        }
                      >
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.source ?? ''}
                            name="property[source]"
                            id="property_source"
                            list="sources-list"
                            datalist={sources_list}
                            readOnly={propertyData?.ongoing !== 'ongoing'}
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                source: e.target.value,
                              })
                            }
                          />
                        </Td>
                      </Row>
                      <Row label={<Th projects_layouts={projects_layouts}>入手先支店/部署</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.source_branch ?? ''}
                            name="property[source_branch]"
                            id="property_source_branch"
                            list="sources-branch-list"
                            datalist={sources_branch_list}
                            readOnly={propertyData?.ongoing !== 'ongoing'}
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                source_branch: e.target.value,
                              })
                            }
                          />
                        </Td>
                      </Row>
                      <Row label={<Th projects_layouts={projects_layouts}>入手先担当者</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.source_staff ?? ''}
                            name="property[source_staff]"
                            id="property_source_staff"
                            list="sources-staff-list"
                            datalist={sources_staff_list}
                            readOnly={propertyData?.ongoing !== 'ongoing'}
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                source_staff: e.target.value,
                              })
                            }
                          />
                        </Td>
                      </Row>
                    </>
                  )}
                  {checkDisplayItem('negotiation_method') && (
                    <Row label={<Th>商談方式</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <DropDown
                            className="md:w-[300px]"
                            name="property[negotiation_method]"
                            id="property_negotiation_method"
                            value={propertyData?.ongoing === 'ongoing' && currentNegotiationMethod}
                            options={negotiationMethodOptions}
                            hasBlank
                            disabled={propertyData?.ongoing !== 'ongoing'}
                            placeholder="(未設定)"
                            onChange={onChangeCurrentNegotiationMethod}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('seller') && (
                    <Row label={<Th projects_layouts={projects_layouts}>売主</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.seller ?? ''}
                          name="property[seller]"
                          id="property_seller"
                          readOnly={propertyData?.ongoing !== 'ongoing'}
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              seller: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('sourced_at') && (
                    <Row label={<Th projects_layouts={projects_layouts}>入手日</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            type="date"
                            value={property.ongoing_proposal ? propertyData?.sourced_at : ''}
                            placeholder=""
                            readOnly
                            tabIndex={-1}
                            onChange={() => {
                              //
                            }}
                          />
                          {checkDisplayItem('passed_day') && (
                            <Input
                              className="md:w-[300px]"
                              value={property.ongoing_proposal ? propertyData?.passed_day : ''}
                              prefix="経過日数"
                              placeholder=""
                              readOnly
                              tabIndex={-1}
                              onChange={() => {
                                //
                              }}
                            />
                          )}
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('purchace_applied_at') && (
                    <Row label={<Th projects_layouts={projects_layouts}>買付提出日</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.purchace_applied_at || ''}
                          name="property[purchace_applied_at]"
                          id="property_purchace_applied_at"
                          type="date"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              purchace_applied_at: e.target.value,
                            })
                          }}
                          maxCalendarYear={3}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('purchace_contract_at') && (
                    <Row label={<Th projects_layouts={projects_layouts}>仕入契約日</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.purchace_contract_at || ''}
                          name="property[purchace_contract_at]"
                          id="property_purchace_contract_at"
                          type="date"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              purchace_contract_at: e.target.value,
                            })
                          }}
                          maxCalendarYear={3}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('purchace_settlement_at') && (
                    <Row label={<Th projects_layouts={projects_layouts}>仕入決済日</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.purchace_settlement_at || ''}
                          name="property[purchace_settlement_at]"
                          id="property_purchace_settlement_at"
                          type="date"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              purchace_settlement_at: e.target.value,
                            })
                          }}
                          maxCalendarYear={3}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('note') && (
                    <Row label={<Th>備考</Th>}>
                      <Td>
                        <Textarea
                          className="md:w-[615px]"
                          value={propertyData?.note ?? ''}
                          name="property[note]"
                          id="property_note"
                          readOnly={propertyData?.ongoing !== 'ongoing'}
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              note: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  )}
                </tbody>
              </Table>

              <AreaInfo
                responseAreaData={responseAreaData}
                propertyData={propertyData}
                prefectureName={prefectureName}
              />

              <Table
                className="border-t border-b block mb-16"
                header={
                  <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                    事業情報
                  </div>
                }
              >
                <tbody className="block md:table-row-group">
                  {checkDisplayItem('business_type_id') && (
                    <Row
                      label={
                        <Th
                          projects_layouts={projects_layouts}
                          required={checkRequiredItem('business_type_id')}
                        >
                          事業形態
                        </Th>
                      }
                    >
                      <Td>
                        <Select
                          className="md:w-[300px]"
                          name="property[business_type_id]"
                          id="property_business_type_id"
                          value={propertyData?.business_type_id ?? ''}
                          options={businessTypeOptions}
                          hasBlank
                          blankLabel="(未設定)"
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              business_type_id: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('suggested_total_price') && (
                    <Row
                      label={(() => {
                        if (projects_layouts_no_mapping?.includes('売主希望総額')) {
                          return <Th projects_layouts={projects_layouts}>売主希望総額</Th>
                        }
                        if (
                          ['現況表面利回り', '現況NOI利回り'].filter((item) =>
                            projects_layouts?.includes(item)
                          ).length > 0 &&
                          [
                            '売主希望坪単価',
                            '売主希望一種単価',
                            '仕入戸当たり単価',
                            '仕入延床坪単価',
                          ].filter((item) => projects_layouts?.includes(item)).length == 0
                        ) {
                          return propertyData?.purchase_price ? (
                            <Th>売主希望総額</Th>
                          ) : (
                            <Th projects_layouts={projects_layouts}>売主希望総額</Th>
                          )
                        }
                        return <Th projects_layouts={projects_layouts}>売主希望総額</Th>
                      })()}
                    >
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.suggested_total_price ?? ''}
                          suffix="円"
                          readOnly={propertyData?.ongoing !== 'ongoing'}
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              suggested_total_price: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const suggested_total_price = e.target.value
                              ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                              : ''
                            const suggestedUnitPriceChangedData =
                              suggestedUnitPriceChanged(propertyData)
                            const calculateCurrentProspectiveYieldData =
                              calculateCurrentProspectiveYield(propertyData)
                            const calculateCurrentProspectiveNoiYieldData =
                              calculateCurrentProspectiveNoiYield(propertyData)
                            const calculateUnitPricePerUnitYieldData =
                              calculateUnitPricePerUnitYield(propertyData)
                            const calculateBuildingUnitPriceYieldData =
                              calculateBuildingUnitPriceYield(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...suggestedUnitPriceChangedData,
                              ...calculateCurrentProspectiveYieldData,
                              ...calculateCurrentProspectiveNoiYieldData,
                              ...calculateUnitPricePerUnitYieldData,
                              ...calculateBuildingUnitPriceYieldData,
                              suggested_total_price: suggested_total_price,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[suggested_total_price]"
                          id="property_suggested_total_price"
                          value={
                            propertyData?.suggested_total_price
                              ? intFormat(String(propertyData?.suggested_total_price))
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('suggested_unit_price') && (
                    <Row label={<Th projects_layouts={projects_layouts}>売主希望坪単価</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.suggested_unit_price
                              ? formatNumber(propertyData?.suggested_unit_price)
                              : ''
                          }
                          suffix="円"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('suggested_primary_unit_price') && (
                    <Row label={<Th projects_layouts={projects_layouts}>売主希望一種単価</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.suggested_primary_unit_price &&
                            propertyData?.property_type === '土地'
                              ? formatNumber(propertyData?.suggested_primary_unit_price)
                              : ''
                          }
                          suffix="円"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('purchase_price') && (
                    <Row
                      label={(() => {
                        if (projects_layouts_no_mapping?.includes('買取金額')) {
                          return <Th projects_layouts={projects_layouts}>買取金額</Th>
                        }
                        if (
                          ['現況表面利回り', '現況NOI利回り'].filter((item) =>
                            projects_layouts?.includes(item)
                          ).length > 0 &&
                          ['買取坪単価', '買取一種単価'].filter((item) =>
                            projects_layouts?.includes(item)
                          ).length == 0
                        ) {
                          return propertyData?.purchase_price ? (
                            <Th projects_layouts={projects_layouts}>買取金額</Th>
                          ) : (
                            <Th>買取金額</Th>
                          )
                        }
                        return <Th projects_layouts={projects_layouts}>買取金額</Th>
                      })()}
                    >
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.purchase_price ?? ''}
                          suffix="円"
                          readOnly={propertyData?.ongoing !== 'ongoing'}
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              purchase_price: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const purchase_price = e.target.value
                              ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                              : ''
                            const purchasePriceChangedData = purchasePriceChanged(propertyData)
                            const calculateCurrentProspectiveYieldData =
                              calculateCurrentProspectiveYield(propertyData)
                            const calculateCurrentProspectiveNoiYieldData =
                              calculateCurrentProspectiveNoiYield(propertyData)
                            const calculateUnitPricePerUnitYieldData =
                              calculateUnitPricePerUnitYield(propertyData)
                            const calculateBuildingUnitPriceYieldData =
                              calculateBuildingUnitPriceYield(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...purchasePriceChangedData,
                              ...calculateCurrentProspectiveYieldData,
                              ...calculateCurrentProspectiveNoiYieldData,
                              ...calculateUnitPricePerUnitYieldData,
                              ...calculateBuildingUnitPriceYieldData,
                              purchase_price: purchase_price,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[purchase_price]"
                          id="property_purchase_price"
                          value={
                            propertyData?.purchase_price
                              ? intFormat(String(propertyData?.purchase_price))
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('purchase_unit_price') && (
                    <Row label={<Th projects_layouts={projects_layouts}>買取坪単価</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.purchase_unit_price
                              ? formatNumber(propertyData?.purchase_unit_price)
                              : ''
                          }
                          suffix="円"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('purchase_unit_price') && (
                    <Row label={<Th projects_layouts={projects_layouts}>買取一種単価</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.purchase_primary_unit_price &&
                            propertyData?.property_type === '土地'
                              ? formatNumber(propertyData?.purchase_primary_unit_price)
                              : ''
                          }
                          suffix="円"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('total_expense_cost_purchase') && (
                    <Row label={<Th projects_layouts={projects_layouts}>諸経費合計(仕入)</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.total_expense_cost_purchase ?? ''}
                          suffix="円"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              total_expense_cost_purchase: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const value = dataPrecisionSetup(e.target.value, 10, 0)
                            setPropertyData({
                              ...propertyData,
                              total_expense_cost_purchase: value ? cammedFormat(value) : '',
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[total_expense_cost_purchase]"
                          id="property_total_expense_cost_purchase"
                          value={
                            propertyData?.total_expense_cost_purchase
                              ? dataPrecisionSetup(
                                  String(propertyData?.total_expense_cost_purchase),
                                  12,
                                  0
                                )
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('current_rent') && (
                    <Row
                      label={<Th projects_layouts={projects_layouts}>現況賃料（税込）/ 月額</Th>}
                    >
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.current_rent ?? ''}
                            suffix="円"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                current_rent: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const current_rent = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                : ''
                              const calculateCurrentProspectiveYieldData =
                                calculateCurrentProspectiveYield(propertyData)
                              setPropertyData({
                                ...propertyData,
                                ...calculateCurrentProspectiveYieldData,
                                current_rent: current_rent,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[current_rent]"
                            id="property_current_rent"
                            value={
                              propertyData?.current_rent
                                ? intFormat(String(propertyData?.current_rent))
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.current_prospective_yield
                                ? dataPrecisionSetup(
                                    propertyData?.current_prospective_yield.toString(),
                                    12,
                                    2
                                  ).toFixed(2)
                                : ''
                            }
                            prefix="現況表面利回り"
                            suffix="%"
                            readOnly
                            tabIndex={-1}
                            onChange={() => {
                              //
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[current_prospective_yield]"
                            id="property_current_prospective_yield"
                            value={propertyData?.current_prospective_yield || ''}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('net_income') && (
                    <Row label={<Th projects_layouts={projects_layouts}>現況NOI / 月額</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.net_income ?? ''}
                            suffix="円"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                net_income: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const net_income = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                : ''
                              const calculateCurrentProspectiveNoiYieldData =
                                calculateCurrentProspectiveNoiYield(propertyData)
                              setPropertyData({
                                ...propertyData,
                                ...calculateCurrentProspectiveNoiYieldData,
                                net_income: net_income,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[net_income]"
                            id="property_net_income"
                            value={
                              propertyData?.net_income
                                ? intFormat(String(propertyData?.net_income))
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.current_prospective_noi_yield
                                ? dataPrecisionSetup(
                                    propertyData?.current_prospective_noi_yield.toString(),
                                    12,
                                    2
                                  ).toFixed(2)
                                : ''
                            }
                            prefix="現況NOI利回り"
                            suffix="%"
                            readOnly
                            tabIndex={-1}
                            onChange={() => {
                              //
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[current_prospective_noi_yield]"
                            id="property_current_prospective_noi_yield"
                            value={propertyData?.current_prospective_noi_yield || ''}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('coverage_ratio') && (
                    <Row label={<Th projects_layouts={projects_layouts}>計画建ぺい率</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.coverage_ratio ? propertyData?.coverage_ratio : ''}
                          suffix="%"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              coverage_ratio: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const value = e.target.value
                              ? dataPrecisionSetup(e.target.value, 8, 2)
                              : 0
                            setPropertyData({
                              ...propertyData,
                              coverage_ratio: value ? cammedFormat(value, 2) : '',
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[coverage_ratio]"
                          id="property_coverage_ratio"
                          value={
                            propertyData?.coverage_ratio
                              ? intFormat(String(propertyData?.coverage_ratio))
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('volume_rate') && (
                    <Row label={<Th projects_layouts={projects_layouts}>計画容積率</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.volume_rate ? propertyData?.volume_rate : ''}
                          name="property[volume_rate]"
                          id="property_volume_rate"
                          suffix="%"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              volume_rate: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const value = e.target.value
                              ? dataPrecisionSetup(e.target.value, 8, 2)
                              : 0
                            const newPropertyData = {
                              ...propertyData,
                              volume_rate: value ? cammedFormat(value, 2) : '',
                            }
                            const suggestedUnitPriceChangedData =
                              suggestedUnitPriceChanged(newPropertyData)
                            const purchasePriceChangedData = purchasePriceChanged(newPropertyData)
                            const calculateAmountOfSalesData =
                              calculateAmountOfSales(newPropertyData)
                            setPropertyData({
                              ...newPropertyData,
                              ...suggestedUnitPriceChangedData,
                              ...purchasePriceChangedData,
                              ...calculateAmountOfSalesData,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[volume_rate]"
                          id="property_volume_rate"
                          value={
                            propertyData?.volume_rate
                              ? intFormat(String(propertyData?.volume_rate))
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('number_of_buildings') && (
                    <Row label={<Th projects_layouts={projects_layouts}>棟数（区画）</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.number_of_buildings || ''}
                          suffix="棟（区画）"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              number_of_buildings: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const value = dataPrecisionSetup(e.target.value, 10, 0)
                            setPropertyData({
                              ...propertyData,
                              number_of_buildings: value ? cammedFormat(value) : '',
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[number_of_buildings]"
                          id="property_number_of_buildings"
                          value={
                            propertyData?.number_of_buildings
                              ? dataPrecisionSetup(String(propertyData?.number_of_buildings), 10, 0)
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('number_of_units') && (
                    <Row label={<Th projects_layouts={projects_layouts}>戸数</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.number_of_units || ''}
                            suffix="戸"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                number_of_units: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const number_of_units = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0))
                                : ''
                              const calculateUnitPricePerUnitYieldData =
                                calculateUnitPricePerUnitYield(propertyData)
                              setPropertyData({
                                ...propertyData,
                                ...calculateUnitPricePerUnitYieldData,
                                number_of_units: number_of_units,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[number_of_units]"
                            id="property_number_of_units"
                            value={
                              propertyData?.number_of_units
                                ? dataPrecisionSetup(String(propertyData?.number_of_units), 10, 0)
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.unit_price_per_unit_yield
                                ? cammedFormat(
                                    dataPrecisionSetup(
                                      propertyData?.unit_price_per_unit_yield.toString(),
                                      10,
                                      0
                                    )
                                  )
                                : ''
                            }
                            prefix="仕入戸当たり単価"
                            suffix="円"
                            readOnly
                            tabIndex={-1}
                            onChange={() => {
                              //
                            }}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('building_unit_price_yield') && (
                    <Row label={<Th></Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.building_unit_price_yield
                              ? cammedFormat(
                                  dataPrecisionSetup(
                                    propertyData?.building_unit_price_yield.toString(),
                                    10,
                                    0
                                  )
                                )
                              : ''
                          }
                          prefix="仕入延床坪面積"
                          suffix="円"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('building_structure') && (
                    <Row label={<Th projects_layouts={projects_layouts}>建物構造</Th>}>
                      <Td>
                        <Select
                          className="md:w-[300px]"
                          name="property[building_structure]"
                          id="property_building_structure"
                          value={propertyData?.building_structure ?? ''}
                          options={buildingStructureOptions}
                          hasBlank
                          blankLabel="(未設定)"
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              building_structure: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('floors') && (
                    <Row label={<Th projects_layouts={projects_layouts}>建物階数</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.floors_above_ground || ''}
                            name="property[floors_above_ground]"
                            id="property_floors_above_ground"
                            prefix="地上"
                            suffix="階"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                floors_above_ground: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const value = dataPrecisionSetup(e.target.value, 10, 0)
                              setPropertyData({
                                ...propertyData,
                                floors_above_ground: value ? cammedFormat(value) : '',
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[floors_above_ground]"
                            id="property_floors_above_ground"
                            value={
                              propertyData?.floors_above_ground
                                ? dataPrecisionSetup(
                                    String(propertyData?.floors_above_ground),
                                    10,
                                    0
                                  )
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.floors_under_ground || ''}
                            name="property[floors_under_ground]"
                            id="property_floors_under_ground"
                            prefix="地下"
                            suffix="階"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                floors_under_ground: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const value = dataPrecisionSetup(e.target.value, 10, 0)
                              setPropertyData({
                                ...propertyData,
                                floors_under_ground: value ? cammedFormat(value) : '',
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[floors_under_ground]"
                            id="property_floors_under_ground"
                            value={
                              propertyData?.floors_under_ground
                                ? dataPrecisionSetup(
                                    String(propertyData?.floors_under_ground),
                                    10,
                                    0
                                  )
                                : ''
                            }
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('building_construction_cost') && (
                    <Row label={<Th projects_layouts={projects_layouts}>本体工事費</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.building_construction_cost || ''}
                            suffix="円"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                building_construction_cost: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const value = dataPrecisionSetup(e.target.value, 10, 0)
                              const newPropertyData = {
                                ...propertyData,
                                building_construction_cost: value ? cammedFormat(value) : '',
                              }
                              const calculateConstructionUnitPriceData =
                                calculateConstructionUnitPrice(newPropertyData)
                              setPropertyData({
                                ...newPropertyData,
                                ...calculateConstructionUnitPriceData,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[building_construction_cost]"
                            id="property_building_construction_cost"
                            value={
                              propertyData?.building_construction_cost
                                ? dataPrecisionSetup(
                                    String(propertyData?.building_construction_cost),
                                    12,
                                    0
                                  )
                                : ''
                            }
                          />
                          {checkDisplayItem('construction_unit_price') && (
                            <Input
                              className="md:w-[300px]"
                              value={
                                propertyData?.construction_unit_price
                                  ? formatNumber(propertyData?.construction_unit_price)
                                  : ''
                              }
                              prefix="工事費坪単価"
                              suffix="円"
                              readOnly
                              tabIndex={-1}
                              onChange={() => {
                                //
                              }}
                            />
                          )}
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('build_completed_at') && (
                    <Row label={<Th projects_layouts={projects_layouts}>竣工日</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.build_completed_at || ''}
                          name="property[build_completed_at]"
                          id="property_build_completed_at"
                          type="date"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              build_completed_at: e.target.value,
                            })
                          }}
                          maxCalendarYear={3}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('certificate_of_inspection') && (
                    <Row label={<Th projects_layouts={projects_layouts}>検査済証</Th>}>
                      <Td>
                        <div className="flex gap-8">
                          {propertyCertificateOfInspectionOptions.map((item, i) => (
                            <RadioButton
                              key={i}
                              name="property[certificate_of_inspection]"
                              id={`property_certificate_of_inspection_${i}`}
                              text={item.text}
                              value={item.value}
                              checked={item.text === propertyData?.certificate_of_inspection}
                              onChange={(e) => {
                                setPropertyData({
                                  ...propertyData,
                                  certificate_of_inspection: e.target.value,
                                  location_of_division:
                                    e.target.value === '土地＋建物（区分）'
                                      ? propertyData.location_of_division
                                      : '',
                                })
                              }}
                            />
                          ))}
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('selled_at') && (
                    <Row label={<Th projects_layouts={projects_layouts}>売上日</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.selled_at || ''}
                          name="property[selled_at]"
                          id="property_selled_at"
                          type="date"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              selled_at: e.target.value,
                            })
                          }}
                          maxCalendarYear={3}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('buyer') && (
                    <Row label={<Th projects_layouts={projects_layouts}>買主</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.buyer || ''}
                          name="property[buyer]"
                          id="property_buyer"
                          type=""
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              buyer: e.target.value,
                            })
                          }}
                          maxCalendarYear={3}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('land_price') && (
                    <Row label={<Th projects_layouts={projects_layouts}>販売土地価格</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.land_price || ''}
                          suffix="円"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              land_price: e.target.value,
                            })
                          }}
                          onKeyUp={() => {
                            const calculateAmountOfSalesData = calculateAmountOfSales(propertyData)
                            const calculateBusinessIncomeYieldData =
                              calculateBusinessIncomeYield(propertyData)
                            const calculateProspectiveYieldData =
                              calculateProspectiveYield(propertyData)
                            const calculateProspectiveNoiYieldData =
                              calculateProspectiveNoiYield(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...calculateAmountOfSalesData,
                              ...calculateBusinessIncomeYieldData,
                              ...calculateProspectiveYieldData,
                              ...calculateProspectiveNoiYieldData,
                            })
                          }}
                          onBlur={(e) => {
                            const land_price = e.target.value
                              ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                              : ''
                            const calculateAmountOfSalesData = calculateAmountOfSales(propertyData)
                            const calculateBusinessIncomeYieldData =
                              calculateBusinessIncomeYield(propertyData)
                            const calculateProspectiveYieldData =
                              calculateProspectiveYield(propertyData)
                            const calculateProspectiveNoiYieldData =
                              calculateProspectiveNoiYield(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...calculateAmountOfSalesData,
                              ...calculateBusinessIncomeYieldData,
                              ...calculateProspectiveYieldData,
                              ...calculateProspectiveNoiYieldData,
                              land_price: land_price,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[land_price]"
                          id="property_land_price"
                          value={
                            propertyData?.land_price
                              ? dataPrecisionSetup(String(propertyData?.land_price), 12, 0)
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('building_price') && (
                    <Row label={<Th projects_layouts={projects_layouts}>販売建物価格</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.building_price || ''}
                          suffix="円"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              building_price: e.target.value,
                            })
                          }}
                          onKeyUp={() => {
                            const calculateAmountOfSalesData = calculateAmountOfSales(propertyData)
                            const calculateBusinessIncomeYieldData =
                              calculateBusinessIncomeYield(propertyData)
                            const calculateProspectiveYieldData =
                              calculateProspectiveYield(propertyData)
                            const calculateProspectiveNoiYieldData =
                              calculateProspectiveNoiYield(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...calculateAmountOfSalesData,
                              ...calculateBusinessIncomeYieldData,
                              ...calculateProspectiveYieldData,
                              ...calculateProspectiveNoiYieldData,
                            })
                          }}
                          onBlur={(e) => {
                            const building_price = e.target.value
                              ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                              : ''
                            const calculateAmountOfSalesData = calculateAmountOfSales(propertyData)
                            const calculateBusinessIncomeYieldData =
                              calculateBusinessIncomeYield(propertyData)
                            const calculateProspectiveYieldData =
                              calculateProspectiveYield(propertyData)
                            const calculateProspectiveNoiYieldData =
                              calculateProspectiveNoiYield(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...calculateAmountOfSalesData,
                              ...calculateBusinessIncomeYieldData,
                              ...calculateProspectiveYieldData,
                              ...calculateProspectiveNoiYieldData,
                              building_price: building_price,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[building_price]"
                          id="property_building_price"
                          value={
                            propertyData?.building_price
                              ? dataPrecisionSetup(String(propertyData?.building_price), 12, 0)
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('selling_price_without_tax') && (
                    <Row label={<Th></Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.selling_price_without_tax
                                ? formatNumber(propertyData?.selling_price_without_tax)
                                : ''
                            }
                            prefix="税抜売上高"
                            suffix="円"
                            readOnly
                            onChange={() => {
                              //
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[selling_price_without_tax]"
                            id="property_selling_price_without_tax"
                            value={propertyData?.selling_price_without_tax || ''}
                          />
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.selling_price
                                ? formatNumber(propertyData?.selling_price)
                                : ''
                            }
                            prefix="税込売上高"
                            suffix="円"
                            readOnly
                            onChange={() => {
                              //
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[selling_price]"
                            id="property_selling_price"
                            value={propertyData?.selling_price || ''}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('selling_unit_price') && (
                    <Row label={<Th></Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.selling_unit_price
                                ? formatNumber(propertyData?.selling_unit_price)
                                : ''
                            }
                            prefix="販売坪単価"
                            suffix="円"
                            readOnly
                            onChange={() => {
                              //
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[selling_unit_price]"
                            id="property_selling_unit_price"
                            value={propertyData?.selling_unit_price || ''}
                          />
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.selling_primary_unit_price
                                ? formatNumber(propertyData?.selling_primary_unit_price)
                                : ''
                            }
                            prefix="販売一種単価"
                            suffix="円"
                            readOnly
                            onChange={() => {
                              //
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[selling_primary_unit_price]"
                            id="property_selling_primary_unit_price"
                            value={propertyData?.selling_primary_unit_price || ''}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('total_expense_cost_price') && (
                    <Row label={<Th projects_layouts={projects_layouts}>諸経費合計(販売)</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.total_expense_cost_price || ''}
                          suffix="円"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              total_expense_cost_price: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const value = dataPrecisionSetup(e.target.value, 10, 0)
                            setPropertyData({
                              ...propertyData,
                              total_expense_cost_price: value ? cammedFormat(value) : '',
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[total_expense_cost_price]"
                          id="property_total_expense_cost_price"
                          value={
                            propertyData?.total_expense_cost_price
                              ? dataPrecisionSetup(
                                  String(propertyData?.total_expense_cost_price),
                                  12,
                                  0
                                )
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('business_income') && (
                    <Row label={<Th projects_layouts={projects_layouts}>事業収支</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.business_income || ''}
                            suffix="円"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                business_income: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const business_income = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                : ''
                              const calculateBusinessIncomeYieldData =
                                calculateBusinessIncomeYield(propertyData)
                              setPropertyData({
                                ...propertyData,
                                ...calculateBusinessIncomeYieldData,
                                business_income: business_income,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[business_income]"
                            id="property_business_income"
                            value={
                              propertyData?.business_income
                                ? dataPrecisionSetup(String(propertyData?.business_income), 12, 0)
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.business_income_yield
                                ? dataPrecisionSetup(
                                    propertyData?.business_income_yield.toString(),
                                    12,
                                    2
                                  ).toFixed(2)
                                : ''
                            }
                            name="property[business_income_yield]"
                            id="property_business_income_yield"
                            prefix="事業収支率"
                            suffix="%"
                            readOnly
                            onChange={() => {
                              //
                            }}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('tenancy_rate') && (
                    <Row label={<Th projects_layouts={projects_layouts}>稼働状況</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.tenancy_rate ? propertyData?.tenancy_rate.toString() : ''
                          }
                          suffix="%"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              tenancy_rate: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const value = e.target.value
                            setPropertyData({
                              ...propertyData,
                              tenancy_rate: value ? dataPrecisionSetup(value, 5, 2) : '',
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[tenancy_rate]"
                          id="property_tenancy_rate"
                          value={dataPrecisionSetup(
                            String(formatPercentageToDecimal(propertyData?.tenancy_rate || 0)),
                            5,
                            4
                          )}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('rent_per_tsubo') && (
                    <Row label={<Th projects_layouts={projects_layouts}>平均賃料（坪）</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.rent_per_tsubo ?? ''}
                          suffix="円"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              rent_per_tsubo: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const rent_per_tsubo = e.target.value
                              ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                              : ''
                            setPropertyData({
                              ...propertyData,
                              rent_per_tsubo: rent_per_tsubo,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[rent_per_tsubo]"
                          id="rent_per_tsubo"
                          value={
                            propertyData?.rent_per_tsubo
                              ? dataPrecisionSetup(String(propertyData?.rent_per_tsubo), 12, 0)
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('total_rent') && (
                    <Row
                      label={<Th projects_layouts={projects_layouts}>総額賃料（税込）/ 月額</Th>}
                    >
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.total_rent ?? ''}
                            suffix="円"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                total_rent: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const total_rent = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                : ''
                              const calculateProspectiveYieldData =
                                calculateProspectiveYield(propertyData)
                              setPropertyData({
                                ...propertyData,
                                ...calculateProspectiveYieldData,
                                total_rent: total_rent,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[total_rent]"
                            id="property_total_rent"
                            value={
                              propertyData?.total_rent
                                ? dataPrecisionSetup(String(propertyData?.total_rent), 12, 0)
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.prospective_yield
                                ? dataPrecisionSetup(
                                    propertyData?.prospective_yield.toString(),
                                    12,
                                    2
                                  ).toFixed(2)
                                : ''
                            }
                            prefix="販売表面利回り"
                            suffix="%"
                            readOnly
                            tabIndex={-1}
                            onChange={() => {
                              //
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[prospective_yield]"
                            id="property_prospective_yield"
                            value={propertyData?.prospective_yield || ''}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('prospective_noi') && (
                    <Row label={<Th projects_layouts={projects_layouts}>想定NOI（税込）/ 月額</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.prospective_noi ?? ''}
                            suffix="円"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                prospective_noi: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const prospective_noi = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                : ''
                              const calculateProspectiveNoiYieldData =
                                calculateProspectiveNoiYield(propertyData)
                              setPropertyData({
                                ...propertyData,
                                ...calculateProspectiveNoiYieldData,
                                prospective_noi: prospective_noi,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[prospective_noi]"
                            id="property_prospective_noi"
                            value={
                              propertyData?.prospective_noi
                                ? dataPrecisionSetup(String(propertyData?.prospective_noi), 12, 0)
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.prospective_noi_yield
                                ? dataPrecisionSetup(
                                    propertyData?.prospective_noi_yield.toString(),
                                    12,
                                    2
                                  ).toFixed(2)
                                : ''
                            }
                            prefix="販売想定NOI利回り"
                            suffix="%"
                            readOnly
                            tabIndex={-1}
                            onChange={() => {
                              //
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[prospective_noi_yield]"
                            id="property_prospective_noi_yield"
                            value={propertyData?.prospective_noi_yield || ''}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  <Row
                    label={
                      <Th required={checkRequiredItem('site_shape')}>
                        敷地形状
                        <span
                          className={`ml-2 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${
                            propertyShape === null || propertyShape === ''
                              ? 'border-[#d4c5c5]'
                              : 'border-primary'
                          }`}
                        >
                          <Button
                            className="w-[82px] self-center"
                            size="tiny"
                            type="button"
                            disabled={propertyShape === null || propertyShape === '' || loading}
                            title={
                              propertyShape === null || propertyShape === ''
                                ? '敷地形状を作成してください'
                                : ''
                            }
                            onClick={handlequickVolumeCheck}
                          >
                            {loading ? loadingIcon : 'area check'}
                          </Button>
                        </span>
                        {showNoAreaInfoMessage && (
                          <>
                            <span className="text-primary-font">
                              <br />
                              この地域の情報は提供しておりません
                            </span>
                          </>
                        )}
                      </Th>
                    }
                    className="bg-white"
                  >
                    <Td>
                      {isLoaded && (
                        <GoogleMapViewer
                          polygons_api_base_url={polygons_api_base_url}
                          current_user={current_user}
                          csrfToken={csrfToken.content}
                          mode="property_edit"
                          property_id={Number(propertyData?.id ?? '0')}
                          lat={property.address_geocoding[0] ?? latLng.lat}
                          lng={property.address_geocoding[1] ?? latLng.lng}
                          propertyLat={property.lat}
                          propertyLng={property.lng}
                          propertyShape={shape}
                          existdAreaValue={existdAreaValue}
                          areaTextFromResponse={responseArea}
                          areaShape={propertyShape}
                          requestUUID={requestUUID}
                          onChangePolygon={setPolygonChanged}
                          onChangeAreaCheck={setAreaChecked}
                          setChibanInfo={setChibanInfo}
                          onChangeLatLng={(latLng) => {
                            setPropertyLatLng(latLng)
                          }}
                          onChangeShape={(shape) => {
                            setPropertyShape(shape)
                          }}
                        />
                      )}
                      <input
                        value={propertyShape}
                        type="hidden"
                        name="property[shape]"
                        id="property_shape"
                      ></input>
                      <input
                        type="hidden"
                        value={propertyLatLng.lat}
                        name="property[lat]"
                        id="property_lat"
                      />
                      <input
                        type="hidden"
                        value={propertyLatLng.lng}
                        name="property[lng]"
                        id="property_lng"
                      />
                    </Td>
                  </Row>
                </tbody>
              </Table>
            </div>
          )}

          {!is_edit && (
            <>
              <input type="hidden" name="instant_create" value={instant_create} />
              <FileUpload
                hidden={true}
                name="property[attached_files][]"
                accept={AcceptableMimeTypes}
                filenameInitial={ocr.localFile?.name}
                ref={attachedFileRef}
              />
              <Table
                className="border-t border-b block mb-16 w-full"
                header={
                  <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                    基本情報
                  </div>
                }
              >
                <tbody className="block md:table-row-group">
                  <Address
                    mapRef={mapRef}
                    current_user={current_user}
                    prefectureInitial={propertyData ? propertyData.prefecture : property.prefecture}
                    cityInitial={propertyData ? propertyData.city : property.city}
                    townInitial={propertyData ? propertyData.town : property.town}
                    name={'property'}
                    data={propertyData}
                    ocrExtractedItems={ocr && ocr.extractedItems}
                    onChangeData={setPropertyData}
                    requiredItems={required_items}
                    error_messages={error_messages}
                    is_edit={is_edit}
                  />
                  <Row label={<Th>丁目以降（地番）</Th>}>
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] inline-flex"
                          value={inputChibanInfo ?? propertyData.chiban}
                          name="property[chiban]"
                          id="property_chiban"
                          placeholder="1-2-1, 1-2-2, 1-2-3"
                          onChange={(e) => {
                            setInputChibanInfo(e.target.value)
                            setPropertyData({
                              ...propertyData,
                              chiban: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const value = formatChiban(e.target.value)
                            setInputChibanInfo(value)
                            setPropertyData({
                              ...propertyData,
                              chiban: value,
                            })
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row label={<Th>建物名</Th>}>
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] inline-flex"
                          value={propertyData?.map_information || ''}
                          name="property[map_information]"
                          id="property_map_information"
                          placeholder="〜宅の東隣、○×宅　等"
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              map_information: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th required={checkRequiredItem('site_shape')}>
                        敷地形状
                        <span
                          className={`ml-2 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${
                            propertyShape === null || propertyShape === ''
                              ? 'border-[#d4c5c5]'
                              : 'border-primary'
                          }`}
                        >
                          <Button
                            className="w-[82px] self-center"
                            size="tiny"
                            type="button"
                            disabled={propertyShape === null || propertyShape === '' || loading}
                            title={
                              propertyShape === null || propertyShape === ''
                                ? '敷地形状を作成してください'
                                : ''
                            }
                            onClick={handlequickVolumeCheck}
                          >
                            {loading ? loadingIcon : 'area check'}
                          </Button>
                        </span>
                        {showNoAreaInfoMessage && (
                          <>
                            <span className="text-primary-font">
                              <br />
                              この地域の情報は提供しておりません
                            </span>
                          </>
                        )}
                      </Th>
                    }
                    className="bg-white"
                  >
                    <Td>
                      {isLoaded && (
                        <GoogleMapViewer
                          polygons_api_base_url={polygons_api_base_url}
                          current_user={current_user}
                          csrfToken={csrfToken.content}
                          mode="property_edit"
                          property_id={Number(propertyData?.id ?? '0')}
                          lat={property.address_geocoding[0] ?? latLng.lat}
                          lng={property.address_geocoding[1] ?? latLng.lng}
                          propertyLat={property.lat}
                          propertyLng={property.lng}
                          propertyShape={shape}
                          existdAreaValue={existdAreaValue}
                          areaTextFromResponse={responseArea}
                          areaShape={propertyShape}
                          requestUUID={requestUUID}
                          onChangePolygon={setPolygonChanged}
                          onChangeAreaCheck={setAreaChecked}
                          setChibanInfo={setChibanInfo}
                          onChangeLatLng={(latLng) => {
                            setPropertyLatLng(latLng)
                          }}
                          onChangeShape={(shape) => {
                            setPropertyShape(shape)
                          }}
                          ref={mapRef}
                        />
                      )}
                      <input
                        value={propertyShape}
                        type="hidden"
                        name="property[shape]"
                        id="property_shape"
                      ></input>
                      <input
                        type="hidden"
                        value={propertyLatLng.lat}
                        name="property[lat]"
                        id="property_lat"
                      />
                      <input
                        type="hidden"
                        value={propertyLatLng.lng}
                        name="property[lng]"
                        id="property_lng"
                      />
                    </Td>
                  </Row>
                  <Row label={<Th required={checkRequiredItem('property_type')}>物件種目</Th>}>
                    <Td>
                      <div className="flex gap-8">
                        {propertyTypeOptions.map((item, i) => (
                          <RadioButton
                            key={i}
                            name="property[property_type]"
                            id={`property_property_type_${i}`}
                            text={item.text}
                            value={item.value}
                            checked={item.text === propertyData?.property_type}
                            onChange={(e) => {
                              const suggestedUnitPriceChangedData =
                                suggestedUnitPriceChanged(propertyData)
                              let totalFloorAreaChangedData = {}
                              if (!is_edit && e.target.value !== '土地') {
                                totalFloorAreaChangedData = {
                                  ...totalFloorAreaChanged(propertyData),
                                }
                              }
                              setPropertyData({
                                ...propertyData,
                                ...suggestedUnitPriceChangedData,
                                ...totalFloorAreaChangedData,
                                property_type: e.target.value,
                                location_of_division:
                                  e.target.value === '土地＋建物（区分）'
                                    ? propertyData.location_of_division
                                    : '',
                              })
                            }}
                          />
                        ))}
                      </div>
                    </Td>
                  </Row>
                  {checkDisplayItem('local_situation') && (
                    <Row label={<Th>現況</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Select
                            className="md:w-[300px] inline-flex"
                            name="property[local_situation]"
                            id="property_local_situation"
                            value={propertyData?.local_situation || ''}
                            options={localSituationOptions}
                            hasBlank
                            blankLabel="（未設定）"
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                local_situation: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  <Row label={<Th required={checkRequiredItem('area_m3')}>敷地面積</Th>}>
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.area_m3 ?? ''}
                          suffix="㎡"
                          onChange={(e) => {
                            const area_m3 = e.target.value
                            setPropertyData({
                              ...propertyData,
                              area_m3,
                            })
                          }}
                          onBlur={(e) => {
                            const value = e.target.value
                              ? dataPrecisionSetup(e.target.value, 12, 2)
                              : 0
                            const data = {}
                            data['area_m3'] = value ? cammedFormat(value, 2) : ''
                            data['area_tsubo'] = value ? calculateTsubo(value) : ''
                            data['effective_area'] = propertyData?.effective_area
                              ? cammedFormat(intFormat(String(propertyData?.effective_area)), 2)
                              : 0

                            if (data['effective_area'] === 0) {
                              data['effective_area'] = value ? cammedFormat(value, 2) : ''
                              data['effective_area_tsubo'] = value ? calculateTsubo(value) : ''
                              data['suggested_unit_price'] =
                                value && propertyData?.property_type === '土地'
                                  ? intFormat(String(propertyData?.suggested_total_price)) /
                                    data['effective_area_tsubo']
                                  : propertyData?.suggested_unit_price
                            }
                            setPropertyData({
                              ...propertyData,
                              ...data,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          value={
                            propertyData?.area_m3 ? intFormat(String(propertyData?.area_m3)) : ''
                          }
                          name="property[area_m3]"
                          id="property_area_m3"
                        />
                        <Input
                          className="md:w-fit w-full"
                          value={propertyData?.area_tsubo || ''}
                          name="property[area_tsubo]"
                          id="property_area_tsubo"
                          suffix="坪"
                          placeholder=""
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row label={<Th required={checkRequiredItem('effective_area')}>有効敷地面積</Th>}>
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.effective_area ?? ''}
                          suffix="㎡"
                          onChange={(e) => {
                            const effective_area = e.target.value
                            setPropertyData({
                              ...propertyData,
                              effective_area,
                            })
                          }}
                          onBlur={(e) => {
                            const value = dataPrecisionSetup(e.target.value, 12, 2)
                            const effective_area = value ? cammedFormat(value, 2) : ''
                            const effective_area_tsubo = value ? calculateTsubo(value) : ''
                            const suggestedUnitPriceChangedData =
                              suggestedUnitPriceChanged(propertyData)
                            const calculateAmountOfSalesData = calculateAmountOfSales(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...suggestedUnitPriceChangedData,
                              ...calculateAmountOfSalesData,
                              effective_area,
                              effective_area_tsubo,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          value={
                            propertyData?.effective_area
                              ? intFormat(String(propertyData?.effective_area))
                              : ''
                          }
                          name="property[effective_area]"
                          id="property_effective_area"
                        />
                        <Input
                          className="md:w-fit w-full"
                          value={propertyData?.effective_area_tsubo || ''}
                          name="property[effective_area_tsubo]"
                          id="property_effective_area_tsubo"
                          suffix="坪"
                          placeholder=""
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row label={<Th required={checkRequiredItem('total_floor_area')}>延床面積</Th>}>
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.total_floor_area || ''}
                          suffix="㎡"
                          placeholder={getPlaceholderText(
                            propertyData?.property_type,
                            'spaceMetrics'
                          )}
                          onChange={(e) => {
                            const total_floor_area = e.target.value
                            setPropertyData({
                              ...propertyData,
                              total_floor_area: total_floor_area || '',
                            })
                          }}
                          onBlur={(e) => {
                            const occupiedAreaData = {}

                            const occupied_area = propertyData?.occupied_area
                              ? intFormat(String(propertyData?.occupied_area))
                              : 0

                            const value = dataPrecisionSetup(e.target.value, 12, 2)
                            const total_floor_area = value ? cammedFormat(value, 2) : ''
                            const total_floor_area_tsubo = value ? calculateTsubo(value) : ''
                            if (!occupied_area) {
                              occupiedAreaData['occupied_area'] = value
                                ? cammedFormat(value, 2)
                                : ''
                              occupiedAreaData['occupied_area_tsubo'] = value
                                ? calculateTsubo(value)
                                : ''
                            }
                            const calculateUnitPricePerUnitYieldData =
                              calculateUnitPricePerUnitYield(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...occupiedAreaData,
                              ...calculateUnitPricePerUnitYieldData,
                              total_floor_area,
                              total_floor_area_tsubo,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          value={
                            propertyData?.total_floor_area
                              ? intFormat(String(propertyData?.total_floor_area))
                              : ''
                          }
                          name="property[total_floor_area]"
                          id="property_total_floor_area"
                        />
                        <Input
                          className="md:w-fit w-full"
                          value={propertyData?.total_floor_area_tsubo || ''}
                          name="property[total_floor_area_tsubo]"
                          id="property_total_floor_area_tsubo"
                          suffix="坪"
                          placeholder=""
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            const calculateUnitPricePerUnitYieldData =
                              calculateUnitPricePerUnitYield(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...calculateUnitPricePerUnitYieldData,
                            })
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th required={checkRequiredItem('occupied_area')}>
                        建物賃貸可能面積（専有面積）
                      </Th>
                    }
                  >
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <Input
                          className="md:w-[300px] w-full"
                          value={propertyData?.occupied_area || ''}
                          suffix="㎡"
                          placeholder={getPlaceholderText(
                            propertyData?.property_type,
                            'spaceMetrics'
                          )}
                          onChange={(e) => {
                            const occupied_area = e.target.value
                            setPropertyData({
                              ...propertyData,
                              occupied_area,
                            })
                          }}
                          onBlur={(e) => {
                            const value = dataPrecisionSetup(e.target.value, 12, 2)
                            const occupied_area = value ? cammedFormat(value, 2) : ''
                            const occupied_area_tsubo = value ? calculateTsubo(value) : ''
                            const suggestedUnitPriceChangedData =
                              suggestedUnitPriceChanged(propertyData)
                            const calculateAmountOfSalesData = calculateAmountOfSales(propertyData)

                            setPropertyData({
                              ...propertyData,
                              ...suggestedUnitPriceChangedData,
                              ...calculateAmountOfSalesData,
                              occupied_area,
                              occupied_area_tsubo,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          value={
                            propertyData?.occupied_area
                              ? intFormat(String(propertyData?.occupied_area))
                              : ''
                          }
                          name="property[occupied_area]"
                          id="property_occupied_area"
                        />
                        <Input
                          className="md:w-fit w-full"
                          value={propertyData?.occupied_area_tsubo || ''}
                          name="property[occupied_area_tsubo]"
                          id="property_occupied_area_tsubo"
                          suffix="坪"
                          placeholder=""
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row
                    label={<Th required={checkRequiredItem('location_of_division')}>区分の場所</Th>}
                  >
                    <Td>
                      <Input
                        className="md:w-[300px]"
                        value={propertyData?.location_of_division || ''}
                        name="property[location_of_division]"
                        id="property_location_of_division"
                        readOnly={propertyData?.property_type !== '土地＋建物（区分）'}
                        tabIndex={propertyData?.property_type !== '土地＋建物（区分）' && -1}
                        placeholder={getPlaceholderText(
                          propertyData?.property_type,
                          'location_of_division'
                        )}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            location_of_division: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Row>
                  {propertyData?.transportations.map((transportation, index) => (
                    <TransportationRow
                      index={index}
                      transportation={transportation}
                      data={propertyData}
                      setData={setPropertyData}
                      dataLatLng={propertyLatLng}
                      defaultTransportationData={defaultTransportationData}
                      requestParamName="property"
                      projectsLayouts={projects_layouts}
                    />
                  ))}
                  {propertyData?.road_accesses.map((road_access, index) => (
                    <RoadAccessRow
                      index={index}
                      road_access={road_access}
                      data={propertyData}
                      setData={setPropertyData}
                      dataLatLng={propertyLatLng}
                      defaultRoadAccessData={defaultRoadAccessData}
                      requestParamName="road_accesses"
                    />
                  ))}
                  {propertyData?.area_info_hands.map((areainfo, index) => (
                    <AreaInfoHandRow
                      index={index}
                      areainfo={areainfo}
                      data={propertyData}
                      setData={setPropertyData}
                      dataLatLng={propertyLatLng}
                      defaultAreaInfoHandData={defaultAreaInfoHandData}
                      requestParamName="property"
                      projectsLayouts={projects_layouts}
                    />
                  ))}
                </tbody>
              </Table>

              <Table
                className="border-t border-b block mb-16"
                header={
                  <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                    入手情報
                  </div>
                }
              >
                <tbody className="block md:table-row-group">
                  <Row label={<Th required>ステータス</Th>}>
                    <Td>
                      <div className="flex gap-8">
                        {propertyStatusOptions.map((item, i) => (
                          <RadioButton
                            key={i}
                            name="property[status]"
                            id={`property_status_${i}`}
                            text={item.text}
                            value={item.value}
                            checked={item.value === propertyData?.ongoing}
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                ongoing: e.target.value,
                              })
                            }
                          />
                        ))}
                      </div>
                    </Td>
                  </Row>
                  {(current_user.role === 'system_admin' || current_user.role === 'admin') &&
                    checkDisplayItem('local_situation') && (
                      <Row label={<Th>入手日時</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={
                              propertyData?.recepted_at ||
                              format(parseISO(new Date().toISOString()), 'yyyy-MM-dd HH:mm')
                            }
                            name="property[sourced_at]"
                            id="property_sourced_at"
                            type="datetime-local"
                            onChange={(e) => {
                              //const newDate = new Date(e.target.value)
                              const recepted_at = format(
                                parseISO(e.target.value),
                                'yyyy-MM-dd HH:mm'
                              )

                              setPropertyData({
                                ...propertyData,
                                recepted_at,
                              })
                            }}
                            maxCalendarYear={3}
                          />
                        </Td>
                      </Row>
                    )}
                  <Row label={<Th>案件名</Th>}>
                    <Td>
                      <Input
                        className="md:w-[300px]"
                        value={propertyData?.ongoing !== 'ongoing' ? '' : propertyData?.name ?? ''}
                        readOnly={propertyData?.ongoing !== 'ongoing'}
                        name="property[name]"
                        id="property_name"
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            name: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Row>
                  <Row label={<Th required>情報入手者</Th>}>
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <DropDown
                          className="md:w-[300px]"
                          name="property[source_user_id]"
                          id="property_source_user_id"
                          value={currentSourceUser}
                          options={usersOptions}
                          placeholder="(未設定)"
                          onChange={onChangeCurrentSourceUser}
                        />
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.source_department_name ?? ''}
                          placeholder="(未設定)"
                          name="source_department_name"
                          id="source_department_name"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  <Row label={<Th required={propertyData?.ongoing !== 'yet'}>案件担当者</Th>}>
                    <Td>
                      <div className="flex gap-4 flex-col md:flex-row">
                        <DropDown
                          className="md:w-[300px]"
                          name="property[user_id]"
                          id="property_user_id"
                          value={propertyData?.ongoing === 'ongoing' && currentPropertyUser}
                          options={usersOptions}
                          hasBlank
                          disabled={propertyData?.ongoing !== 'ongoing'}
                          placeholder="(未設定)"
                          onChange={onChangeCurrentPropertyUser}
                        />
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.ongoing !== 'ongoing'
                              ? '(未設定)'
                              : propertyData?.department_name ?? ''
                          }
                          placeholder=""
                          disabled={propertyData?.ongoing !== 'ongoing'}
                          name="department_name"
                          id="department_name"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </div>
                    </Td>
                  </Row>
                  {checkDisplayItem('source') && (
                    <>
                      <Row label={<Th required={checkRequiredItem('source')}>入手先企業</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.source ?? ''}
                            name="property[source]"
                            id="property_source"
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                source: e.target.value,
                              })
                            }
                            list="sources-list"
                            datalist={sources_list}
                          />
                        </Td>
                      </Row>
                      <Row label={<Th>入手先支店/部署</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.source_branch ?? ''}
                            name="property[source_branch]"
                            id="property_source_branch"
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                source_branch: e.target.value,
                              })
                            }
                            list="sources-branch-list"
                            datalist={sources_branch_list}
                          />
                        </Td>
                      </Row>
                      <Row label={<Th>入手先担当者</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.source_staff ?? ''}
                            name="property[source_staff]"
                            id="property_source_staff"
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                source_staff: e.target.value,
                              })
                            }
                            list="sources-staff-list"
                            datalist={sources_staff_list}
                          />
                        </Td>
                      </Row>
                    </>
                  )}
                  {checkDisplayItem('negotiation_method') && (
                    <Row label={<Th>商談方式</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <DropDown
                            className="md:w-[300px]"
                            name="property[negotiation_method]"
                            id="property_negotiation_method"
                            value={propertyData?.ongoing === 'ongoing' && currentNegotiationMethod}
                            options={negotiationMethodOptions}
                            hasBlank
                            disabled={propertyData?.ongoing !== 'ongoing'}
                            placeholder="(未設定)"
                            onChange={onChangeCurrentNegotiationMethod}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('seller') && (
                    <Row label={<Th>売主</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.seller ?? ''}
                          name="property[seller]"
                          id="property_seller"
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              seller: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('suggested_total_price') && (
                    <Row label={<Th>売主希望総額</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={propertyData?.suggested_total_price ?? ''}
                          suffix="円"
                          onChange={(e) => {
                            setPropertyData({
                              ...propertyData,
                              suggested_total_price: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            const suggested_total_price = e.target.value
                              ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                              : ''
                            const suggestedUnitPriceChangedData =
                              suggestedUnitPriceChanged(propertyData)
                            setPropertyData({
                              ...propertyData,
                              ...suggestedUnitPriceChangedData,
                              suggested_total_price: suggested_total_price,
                            })
                          }}
                        />
                        <input
                          type="hidden"
                          name="property[suggested_total_price]"
                          id="property_suggested_total_price"
                          value={
                            propertyData?.suggested_total_price
                              ? intFormat(String(propertyData?.suggested_total_price))
                              : ''
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('suggested_unit_price') && (
                    <Row label={<Th>売主希望坪単価</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={
                            propertyData?.suggested_unit_price
                              ? formatNumber(propertyData?.suggested_unit_price)
                              : ''
                          }
                          suffix="円"
                          readOnly
                          tabIndex={-1}
                          onChange={() => {
                            //
                          }}
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('note') && (
                    <Row label={<Th>備考</Th>}>
                      <Td>
                        <Textarea
                          className="md:w-[615px]"
                          value={propertyData?.note ?? ''}
                          name="property[note]"
                          id="property_note"
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              note: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  )}
                </tbody>
              </Table>

              <Table
                className="border-t border-b block mb-16"
                header={
                  <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                    事業情報
                  </div>
                }
              >
                <tbody className="block md:table-row-group">
                  {checkDisplayItem('business_type_id') && (
                    <Row label={<Th required={checkRequiredItem('business_type_id')}>事業形態</Th>}>
                      <Td>
                        <Select
                          className="md:w-[300px]"
                          name="property[business_type_id]"
                          id="property_business_type_id"
                          value={propertyData?.business_type_id ?? ''}
                          options={businessTypeOptions}
                          hasBlank
                          blankLabel="(未設定)"
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              business_type_id: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('current_rent') && (
                    <Row label={<Th>現況賃料（税込）/ 月額</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.current_rent ?? ''}
                            suffix="円"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                current_rent: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const current_rent = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                : ''
                              const calculateCurrentProspectiveYieldData =
                                calculateCurrentProspectiveYield(propertyData)
                              setPropertyData({
                                ...propertyData,
                                ...calculateCurrentProspectiveYieldData,
                                current_rent: current_rent,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[current_rent]"
                            id="property_current_rent"
                            value={
                              propertyData?.current_rent
                                ? intFormat(String(propertyData?.current_rent))
                                : ''
                            }
                          />
                          {checkDisplayItem('current_prospective_yield') && (
                            <>
                              <Input
                                className="md:w-fit"
                                value={
                                  propertyData?.current_prospective_yield
                                    ? dataPrecisionSetup(
                                        propertyData?.current_prospective_yield.toString(),
                                        12,
                                        2
                                      ).toFixed(2)
                                    : ''
                                }
                                prefix="現況表面利回り"
                                suffix="%"
                                readOnly
                                tabIndex={-1}
                                onChange={() => {
                                  //
                                }}
                              />
                              <input
                                type="hidden"
                                name="property[current_prospective_yield]"
                                id="property_current_prospective_yield"
                                value={propertyData?.current_prospective_yield || ''}
                              />
                            </>
                          )}
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('number_of_buildings') && (
                    <Row label={<Th>棟数（区画）</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.number_of_buildings || ''}
                            suffix="棟（区画）"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                number_of_buildings: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const value = dataPrecisionSetup(e.target.value, 10, 0)
                              setPropertyData({
                                ...propertyData,
                                number_of_buildings: value ? cammedFormat(value) : '',
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[number_of_buildings]"
                            id="property_number_of_buildings"
                            value={
                              propertyData?.number_of_buildings
                                ? dataPrecisionSetup(
                                    String(propertyData?.number_of_buildings),
                                    10,
                                    0
                                  )
                                : ''
                            }
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('number_of_units') && (
                    <Row label={<Th>戸数</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px] inline-block"
                            value={propertyData?.number_of_units || ''}
                            suffix="戸"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                number_of_units: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const value = dataPrecisionSetup(e.target.value, 10, 0)
                              setPropertyData({
                                ...propertyData,
                                number_of_units: value ? cammedFormat(value) : '',
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[number_of_units]"
                            id="property_number_of_units"
                            value={
                              propertyData?.number_of_units
                                ? dataPrecisionSetup(String(propertyData?.number_of_units), 10, 0)
                                : ''
                            }
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('building_structure') && (
                    <Row label={<Th>建物構造</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Select
                            className="md:w-[300px]"
                            name="property[building_structure]"
                            id="property_building_structure"
                            value={propertyData?.building_structure ?? ''}
                            options={buildingStructureOptions}
                            hasBlank
                            blankLabel="(未設定)"
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                building_structure: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('floors') && (
                    <Row label={<Th>建物階数</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.floors_above_ground || ''}
                            name="property[floors_above_ground]"
                            id="property_floors_above_ground"
                            prefix="地上"
                            suffix="階"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                floors_above_ground: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const value = dataPrecisionSetup(e.target.value, 10, 0)
                              setPropertyData({
                                ...propertyData,
                                floors_above_ground: value ? cammedFormat(value) : '',
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[floors_above_ground]"
                            id="property_floors_above_ground"
                            value={
                              propertyData?.floors_above_ground
                                ? dataPrecisionSetup(
                                    String(propertyData?.floors_above_ground),
                                    10,
                                    0
                                  )
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.floors_under_ground || ''}
                            name="property[floors_under_ground]"
                            id="property_floors_under_ground"
                            prefix="地下"
                            suffix="階"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                floors_under_ground: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              const value = dataPrecisionSetup(e.target.value, 10, 0)
                              setPropertyData({
                                ...propertyData,
                                floors_under_ground: value ? cammedFormat(value) : '',
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="property[floors_under_ground]"
                            id="property_floors_under_ground"
                            value={
                              propertyData?.floors_under_ground
                                ? dataPrecisionSetup(
                                    String(propertyData?.floors_under_ground),
                                    10,
                                    0
                                  )
                                : ''
                            }
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('build_completed_at') && (
                    <Row label={<Th>竣工日</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={propertyData?.build_completed_at || ''}
                            name="property[build_completed_at]"
                            id="property_build_completed_at"
                            type="date"
                            onChange={(e) => {
                              setPropertyData({
                                ...propertyData,
                                build_completed_at: e.target.value,
                              })
                            }}
                            maxCalendarYear={3}
                          />
                        </div>
                      </Td>
                    </Row>
                  )}
                  {checkDisplayItem('certificate_of_inspection') && (
                    <Row label={<Th>検査済証</Th>}>
                      <Td>
                        <div className="flex gap-8">
                          {propertyCertificateOfInspectionOptions.map((item, i) => (
                            <RadioButton
                              key={i}
                              name="property[certificate_of_inspection]"
                              id={`property_certificate_of_inspection_${i}`}
                              text={item.text}
                              value={item.value}
                              checked={item.text === propertyData?.certificate_of_inspection}
                              onChange={(e) => {
                                setPropertyData({
                                  ...propertyData,
                                  certificate_of_inspection: e.target.value,
                                  location_of_division:
                                    e.target.value === '土地＋建物（区分）'
                                      ? propertyData.location_of_division
                                      : '',
                                })
                              }}
                            />
                          ))}
                        </div>
                      </Td>
                    </Row>
                  )}
                </tbody>
              </Table>

              <AreaInfo
                responseAreaData={responseAreaData}
                propertyData={propertyData}
                prefectureName={prefectureName}
              />
              {responseAreaData && <br />}
            </>
          )}

          <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-150 md:py-2 fixed bottom-0 md:left-12 z-20">
            {ocr.jobStatus && (
              <>
                {/* this flag intend to keep showing same screen (file previews showing up) even if form submit get validation error */}
                <input type="hidden" name="ocr_mode_enabled" value="true" />
                <input
                  type="hidden"
                  name="ocr_area_info"
                  value={
                    propertyData?.ocr_area_info ? JSON.stringify(propertyData.ocr_area_info) : ''
                  }
                />
                <input type="hidden" name="ocr_file_blob_id" value={ocr.blobSignedId} />
              </>
            )}
            {ocr.jobStatus == 'preview' ? (
              <div className="flex gap-4">
                <Button
                  className="w-full md:w-40 md:h-auto text-gray-300"
                  variant="text"
                  onClick={(e) => {
                    e.preventDefault()
                    setOcr((prevState) => {
                      return {
                        ...prevState,
                        jobStatus: undefined,
                      }
                    })
                  }}
                >
                  キャンセル
                </Button>
                <Button
                  className="w-full md:w-40 md:h-auto !py-1"
                  variant="primaryLight"
                  onClick={(e) => {
                    e.preventDefault()
                    setOcr((prevState) => {
                      return {
                        ...prevState,
                        jobStatus: 'uploadOnly',
                      }
                    })
                  }}
                >
                  資料を保存して
                  <br />
                  入力に戻る
                </Button>
                <Button
                  className="w-full md:w-40 md:h-auto"
                  disabled={!ocr.localFileValidity}
                  onClick={(e) => {
                    e.preventDefault()
                    setOcr((prevState) => {
                      return {
                        ...prevState,
                        jobStatus: 'fileUpload',
                      }
                    })
                  }}
                >
                  OCRで読取
                </Button>
              </div>
            ) : (
              <div className="flex flex-grow justify-between w-full md:w-auto md:mx-auto ">
                <Button
                  className="w-1/2 md:w-40 md:h-auto text-gray-300 ml-auto"
                  variant="text"
                  onClick={(e) => {
                    e.preventDefault()
                    setDialogOpen(true)
                  }}
                >
                  キャンセル
                </Button>
                <Tippy
                  theme="light"
                  content={'形状編集後、area checkボタンを押してください'}
                  visible={isclickDisabled}
                  className="p-2 rounded"
                  placement="top"
                >
                  <span></span>
                </Tippy>
                <span className="w-1/2">
                  <Button
                    className="w-full md:w-40 md:h-auto"
                    disabled={isclickDisabled || loading}
                  >
                    {is_edit ? '編集を保存' : '登録'}
                  </Button>
                </span>
              </div>
            )}
          </div>
          <input type="hidden" value={newMemo ?? ''} name="memo[content]" id="memo_content"></input>

          {/* 進捗管理 */}
          {is_edit && (
            <CommentForm
              memosData={memos ?? []}
              property={property}
              newMemoChange={newMemoChange}
              editMemo={newMemo}
            />
          )}

          {/* キャンセル確認のダイアログ */}
          <ConfirmDialog open={dialogOpen} onClose={onCloseDialog}>
            <div className="text-black-base text-sm text-center">
              <p>入力内容を保存せずに画面を閉じます。</p>
              <br />
              <p>よろしいですか？</p>
            </div>
          </ConfirmDialog>
        </OCRLayout>
      </form>
    </Base>
  )
}

export default PropertiesEditAndNewPage
