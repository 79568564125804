import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import * as React from 'react'

type Props = {
  children?: React.ReactNode;
  className?: string;
  header?: React.ReactNode;
  topScrollbar?: boolean;
  borderLine?: boolean;
  scrollDep?: any;
  tableContainerRef?: React.MutableRefObject<HTMLDivElement>;
  max_width?: string;
  children?: React.ReactNode
  className?: string
  header?: React.ReactNode
  topScrollbar?: boolean
  borderLine?: boolean
  scrollDep?: any
  tableContainerRef?: React.MutableRefObject<HTMLDivElement>
  table_wrapper?: string
}

export const Table: React.FC<Props> = ({
  className,
  children,
  header,
  table_wrapper = '',
  topScrollbar = false,
  borderLine = false,
  scrollDep,
  tableContainerRef,
  max_width = "",
}) => {
  tableContainerRef = tableContainerRef || React.useRef<HTMLDivElement>()
  const topScrollContainerRef = React.useRef<HTMLDivElement>()
  const [tableWidth, setTableWidth] = React.useState<number>(0)

  React.useEffect(() => {
    setTableWidth(tableContainerRef.current.scrollWidth)
  }, [scrollDep])

  const handleTopScroll = React.useCallback(() => {
    tableContainerRef.current.scrollLeft = topScrollContainerRef.current.scrollLeft
  }, [tableContainerRef])

  const handleTableScroll = React.useCallback(() => {
    if (topScrollbar) {
      topScrollContainerRef.current.scrollLeft = tableContainerRef.current.scrollLeft
    }
  }, [topScrollContainerRef])

  return (
    <>
      {header}
      {topScrollbar && (
        <div
          className="relative overflow-x-auto"
          ref={topScrollContainerRef}
          onScroll={handleTopScroll}
        >
          <div className={`h-[1px]`} style={{ width: `${tableWidth}px` }} />
        </div>
      )}

      <div
        className={[`relative overflow-x-auto ${table_wrapper}`, className].join(' ')}
        ref={tableContainerRef}
        onScroll={handleTableScroll}
      >
        <table
          className={`${styles.table} ${
            borderLine && 'border-t border-t-gray-200 border-b border-b-gray-200'
          } ${max_width}`}
        >
          {children}
        </table>
      </div>
    </>
  )
}

const styles = {
  table: `
    w-full
    text-sm
    text-left
    text-black-base
  `,
}

export const Row: React.FC<{
  label?: React.ReactNode
  children?: React.ReactNode
  className?: string
}> = ({ label, children, className }) => (
  <tr
    className={[`bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white`, className].join(
      ' '
    )}
  >
    {label}
    {children}
  </tr>
)

export const Th: React.FC<{
  children?: React.ReactNode
  required?: boolean
  projects_layouts?: string[]
}> = ({ children, required = false, projects_layouts = [] }) => {
  return (
    <th
      scope="row"
      className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
    >
      {children}
      {(projects_layouts?.includes(children) ||
        (Array.isArray(children) && projects_layouts?.includes(children[0]))) && (
        <span className="ml-2 inline-block py-0.1 px-0.1 bg-primary text-white">
          <ClassOutlinedIcon />
        </span>
      )}
      {required && (
        <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
          必須
        </span>
      )}
    </th>
  )
}

export const Td: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  return <td className={`py-2 px-4 md:w-3/4 block md:table-cell`}>{children}</td>
}
