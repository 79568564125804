import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { User } from '@/types/user'
import type { Company } from '@/types/company'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { pricingType } from '@/config/langs/company'
import { AnchorButton } from '@/components/Button'
import { Breadcrumb } from '@/components/Breadcrumb'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import BuildIcon from '@material-ui/icons/Build';

type Props = {
  current_user: User
  companies: Company[]
}

const formatZipCode = (code: string): string => {
  return code.slice(0, 3) + '-' + code.slice(3, code.length)
}

const CompaniesIndexPage: React.FC<Props> = ({ current_user, companies }) => {
  const title = '利用企業設定'
  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['利用企業設定']} />}
      submenuCategory="settings"
    >
      <div className="flex flex-wrap items-end justify-between m-1">
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          <span className="text-primary-font text-xl font-medium">
            {companies.length.toLocaleString()}
          </span>
          <span className="text-gray-800 text-sm mt-0.5">件</span>
        </div>
        {current_user.role === 'system_admin' && (
          <AnchorButton
            className="text-primary-font"
            prefix={<AddOutlinedIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href="/companies/new"
          >
            <span className="hidden md:inline">新規利用企業登録</span>
          </AnchorButton>
        )}
      </div>
      <Table
        className={
          window.innerHeight > 920
            ? 'h-[760px] md:h-[760px]'
            : 'h-[calc(100vh_-_145px)] md:h-[calc(100vh_-_155px)]'
        }
        borderLine={false}
        topScrollbar={true}
      >
        <thead className="bg-gray-150 text-sm sticky top-0">
          <tr className="whitespace-nowrap">
            <th scope="col" className="py-2 px-4 font-medium">
              No.
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              会社名
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              料金タイプ
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              契約容量
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              使用中容量
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              郵便番号
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              都道府県
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              市区町村
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              その他住所
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              電話番号
            </th>
            <th scope="col" className="py-2 px-4 font-medium">
              ユーザー
            </th>
            {['system_admin', 'admin'].includes(current_user.role) && (
              <>
                <th scope="col" colSpan={2} className="py-2 px-4 font-medium"></th>
                <th scope="col" colSpan={2} className="py-2 px-4 font-medium"></th>
              </>
            )}
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {companies.map((company, index) => (
            <tr
              key={index}
              className={`${
                index % 2 ? 'bg-gray-light' : 'bg-white'
              } hover:bg-targetLine cursor-pointer`}
            >
              <td>
                <a
                  href={`/companies/${company.id}/edit`}
                  className="block w-full h-full py-2 px-4 text-right"
                >
                  {index + 1}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {company.name}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {pricingType[company.pricing_type]}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {company.file_limit_with_unit || '　'}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {company.file_usage_with_unit || '　'}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {formatZipCode(String(company.zipcode))}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {company.prefecture}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {company.city}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {company.address}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {company.phone_number}
                </a>
              </td>
              <td>
                <a href={`/companies/${company.id}/edit`} className="block w-full h-full py-2 px-4">
                  {company.users.length}人
                </a>
              </td>
              <td className="py-2 px-4 text-right">
              {(current_user.role === 'system_admin' || current_user.role === 'admin') && 
                current_user.company.id === company.id && (
                  <td className="py-2 px-4 text-right">
                    <AnchorButton
                      className="text-primary-font"
                      prefix={<BuildIcon fontSize="small" />}
                      outline
                      size="small"
                      variant="primary"
                      href={`/companies/${company.id}/company_display_order_setting`}
                    >
                      <span className="text-primary-font hidden md:block">入手情報カラム順設定</span>
                    </AnchorButton>
                  </td>
              )}
              </td>
              {current_user.role === 'system_admin' && (
                <td className="py-2 px-4 text-right">
                  {current_user.company.id !== company.id && (
                    <AnchorButton
                      className="text-primary-font"
                      prefix={<SettingsOutlinedIcon fontSize="small" />}
                      outline
                      size="small"
                      variant="primary"
                      href={
                        company.proxy_user
                          ? `/users/${company.proxy_user.id}/edit`
                          : `/users/new?proxy_company_id=${company.id}`
                      }
                    >
                      <span className="text-primary-font hidden md:block">代理ログイン</span>
                    </AnchorButton>
                  )}
                </td>
              )}
              {current_user.role === 'system_admin' && (
                <td className="py-2 px-4 text-right">
                  <a
                    className="inline-block w-[30px] hover:text-deleteHover"
                    data-confirm={`\u0022${company.name}\u0022を削除しますか？`}
                    rel="nofollow"
                    data-method="delete"
                    href={`/companies/${company.id}`}
                  >
                    <DeleteOutlined fontSize="small" />
                  </a>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Base>
  )
}

export default CompaniesIndexPage
