import { tagIcons } from "@/models/tagIcons";
import { LatLng } from "@/types/property";
import { EventEmitter } from "events";
import { MapModeType } from "./types/MapModeType";

export type EmitterEventType = "setModalProperty" | "setIsModalOpen" | "onChangeShape" | "onChangePolygon" | "onChangeAreaCheck" | "onChangeLatLng"

// 物件マーカー。
export class PropertyMarker {
  private markerList = []
  private polygonsHash = {}
  private map = null
  private mode: MapModeType = ""
  private property_id = 0
  private propertyLat?: number
  private propertyLng?: number
  private propertyShape?: LatLng[]
  private csrfToken = ""
  private currentPolygon = null // 図形のポリゴン
  private areaText = null
  private newProperty?: google.maps.Marker = null // 物件ピン
  private areaMarkers = []
  private drawingManager
  private areaTextFromResponse
  private siteArea
  private areaShape

  markerRefresh = false
  readonly emitter = new EventEmitter();

  constructor({ map, mode, property_id, propertyLat, propertyLng, propertyShape, areaTextFromResponse, siteArea, areaShape, csrfToken }:
    { map: google.maps.Map, mode: MapModeType, property_id: number, propertyLat: number, propertyLng: number, propertyShape: LatLng[], areaTextFromResponse?, siteArea?, areaShape?, csrfToken: string }) {
    this.map = map
    this.mode = mode
    this.property_id = property_id
    this.propertyLat = propertyLat
    this.propertyLng = propertyLng
    this.propertyShape = propertyShape
    this.areaTextFromResponse = areaTextFromResponse
    this.siteArea = siteArea
    this.areaShape = areaShape
    this.csrfToken = csrfToken
  }

  idle(params, showOtherPropertiesClicked) {
    // マーカーのリフレッシュ
    if (this.markerList.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[...this.markerList].forEach((marker) => {
        marker.setMap()
      })
      this.markerList.length = 0
    }
    // 物件リストのmarkerを追加
    const query = new URLSearchParams(params)
    fetch(`/api/properties?${query}`)
      .then((res) => res.json())
      .then((json) => {
        json.res.forEach((marker) => {
          if (marker.lat < 0 || marker.lng < 0) return
          if (this.mode === "property_edit" && marker.id === this.property_id) return
          // マーカーを追加
          this.markerList.push(this.addMarker(marker, this.map, marker.tag_id, marker.tag_color, showOtherPropertiesClicked))
          // 敷地を表示
          if (
            (this.mode === 'list' || showOtherPropertiesClicked) &&
            marker.shape &&
            !this.polygonsHash[marker.id]
          ) {
            this.drawArea(marker, this.map)
          }
          if (!showOtherPropertiesClicked) {
            this.deleteArea()
            this.markerList.length = 0
          }
        })
      })
      .catch((err) => {
        console.error(err)
      })
    this.markerRefresh = false
  }

  /** mapクリック時にマーカーを追加 */
  addClickMarker() {
    /** 隠蔽しすぎ？ */
    if (this.mode === "property_edit") {
      google.maps.event.addListener(this.map, 'click', (event) => {
        this.propertyLat = event.latLng.lat()
        this.propertyLng = event.latLng.lng()
        // 物件のピンを追加
        this.putNewProperty(true)
        const lat = this.newProperty.getPosition().lat()
        const lng = this.newProperty.getPosition().lng()
        this.onChangeLatLng({ lat: lat.toString(), lng: lng.toString() })
        google.maps.event.addListener(this.newProperty, 'dragend', () => {
          const lat = this.newProperty.getPosition().lat()
          const lng = this.newProperty.getPosition().lng()
          this.onChangeLatLng({ lat: lat.toString(), lng: lng.toString() })
          this.onChangePolygon(true)
        })
      })
    } else {
      google.maps.event.addListener(this.map, 'click', (event) => {
        this.addMarker({ id: 0, lat: event.latLng.lat(), lng: event.latLng.lng() }, this.map, undefined, undefined, undefined)
      })
    }
  }

  /** マーカーをリロード */
  reloadMarker() {
    this.markerRefresh = true
    google.maps.event.trigger(this.map, 'idle')
  }

  setupMarker() {
    let fetchURL = `/properties/${this.property_id}/shape`

    // 過去分ボリュームチェック敷地履歴の描画
    if (this.mode == 'volume_detail') {
      const currentUrl = new URL(window.location.href)
      const pathParams = currentUrl.pathname.split('/')
      const volume_check_request_id = pathParams[pathParams.length - 1]
      if (volume_check_request_id) {
        let fetchParams = new URLSearchParams({ ref: volume_check_request_id.toString() })
        fetchURL += `?${fetchParams.toString()}`
      }
    } else if (this.mode == 'volume') {
      let currentSearchParams = new URLSearchParams(window.location.search)
      if (currentSearchParams.has('ref')) {
        let fetchParams = new URLSearchParams({ ref: currentSearchParams.get('ref') })
        fetchURL += `?${fetchParams.toString()}`
      }
    }

    if (this.mode != "property_edit") {
      fetch(fetchURL)
        .then((res) => res.json())
        .then((json) => {
          if (json.status == 'NG') return
          const draggable = this.mode != 'property'
          const editable = draggable && this.mode != 'volume_detail'
          const polygon = this.initializePolygon(json.shape, editable, draggable)
          polygon.setMap(this.map)
          this.showAreaMarkers(polygon)
          const shape = []
          polygon.getPath().forEach((latlng) => {
            shape.push({ lat: latlng.lat(), lng: latlng.lng() })
          })
          this.onChangeShape(JSON.stringify(shape))
          if (this.mode == 'volume') {
            google.maps.event.addListener(polygon, 'mouseup', () => {
              this.showAreaText(polygon)
              this.showAreaMarkers(polygon)
              void this.createParameters(polygon)
              const shape = []
              polygon.getPath().forEach((latlng) => {
                shape.push({ lat: latlng.lat(), lng: latlng.lng() })
              })
              this.onChangeShape(JSON.stringify(shape))
              if (this.onChangePolygon) {
                this.onChangePolygon(true)
              }
              if (this.onChangeAreaCheck) {
                this.onChangeAreaCheck(false)
              }
            })
            this.drawingManager.setDrawingMode(null)
          }
          this.currentPolygon = polygon
          this.showAreaText(polygon)
          void this.createParameters(polygon)
        })
        .catch((err) => {
          console.error(err)
        })
    }
    if (
      this.mode === 'property_edit' ||
      this.mode === 'property' ||
      this.mode === 'volume' ||
      this.mode === 'volume_detail' ||
      this.mode === 'market_datum_report') {
      const hasAreaText = this.areaTextFromResponse !== '' || this.siteArea !== ''
      const hasAreaShape = this.areaShape !== null

      if (hasAreaText && hasAreaShape) {
        const areaValue = this.areaTextFromResponse || this.siteArea
        this.showAreaText(this.areaShape, areaValue)
      }
    }
  }

  // ドローイングマネージャーを追加
  setupDrawingManager() {
    // DrawingManagerを生成
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null, // PAN
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      //ポリゴンのオプション
      polygonOptions: {
        draggable: true,
        fillColor: '#55ee55',
        fillOpacity: 0.5,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        zIndex: 1,
      },
    })

    // Mapに割り当て
    this.drawingManager.setMap(this.map)

    // 敷地の描画
    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
      this.showAreaText(polygon)
      this.showAreaMarkers(polygon)
      if (this.mode == 'volume') {
        const shape = []
        polygon.getPath().forEach((latlng) => {
          shape.push({ lat: latlng.lat(), lng: latlng.lng() })
        })
        this.onChangeShape(JSON.stringify(shape))
        this.onChangePolygon(true)
        if (this.onChangePolygon) {
          this.onChangePolygon(true)
        }
        if (this.onChangeAreaCheck) {
          this.onChangeAreaCheck(false)
        }

        google.maps.event.addListener(polygon, 'mouseup', () => {
          this.showAreaText(polygon)
          this.showAreaMarkers(polygon)
          const shape = []
          polygon.getPath().forEach((latlng) => {
            shape.push({ lat: latlng.lat(), lng: latlng.lng() })
          })
          this.onChangeShape(JSON.stringify(shape))
          if (this.onChangePolygon) {
            this.onChangePolygon(true)
          }
          if (this.onChangeAreaCheck) {
            this.onChangeAreaCheck(false)
          }
        })
        if (!(this.currentPolygon === null)) {
          this.currentPolygon.setMap()
        }
        this.currentPolygon = polygon
        this.drawingManager.setDrawingMode(null)
        void this.createParameters(polygon)
      } else {
        if (!(this.currentPolygon === null)) {
          this.currentPolygon.setMap()
        }
        const shape = []

        polygon.getPath().forEach((latlng) => {
          shape.push({ lat: latlng.lat(), lng: latlng.lng() })
        })
        if (this.newProperty === null) {
          const bounds = new google.maps.LatLngBounds()

          polygon.getPath().forEach((latlng) => {
            bounds.extend(latlng)
          })
          this.putNewProperty(true, bounds.getCenter())
          this.showAreaText(polygon)

          const lat = this.newProperty.getPosition().lat()
          const lng = this.newProperty.getPosition().lng()
          this.onChangeLatLng({ lat: lat.toString(), lng: lng.toString() })
        }
        this.onChangeShape(JSON.stringify(shape))
        if (this.onChangePolygon) {
          this.onChangePolygon(true)
        }
        if (this.onChangeAreaCheck) {
          this.onChangeAreaCheck(false)
        }
        this.showAreaMarkers(polygon)
        google.maps.event.addListener(polygon, 'mouseup', () => {
          shape.splice(0)

          polygon.getPath().forEach((latlng) => {
            shape.push({ lat: latlng.lat(), lng: latlng.lng() })
          })
          this.onChangeShape(JSON.stringify(shape))
          if (this.onChangePolygon) {
            this.onChangePolygon(true)
          }
          if (this.onChangeAreaCheck) {
            this.onChangeAreaCheck(false)
          }
          this.showAreaText(polygon)
          this.showAreaMarkers(polygon)
        })
        this.drawingManager.setDrawingMode(null)
        this.currentPolygon = polygon
      }
    })

    // 敷地の削除ボタンを追加（対象モード： mode == 'property_edit' || mode == 'volume'）
    const deleteButton = document.createElement('button')

    deleteButton.textContent = '描画削除'
    deleteButton.classList.add('custom-map-control-button')
    deleteButton.setAttribute('type', 'button')
    deleteButton.style.fontSize = '14px'
    deleteButton.style.margin = '4px 8px'

    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(deleteButton)

    deleteButton.addEventListener('click', () => {
      if (this.onChangePolygon) {
        this.onChangePolygon(false)
      }
      if (this.onChangeAreaCheck) {
        this.onChangeAreaCheck(false)
      }
      if (this.currentPolygon === null) return
      this.currentPolygon.setMap()
      this.currentPolygon = null
      this.areaText.setMap()
      this.areaText = null
      this.showAreaMarkers()
      this.onChangeShape('')
      if (this.mode === 'volume') {
        void this.createParameters()
        // onRemovePolygon(true)
      }
    })

  }

  async setPin(address: string) {
    const geocoder = new google.maps.Geocoder()
    const results = await geocoder.geocode({ address: address, language: 'ja' })
    const location = results.results[0].geometry.location
    if (location === undefined) { return }
    const lat = location.lat()
    const lng = location.lng()
    // 物件のピンを追加
    const icon = {
      url: '/target_ping.png',
      scaledSize: new google.maps.Size(37, 30),
    }
    this.newProperty && this.newProperty.setMap(null)
    this.newProperty = new google.maps.Marker({
      position: location,
      map: this.map,
      optimized: false,
      draggable: true,
    })

    this.newProperty.setOptions({ zIndex: 99999 })
    this.newProperty.setIcon(icon)
    this.onChangeLatLng({ lat: lat.toString(), lng: lng.toString() })
    google.maps.event.addListener(this.newProperty, 'dragend', () => {
      const lat = this.newProperty.getPosition().lat()
      const lng = this.newProperty.getPosition().lng()
      this.onChangeLatLng({ lat: lat.toString(), lng: lng.toString() })
      this.onChangePolygon(true)
    })
  }

  // TODO: addMarkerにしては責務が重いかもしれない
  /** markerの追加 */
  private addMarker(
    property: {
      id: number
      hashid?: string
      lat: number
      lng: number
      property_type?: string
    },
    map,
    tag_id: number = undefined,
    tag_color: number = undefined,
    showOtherPropertiesClicked,
  ) {
    const marker_params = {
      position: new google.maps.LatLng(Number(property.lat), Number(property.lng)),
      map: map,
      optimized: false,
    }
    if (this.mode == 'property_edit' || this.mode == 'volume') {
      marker_params['draggable'] = property.id === this.property_id
    }
    const marker = new google.maps.Marker(marker_params)

    if (this.mode == 'list') {
      const icon = {
        url: tag_id ? tagIcons[tag_color] : '/flag_icon/no_tag.png',
        scaledSize: new google.maps.Size(tag_id ? 20 : 10, 20),
      }
      marker.setIcon(icon)
    } else {
      let icon
      if (this.property_id == property.id) {
        icon = {
          url: '/target_ping.png',
          scaledSize: new google.maps.Size(37, 30),
        }
        marker.setOptions({ zIndex: 99999 })
      } else if (showOtherPropertiesClicked) {
        icon = {
          url: tag_id ? tagIcons[tag_color] : '/flag_icon/no_tag.png',
          scaledSize: new google.maps.Size(tag_id ? 20 : 10, 20),
        }
      } else {
        marker.setVisible(false)
      }
      marker.setIcon(icon)
    }

    if (this.mode == 'property_edit' || this.mode == 'volume') {
      marker.addListener('dragend', () => {
        const lat = marker.getPosition().lat()
        const lng = marker.getPosition().lng()
        fetch('/api/property_latlng_update', {
          method: 'PATCH',
          body: JSON.stringify({
            id: property.id,
            lat: lat,
            lng: lng,
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': this.csrfToken,
          },
        }).catch((err) => {
          console.error(err)
        })
      })
    }
    // markerにクリックイベント処理追加
    // リスト表示の情報をコピーしてモーダルに表示
    if (
      this.mode === 'list' ||
      ((this.mode === 'property' || this.mode === 'market_datum_report') && this.property_id != property.id)
    ) {
      marker.addListener('click', async () => {
        const columns = [
          'ongoing_proposal_id',
          'tag_name',
          'tag_color',
          'tag_name_color',
          'name',
          'prefecture',
          'city',
          'town',
          'chome',
          'chiban',
          'map_information',
          'property_type',
          'area_m3',
          'effective_area',
          'occupied_area',
          'location_of_division',
          'total_floor_area',
          'purchace_applied_at',
          'purchace_contract_at',
          'suggested_unit_price',
          'suggested_primary_unit_price',
          'purchase_unit_price',
          'purchase_primary_unit_price',
          'business_type_name',
          'coverage_ratio',
          'volume_rate',
          'selling_unit_price',
          'selling_primary_unit_price',
          'rent_per_tsubo',
        ]
        const markerProperty = { ...property, marker }
        let params: any = {}
        if (markerProperty.id === 0) {
          const geocoder = new google.maps.Geocoder()
          const results = await geocoder.geocode({
            location: new google.maps.LatLng(
              Number(markerProperty.lat),
              Number(markerProperty.lng)
            ),
            language: 'ja',
          })
          // a. 東京都港区北青山2-5-8の場合
          // b. 愛知県名古屋市南区宝生町1-43-14の場合
          // c. 丹後公園（愛知県名古屋市）の場合
          const address_components = results.results[0].address_components
          const address = address_components
            .map((c) => {
              return c.long_name
            })
            .reverse()
          const prefecture = address_components.find((c) =>
            c.types.includes('administrative_area_level_1')
          )?.long_name
          // a. 港区 (locality)
          // b. 名古屋市南区 (locality + sublocality_level_1)
          // c. 名古屋市 (locality)
          const city = address_components
            .filter(
              (c) => c.types.includes('locality') || c.types.includes('sublocality_level_1')
            )
            .map((c) => c.long_name)
            .reverse()
            .join('')
          // a. 北青山（sublocality_level_2）
          // b. 宝生町（sublocality_level_2）
          // c. なし
          const town = address_components.find((c) =>
            c.types.includes('sublocality_level_2')
          )?.long_name
          // a. 2-5-8 (sublocality_level_3 + sublocality_level_4 + premise)
          // b. 1-43-14 (sublocality_level_3 + premiseの1つ目)
          // c. なし
          let premise = true
          let chome = address_components
            .reverse()
            .filter((c) => {
              if (
                c.types.includes('sublocality_level_3') ||
                c.types.includes('sublocality_level_4') ||
                (premise && c.types.includes('premise'))
              ) {
                // premiseは最初の1つだけ
                if (c.types.includes('premise')) {
                  premise = false
                }
                return true
              } else {
                return false
              }
            })
            .map((c) => c.long_name)
            .join('-')
            .replace(/[^0-9０-９]+/g, '-')
            .replace(/-$/, '')
          chome = chome.replace(/[０-９]/g, function (c) {
            return String.fromCharCode(c.charCodeAt(0) - 0xfee0)
          })
          params = {
            prefecture,
            city,
            town,
            chome,
            map_information: address[8] || '',
          }
          Object.assign(markerProperty, params)
        } else {
          columns.forEach((column) => {
            let text = markerProperty[column]

            if (text && (column.includes('_unit_price') || column === 'rent_per_tsubo')) {
              text = `${Number(text).toLocaleString()}円`
              markerProperty[column] = text
            }
          })
        }
        const address_params = Object.keys(params)
          .map((p) => {
            return `${p}=${params[p]}`
          })
          .join('&')
        const url =
          markerProperty.id === 0
            ? `/properties/new?${address_params}&lat=${markerProperty.lat}&lng=${markerProperty.lng}&drop_pin=true`
            : `/properties/${markerProperty.hashid}`
        const label = markerProperty.id === 0 ? '新規作成' : '詳細'
        markerProperty['url'] = url
        markerProperty['text'] = label
        this.emitter.emit("setModalProperty", markerProperty)
        this.emitter.emit("setIsModalOpen", true)
      })
    }

    return marker
  }

  private initializePolygon(latlngs, editable, draggable) {
    return new google.maps.Polygon({
      paths: latlngs.map((latlng) => {
        return new google.maps.LatLng(latlng.lat, latlng.lng)
      }),
      draggable: draggable,
      fillColor: '#55ee55',
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: editable,
      editable: editable,
      zIndex: 1,
    })
  }

  /** 敷地を表示 */
  private drawArea(property, map) {
    const polygon = this.initializePolygon(property.shape, false, false)
    polygon.setMap(map)
    this.polygonsHash[property.id] = polygon
    void this.createParameters(polygon)
  }

  private deleteArea() {
    const keysToDelete = []

    Object.keys(this.polygonsHash).forEach((key) => {
      this.polygonsHash[key].setMap(null)
      keysToDelete.push(key)
    })

    keysToDelete.forEach((key) => {
      delete this.polygonsHash[key]
    })
  }

  // 敷地の面積を表示する
  showAreaText(polygon, areaValue = '') {
    const bounds = new google.maps.LatLngBounds()
    let area
    let labelColor = '#691a11'
    if (areaValue) {
      if (!polygon) {
        return
      }
      if (areaValue.endsWith('gmap')) {
        areaValue = areaValue.replace('gmap', '㎡')
        labelColor = '#0000ff'
      }
      const polygonShape = JSON.parse(polygon)
      const parsedShape = polygonShape.map((coord) => {
        return {
          lat: Number(coord.lat),
          lng: Number(coord.lng),
        }
      })
      parsedShape.forEach((latlng) => {
        bounds.extend(latlng)
      })
    } else {
      const roundedPath = []
      polygon?.getPath().forEach((latlng) => {
        bounds.extend(latlng)

        roundedPath.push({
          lat: Math.round(latlng.lat() * 1000000) / 1000000,
          lng: Math.round(latlng.lng() * 1000000) / 1000000,
        })
      })
      area = google.maps.geometry.spherical.computeArea(roundedPath)
    }
    if (this.areaText) {
      this.areaText.setMap()
    }
    this.areaText = new google.maps.Marker({
      position: bounds.getCenter(),
      map: this.map,
      icon: {
        url: '',
        size: new google.maps.Size(1, 1),
      },
      label: {
        text: areaValue || ' ',
        color: labelColor,
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        fontSize: '14px',
      },
    })
  }

  renderProperty() {
    if (this.propertyLat && this.propertyLng) {
      this.putNewProperty(true)
      google.maps.event.addListener(this.newProperty, 'dragend', () => {
        const lat = this.newProperty.getPosition().lat()
        const lng = this.newProperty.getPosition().lng()
        this.onChangeLatLng({ lat: lat.toString(), lng: lng.toString() })
      })
    }
    if (this.propertyShape) {
      const polygon = this.initializePolygon(this.propertyShape, true, true)
      polygon.setMap(this.map)
      this.showAreaMarkers(polygon)
      google.maps.event.addListener(polygon, 'mouseup', () => {
        const shape = []
        polygon.getPath().forEach((latlng) => {
          shape.push({ lat: latlng.lat(), lng: latlng.lng() })
        })
        this.onChangeShape(JSON.stringify(shape))
        this.onChangePolygon(true)
        this.onChangeAreaCheck(false)
        this.showAreaText(polygon)
        this.showAreaMarkers(polygon)
      })
      this.currentPolygon = polygon
      this.showAreaText(this.currentPolygon)
      //this.drawingManager.setDrawingMode(null)
    }
  }

  setupPolygonAndShape() {
    if (this.propertyLat && this.propertyLng) {
      this.putNewProperty(false)
    }
    if (this.propertyShape) {
      const polygon = this.initializePolygon(this.propertyShape, false, false)
      polygon.setMap(this.map)
      this.showAreaMarkers(polygon)
    }
  }
  // polygonの各頂点にピン番号を表示する(nullを与えるとピン番号を隠す)
  private showAreaMarkers(polygon = null) {
    this.areaMarkers.forEach((marker) => {
      marker.setMap()
    })
    this.areaMarkers.length = 0
    if (polygon) {
      let i = 1
      polygon.getPath().forEach((latlng) => {
        this.areaMarkers.push(
          new google.maps.Marker({
            position: latlng,
            map: this.map,
            label: {
              text: `${i++}`,
              color: '#ffffff',
              fontWeight: 'bold',
              fontSize: '14',
            },
            icon: {
              url: '/map_flag.png',
              anchor: new google.maps.Point(2, 30),
              labelOrigin: new google.maps.Point(8, 9),
              scaledSize: new google.maps.Size(15, 30),
            },
            optimized: false,
          })
        )
      })
    }
  }

  private putNewProperty(draggable, position = null) {
    if (this.newProperty) {
      this.newProperty.setMap(null)
    }
    const icon = {
      url: '/target_ping.png',
      scaledSize: new google.maps.Size(37, 30),
    }
    this.newProperty = new google.maps.Marker({
      position: position || new google.maps.LatLng(this.propertyLat, this.propertyLng),
      map: this.map,
      optimized: false,
      draggable: draggable,
    })
    this.newProperty.setOptions({ zIndex: 99999 })
    this.newProperty.setIcon(icon)
  }

  clearProperty() {
    if (this.currentPolygon) {
      this.currentPolygon.setMap(null)
      this.currentPolygon = null
    }
    if (this.areaText) {
      this.areaText.setMap(null)
      this.areaText = null
    }
    this.showAreaMarkers()
    if (this.newProperty) {
      this.newProperty.setMap(null)
      this.newProperty = null
    }
    this.onChangeShape('')
    this.onChangeLatLng({ lat: '', lng: '' })
  }

  // ボリュームチェックパラメーター入力用DOMの生成
  // TODO: 未実装。ここはMarkerのclassなので別classに逃がしたい。
  private async createParameters(polygon = null) {
    // if (this.mode != 'volume') return
    // const refVolume = new URLSearchParams(window.location.search).has('ref')

    // if (polygon) {
    //   const res = await fetch(`/properties/${this.property_id}/volume_check_requests/property_json`)
    //   const property = await res.json()
    //   if (onChangeParameters) {
    //     onChangeParameters(property)
    //   }

    //   // DOMの描画完了を待つ
    //   await new Promise((resolve) => setTimeout(resolve))

    //   let params = JSON.parse(
    //     (document.getElementById('volume_check_request_parameters') as HTMLInputElement)
    //       .value || '{ "borders": [], "surroundings": [], "building_setting": {} }'
    //   )

    //   if (refVolume && this.initialLoad) {
    //     params = JSON.parse(ref_volume_check_parameters)
    //     this.initialLoad = false
    //   }

    //   const path = []
    //   polygon.getPath().forEach((latlng) => {
    //     path.push(latlng)
    //   })
    //   let next_i = 0
    //   for (let i = 0; i < path.length; i++) {
    //     if (i == path.length - 1) {
    //       next_i = 0
    //     } else {
    //       next_i = i + 1
    //     }
    //     const distance = google.maps.geometry.spherical.computeDistanceBetween(
    //       path[i],
    //       path[next_i]
    //     )
    //       ; (document.getElementById(`length_${i}`) as HTMLInputElement).value = `${Math.round(distance * 100) / 100
    //         }m`
    //       ; (document.getElementById(`border_${i}`) as HTMLInputElement).value =
    //         (i in params.borders && params.borders[i].border_type_code) || '4'
    //       ; (document.getElementById(`width_${i}`) as HTMLInputElement).value =
    //         (i in params.borders && params.borders[i].width) || ''
    //       ; (document.getElementById(`setback_${i}`) as HTMLInputElement).value =
    //         (i in params.borders && params.borders[i].set_back_method) || '0'
    //       ; (document.getElementById(`height_${i}`) as HTMLInputElement).value =
    //         (i in params.borders && params.borders[i].from.height) || '0'
    //       ; (document.getElementById(`surroundings_${i}`) as HTMLInputElement).value =
    //         (i in params.surroundings && params.surroundings[i].border_type_code) || '4'
    //       ; (document.getElementById(`surroundings_${i}_width`) as HTMLInputElement).value =
    //         (i in params.surroundings && params.surroundings[i].width) || ''
    //   }
    // } else {
    //   if (onChangeParameters) {
    //     onChangeParameters(null)
    //   }
    //   ; (document.getElementById('volume_check_request_parameters') as HTMLInputElement).value =
    //     ''
    // }
    // parametersChanged()
  }

  // MARK: State
  private onChangeShape(v: string) { this.emitter.emit("onChangeShape", v) }
  private onChangePolygon(v: boolean) { this.emitter.emit("onChangePolygon", v) }
  private onChangeAreaCheck(v: boolean) { this.emitter.emit("onChangeAreaCheck", v) }
  private onChangeLatLng(v: { lat: string, lng: string }) { this.emitter.emit("onChangeLatLng", v) }
}
