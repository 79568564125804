import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import { CustomizeTable } from '@/components/Table/CustomizeTable'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { LoadSpinner } from '@/components/Loading/LoadSpinner'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'
import { Introduction } from '@/components/Layout/Introduction'
import { User } from '@/types/user'
import type { Company } from '@/types/company'
import { Department } from '@/types/department'
import { Breadcrumb } from '@/components/Breadcrumb'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

type Props = {
  current_user: User
  department: Department
  company: Company
  authenticity_token: string
  error_messages: string[]
  display_order_setting_master_hash: { [key: string]: string }
  company_display_order_setting_master_hash: { [key: string]: string }
  company_hidden_send_columns: string[]
  company_not_display_columns: any[]
  department_display_order_setting_master_hash: { [key: string]: string }
  department_hidden_send_columns: string[]
  department_not_display_columns: any[]
  departments: string[]
}

const DepartmentDisplayOrderSetting: React.FC<Props> = ({
  current_user,
  department,
  company,
  authenticity_token,
  error_messages,
  display_order_setting_master_hash,
  company_display_order_setting_master_hash,
  company_not_display_columns,
  department_hidden_send_columns,
  department_display_order_setting_master_hash,
  department_not_display_columns,
  departments,
}) => {
  const title = '入手情報カラム順設定'
  const action = company.id ? 'patch' : 'post'
  const introduction_message = [
    '◾️ 入手情報一覧画面のカラムの順番を変更できます。',
    '◾️ 右矢印をクリックすると右にカラムが移動し、左矢印をクリックすると左に移動します。',
    '◾️ PC幅の場合、No.と所在地(住居表示)の位置は固定です。',
    '◾️ 目のアイコンをクリックすると表示/非表示の切り替えができます。',
    '◾️ 他部署のカラム設定をコピーして編集できます。( ※ 保存せず、画面をリロードするとページを開いた時の状態に戻るため、ご注意してください。)',
  ]
  const column_labels = {
    id: 'No.',
    location: '所在地（住居表示）',
    name: '名前',
  }
  const head_sticky_styles = {
    0: 'md:sticky md:left-0 px-12 z-10',
    1: 'md:sticky md:left-[80px] px-12 z-10',
  }
  const body_sticky_styles = {
    0: 'md:sticky left-0 z-10',
    1: 'md:sticky left-[80px] z-10',
  }
  const [selectedDepartment, setSelectedDepartment] = React.useState('未設定')
  const [useColumn, setUseColumn] = React.useState(
    Object.keys(department_display_order_setting_master_hash)
  )
  const [isDummyValue, setDummyValue] = React.useState(
    Object.values(department_display_order_setting_master_hash)
  )
  const [isDepartmentHiddenSendColumns, setDepartmentHiddenSendColumns] = React.useState(
    department_hidden_send_columns
  )
  const [isDepartmentNotDisplayColumns, setDepartmentNotDisplayColumns] = React.useState(
    department_not_display_columns
  )
  const [isLoading, setIsLoading] = React.useState(false)
  const handleChangeSelect = (e) => {
    const value = e.target.value
    if (value !== '') {
      setSelectedDepartment(value || '未設定')
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      setIsLoading(true)
      fetch('/departments/department_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken, // CSRF トークンをヘッダーに追加
        },
        body: JSON.stringify({
          request_department_name: value,
          company_id: company.id,
          department_id: department.id,
        }),
      })
        .then((response) => {
          if (!response.ok) throw new Error('接続に失敗しました')
          return response.json()
        })
        .then((data) => {
          console.log('Success:', data)
          setDepartmentHiddenSendColumns(data.copy_department.copy_department_hidden_send_columns)
          setUseColumn(
            Object.keys(data.copy_department.copy_department_display_order_setting_master_hash)
          )
          setDummyValue(
            Object.values(data.copy_department.copy_department_display_order_setting_master_hash)
          )
          setDepartmentNotDisplayColumns(data.copy_department.copy_department_not_display_columns)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      console.log('未設定')
    }
  }
  const dummy_tag = ['タグ未設定', 'タグ１', 'タグ２', 'タグ３', 'タグ4', 'タグ5']
  const swapItems = (index: number, direction: number) => {
    const newColumns = [...useColumn]
    const newValues = [...isDummyValue]
    const sendColumns = [...isDepartmentHiddenSendColumns]
    const departmentNotDisplayColumns = [...isDepartmentNotDisplayColumns]

    ;[newColumns[index], newColumns[index + direction]] = [
      newColumns[index + direction],
      newColumns[index],
    ]
    ;[newValues[index], newValues[index + direction]] = [
      newValues[index + direction],
      newValues[index],
    ]
    ;[sendColumns[index], sendColumns[index + direction]] = [
      sendColumns[index + direction],
      sendColumns[index],
    ]
    ;[departmentNotDisplayColumns[index], departmentNotDisplayColumns[index + direction]] = [
      departmentNotDisplayColumns[index + direction],
      departmentNotDisplayColumns[index],
    ]

    setUseColumn(newColumns)
    setDummyValue(newValues)
    setDepartmentHiddenSendColumns(sendColumns)
    setDepartmentNotDisplayColumns(departmentNotDisplayColumns)
  }

  const moveLeft = (index: number) => {
    if (index > 2) {
      swapItems(index, -1)
    }
  }

  const moveRight = (index: number) => {
    if (index < useColumn.length - 1) {
      swapItems(index, 1)
    }
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['入手情報カラム順設定']} />}
      submenuCategory="settings"
    >
      <ul className={['mb-6 text-sm text-red'].join(' ')}>
        {error_messages.map((message, i) => (
          <li key={i} className="mb-2 break-all">
            {message}
          </li>
        ))}
      </ul>
      <form
        className="mt-8"
        encType="multipart/form-data"
        action={`/departments/${department.id}/department_display_order_setting_update`}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={action} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        <input
          type="hidden"
          name="is_department_hidden_send_columns"
          value={JSON.stringify(isDepartmentHiddenSendColumns)}
        />
        <input
          type="hidden"
          name="is_department_not_display_columns"
          value={JSON.stringify(isDepartmentNotDisplayColumns)}
        />
        <input type="hidden" name="is_company_id" value={JSON.stringify(company.id)} />
        <p className="my-4">※ {company.name}様の設定</p>
        <CustomizeTable
          use_view_name={'department_display_order_setting'}
          display_keys={Object.keys(display_order_setting_master_hash)}
          display_values={Object.values(display_order_setting_master_hash)}
          not_display_columns={company_not_display_columns}
          dummy_tag={dummy_tag}
          column_labels={column_labels}
          head_sticky_styles={head_sticky_styles}
          body_sticky_styles={body_sticky_styles}
        />
        <hr className="mt-12" />
        <p className="my-6">※ {department.name}の設定</p>
        <Introduction introduction_message={introduction_message} details_style={'my-4'} />
        <div className="mb-2">
          <select
            id="department-select"
            className={[
              `mb-2 empty:hidden inline-flex text-sm ml-2 px-3 py-[4px] rounded-full border-[1px] border-[#EEE] border-transparent text-black`,
            ].join(' ')}
            value={selectedDepartment}
            onChange={handleChangeSelect}
          >
            <option value="">部署のコピー</option>
            {departments.map((department, index) => (
              <option key={index} value={department}>
                {department}
              </option>
            ))}
          </select>
          <p>選択した部署: {selectedDepartment}</p>
        </div>
        <LoadingOverlay isOpen={isLoading} text="反映中" />
        <CustomizeTable
          use_view_name={'company_display_order_setting'}
          display_keys={useColumn}
          display_values={isDummyValue}
          dummy_tag={dummy_tag}
          not_display_columns={isDepartmentNotDisplayColumns}
          setNotDisplayColumns={setDepartmentNotDisplayColumns}
          moveLeft={moveLeft}
          moveRight={moveRight}
          custom_visibility_flg={true}
          custom_order_flg={true}
          column_labels={column_labels}
          head_sticky_styles={head_sticky_styles}
          body_sticky_styles={body_sticky_styles}
        />

        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2 ms:mt-5 md:mt-16">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white mt-6"
            outline
            variant="text"
            href={`/departments/${department.id}/department_display_order_setting`}
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto mt-6">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default DepartmentDisplayOrderSetting
