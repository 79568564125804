// InfomationIcon.tsx
import * as React from 'react'
import type { Props } from './types'

const InfomationIcon: React.FC<Props> = ({ size = 16, fill = '4B7AAA', className = '' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={size}
      height={size}
      className={`opacity-70 hover:opacity-100 transition-opacity duration-200 ${className}`}
    >
      <circle cx="50" cy="50" r="50" fill={`#${fill}`} />
      <text
        x="50"
        y="78"
        fontFamily="Georgia, serif"
        fontSize="85"
        fontStyle="italic"
        fontWeight="900"
        fill="white"
        textAnchor="middle"
      >
        i
      </text>
    </svg>
  )
}

export default InfomationIcon
