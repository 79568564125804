import * as React from 'react'
import { Row, Th, Td } from '@/components/Table'
import { UserContext } from '@/components/Layout/Base'
import { Input } from '@/components/Input'

type Props = {
  property: any
  plan_number: string
  onChangeParameters?: () => void
  ref_volume_check_parameters?: any
}

const FundamentalsForm3: React.FC<Props> = ({
  property,
  plan_number,
  onChangeParameters,
  ref_volume_check_parameters,
}) => {
  const address = property.city + property.town + property.chome
  const projectName = property.name || address
  const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null
  const ref_project_name = parameters ? parameters.project_name : null
  const ref_plan_name = parameters ? parameters?.plan_setting?.plan_name : null
  const ref_plan_number = parameters ? parameters.plan_number : null
  const ref_manager_name = parameters ? parameters.manager_name : null
  const ref_building_use = parameters ? parameters.building_use : null
  const ref_building_structure = parameters ? parameters.building_structure : null
  const ref_unit_area = parameters ? parameters?.unit_setting?.unit_area : null
  const { user } = React.useContext(UserContext)

  const [fundamentals, setFundamentals] = React.useState({
    project_name: ref_project_name ? ref_project_name : projectName,
    plan_name: ref_plan_name ? ref_plan_name : '',
    plan_number: ref_plan_number ? ref_plan_number : plan_number,
    manager_name: user.name,
    building_use: ref_building_use ? ref_building_use : '共同住宅',
    building_structure: ref_building_structure ? ref_building_structure : 'RC',
    unit_area: ref_unit_area ? ref_unit_area : '25',
  })

  return (
    <>
      <Row label={<Th>案件情報</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              id="project_name"
              name="project_name"
              className="md:w-[300px] w-full"
              prefix="案件名"
              value={fundamentals.project_name}
              placeholder={`例）${projectName}（5階建)`}
              onChange={(e) => {
                setFundamentals({ ...fundamentals, project_name: e.target.value })
                onChangeParameters()
              }}
            />
            <Input
              id="manager_name"
              name="manager_name"
              className="md:w-[300px] w-full"
              prefix="作成者名"
              value={fundamentals.manager_name}
              onChange={(e) => {
                setFundamentals({ ...fundamentals, manager_name: e.target.value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>プラン情報</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              id="plan_name"
              name="plan_name"
              className="md:w-[300px] w-full"
              prefix="建築プラン名"
              value={fundamentals.plan_name}
              placeholder="例）5階建"
              onChange={(e) => {
                setFundamentals({ ...fundamentals, plan_name: e.target.value })
                onChangeParameters()
              }}
            />
            <Input
              id="plan_number"
              name="plan_number"
              className="md:w-[300px] w-full"
              prefix="プランNo."
              value={fundamentals.plan_number}
              onChange={(e) => {
                setFundamentals({ ...fundamentals, plan_number: e.target.value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>建築情報</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              id="building_use"
              name="building_use"
              className="md:w-[300px] w-full"
              prefix="建築用途"
              value={fundamentals.building_use}
              readOnly={true}
              onChange={(e) => {
                setFundamentals({ ...fundamentals, building_use: e.target.value })
                onChangeParameters()
              }}
            />
            <Input
              id="building_structure"
              name="building_structure"
              className="md:w-[300px] w-full"
              prefix="建築構造"
              value={fundamentals.building_structure}
              readOnly={true}
              onChange={(e) => {
                setFundamentals({ ...fundamentals, building_structure: e.target.value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>希望住戸面積</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              id="unit_area"
              name="unit_area"
              className="md:w-[300px] w-full"
              suffix="㎡"
              value={fundamentals.unit_area}
              placeholder="18〜40に入力してください"
              onChange={(e) => {
                setFundamentals({ ...fundamentals, unit_area: e.target.value })
                onChangeParameters()
              }}
              onBlur={(e) => {
                let value = parseFloat(e.target.value)
                if (isNaN(value) || value < 18) value = 18
                if (value > 40) value = 40
                setFundamentals({ ...fundamentals, unit_area: value.toString() })
              }}
            />
          </div>
        </Td>
      </Row>
    </>
  )
}

export default FundamentalsForm3
