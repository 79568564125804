// LoadingSpinnerIcon.tsx
import * as React from 'react'
import type { Props } from './types'

const LoadingSpinnerIcon: React.FC<Props> = ({
  size = 100,
  color = '#4B7AAA',
  text = '出力中',
  className = '',
}: Props) => {
  const units = Array.from({ length: 12 })
  const radius = size * 0.35

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-25 -25 150 150"
      width={size}
      height={size}
      className={`inline-block opacity-70 hover:opacity-100 transition-opacity duration-200 ${className}`}
    >
      {units.map((_, i) => {
        const angle = (i * 30 * Math.PI) / 180
        const x = 50 + radius * Math.cos(angle)
        const y = 50 + radius * Math.sin(angle)

        return (
          <g key={i} transform={`translate(${x},${y}) rotate(${i * 30 + 90})`}>
            <path
              d="m-10-20L-5 15 5 15 10-20z"
              fill={color}
              stroke={color}
              strokeWidth="0.5"
              strokeLinecap="butt"
              opacity={1}
            >
              <animate
                attributeName="opacity"
                values="1;0.2"
                dur="1s"
                repeatCount="indefinite"
                begin={`${i * (1 / 12)}s`}
              />
            </path>
          </g>
        )
      })}

      <text
        x="50"
        y="54"
        fontSize="14"
        fontFamily="sans-serif"
        fill={color}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {text}
      </text>
    </svg>
  )
}

export default LoadingSpinnerIcon
