import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { DropDown, Option } from '@/components/DropDown/Index'
import {
  intFormat,
  cammedFormat,
  dataPrecisionSetup,
  searchYeildFormat,
} from '@/utils/cammedFormat'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'

type Props = {
  department_options: { value: string; label: string }[]
  user_options: { value: string; label: string }[]
  tag_options: { value: string; text: string }[]
  business_type_options: { value: string; text: string }[]
  prefecture_options: { value: string | number; label: string }[]
  title: string
  showMap: boolean
  target_prefecture: string
  target_city: string
  target_town: string
  target_chome: string
  target_map_information: string
  target_map_position_data: string
}

type TownJson = {
  town: string
  town_kana: string
}[]

export const PropertiesFilterModal: React.FC<Props> = ({
  title,
  department_options,
  user_options,
  tag_options,
  business_type_options,
  prefecture_options,
  showMap,
  target_prefecture,
  target_city,
  target_town,
  target_chome,
  target_map_information,
  target_map_position_data,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [city_options, setCityOptions] = React.useState([])
  const [town_options, setTownOptions] = React.useState([])

  const [proposalSearchParams, setProposalSearchParams] = React.useState({
    propertyPrefecture: { value: '', label: '' },
    propertyCity: { value: '', label: '' },
    propertyTown: { value: '', label: '' },
    propertyChome: '',
    propertyMapInformation: '',
    sourceUserId: null,
    propertyUserId: null,
    sourceDepartmentName: null,
    propertyDepartmentName: null,
    createdAtGteq: '',
    createdAtLteq: '',
    propertyPurchaceAppliedAtGteq: '',
    propertyPurchaceAppliedAtLteq: '',
    propertyPurchaceContractAtGteq: '',
    propertyPurchaceContractAtLteq: '',
    propertyPurchaceSettlementAtGteq: '',
    propertyPurchaceSettlementAtLteq: '',
    proposalOrderIn: '',
    proposalOrderGteq: '',
    proposalOrderLteq: '',
    ongoingEq: '',
    propertyTagNameEq: '',
    propertyTypeEq: '',
    proposalsSuggestedTotalPriceGteq: '',
    proposalsSuggestedTotalPriceLteq: '',
    propertyCurrentProspectiveYieldGteq: '',
    propertyCurrentProspectiveYieldLteq: '',
    propertyAreaM3Gteq: '',
    propertyAreaM3Lteq: '',
    propertyOccupiedAreaGteq: '',
    propertyOccupiedAreaLteq: '',
    propertyBusinessTypeEq: '',
    sourceCont: '',
    byFreeword: '',
  })

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const prof = searchParams.get('q[map_prefecture_eq]') || ''
    const city = searchParams.get('q[map_city_eq]') || ''
    const town = searchParams.get('q[map_town_eq]') || ''

    const sourceUserId = searchParams.getAll('q[source_user_id_in][]').filter((v) => v !== '')

    const propertyUserId = searchParams.getAll('q[property_user_id_in][]').filter((v) => v !== '')

    const sourceDepartmentName = searchParams
        .getAll('q[source_user_department_name_in][]')
        .filter((v) => v !== '')

    const propertyDepartmentName = searchParams
        .getAll('q[property_user_department_name_in][]')
        .filter((v) => v !== '')

    getCity(prefecture_options[prof]?.label ?? '')
    getTown(prefecture_options[prof]?.label ?? '', city)

    const processParamValue = (paramValue: string[] | null) => {
      if (!paramValue || paramValue.length === 0) {
        return []
      } else {
        return paramValue
      }
    }
    const propertyTagNameEq = processParamValue(searchParams.getAll('q[property_tag_name_in][]'))
    const propertyBusinessTypeEq = processParamValue(
        searchParams.getAll('q[property_business_type_name_in][]')
    )

    const searchAddressListParam = searchParams.get('searchAddressList')
    const initialSearchAddressList = searchAddressListParam
        ? JSON.parse(searchAddressListParam)
        : []
    setAddressList(initialSearchAddressList)

    setProposalSearchParams({
      propertyPrefecture: prefecture_options[prof] || { value: '', label: '' },
      propertyCity: { value: city, label: city },
      propertyTown: { value: town, label: town },
      propertyChome: searchParams.get('q[property_chome_cont]') || '',
      propertyMapInformation: searchParams.get('q[property_map_information_cont]') || '',
      sourceUserId: sourceUserId,
      propertyUserId: propertyUserId,
      sourceDepartmentName: sourceDepartmentName,
      propertyDepartmentName: propertyDepartmentName,
      createdAtGteq: searchParams.get('q[sourced_at_gteq]') || '',
      createdAtLteq: searchParams.get('q[sourced_at_lteq]') || '',
      propertyPurchaceAppliedAtGteq: searchParams.get('q[property_purchace_applied_at_gteq]') || '',
      propertyPurchaceAppliedAtLteq: searchParams.get('q[property_purchace_applied_at_lteq]') || '',
      propertyPurchaceContractAtGteq:
        searchParams.get('q[property_purchace_contract_at_gteq]') || '',
      propertyPurchaceContractAtLteq:
        searchParams.get('q[property_purchace_contract_at_lteq]') || '',
      propertyPurchaceSettlementAtGteq:
          searchParams.get('q[property_purchace_settlement_at_gteq]') || '',
      propertyPurchaceSettlementAtLteq:
          searchParams.get('q[property_purchace_settlement_at_lteq]') || '',
      proposalOrderIn: searchParams.get('q[proposal_order_in]') || '',
      proposalOrderGteq: searchParams.get('q[proposal_order_gteq]') || '',
      proposalOrderLteq: searchParams.get('q[proposal_order_lteq]') || '',
      ongoingEq: searchParams.getAll('q[ongoing_in][]') || [],
      propertyTagNameEq: propertyTagNameEq,
      propertyTypeEq: searchParams.getAll('q[property_property_type_in][]') || [],
      proposalsSuggestedTotalPriceGteq: searchParams.get('q[suggested_total_price_gteq]') || '',
      proposalsSuggestedTotalPriceLteq: searchParams.get('q[suggested_total_price_lteq]')|| '',
      propertyCurrentProspectiveYieldGteq: searchYeildFormat(searchParams.get('q[current_prospective_yield_gteq]')) || '',
      propertyCurrentProspectiveYieldLteq: searchYeildFormat(searchParams.get('q[current_prospective_yield_lteq]')) || '',
      propertyAreaM3Gteq: searchParams.get('q[property_area_m3_gteq]') || '',
      propertyAreaM3Lteq: searchParams.get('q[property_area_m3_lteq]') || '',
      propertyOccupiedAreaGteq: searchParams.get('q[property_occupied_area_gteq]') || '',
      propertyOccupiedAreaLteq: searchParams.get('q[property_occupied_area_lteq]') || '',
      propertyBusinessTypeEq: propertyBusinessTypeEq,
      sourceCont: searchParams.get('q[source_cont]') || '',
      byFreeword: searchParams.get('q[property_by_freeword_from_proerties]') || '',
    })
    if (prefecture_options[prof]?.label) {
      getCity(prefecture_options[prof]?.label)
    }
    if (prefecture_options[prof]?.label && city) {
      getTown(prefecture_options[prof]?.label, city)
    }
  }, [])

  function getCity(prefecture: string) {
    if (prefecture === '') {
      setProposalSearchParams({
        ...proposalSearchParams,
        propertyCity: { value: '', label: '' },
        propertyTown: { value: '', label: '' },
      })
      setCityOptions([])
      setTownOptions([])
      return
    }

    if (prefecture != '---' && prefecture != '') {
      fetch('/japanese-addresses/ja.json')
        .then((response) => response.json())
        .then((json) => {
          const citys: string[] = json[prefecture]
          const city_options = citys.map((city: string) => {
            const option = { value: city, label: city }
            return option
          })
          setCityOptions(city_options)
        })
        .catch((e) => {
          console.error(e)
          alert('市区町村データの取得に失敗しました。')
        })
    } else {
      setCityOptions([])
      setTownOptions([])
    }
  }

  function getTown(prefecture: string, city: string) {
    if (prefecture !== '' && city !== '') {
      fetch(`/japanese-addresses/ja/${prefecture}/${city}.json`)
        .then((response) => response.json())
        .then((json: TownJson) => {
          const town_options = json.map((town) => {
            const option = { value: town['town'], label: town['town'] }
            return option
          })
          setTownOptions(town_options)
        })
        .catch(() => {
          alert('町名データの取得に失敗しました。')
        })
    } else {
      setTownOptions([])
    }
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function onChangePref(value: Option | null) {
    setCityOptions([])
    setTownOptions([])
    if (value === null) {
      setProposalSearchParams({
        ...proposalSearchParams,
        propertyPrefecture: {
          value: '',
          label: '',
        },
        propertyCity: {
          value: '',
          label: '',
        },
        propertyTown: {
          value: '',
          label: '',
        },
        propertyChome: '',
        propertyMapInformation: '',
      })
    } else {
      setProposalSearchParams({
        ...proposalSearchParams,
        propertyPrefecture: {
          value: value.value.toString(),
          label: value.label,
        },
      })
      getCity(value && value.label)
    }
  }

  function onChangeCity(value: Option | null) {
    setTownOptions([])

    if (value === null) {
      setProposalSearchParams({
        ...proposalSearchParams,
        propertyCity: {
          value: '',
          label: '',
        },
        propertyTown: {
          value: '',
          label: '',
        },
        propertyChome: '',
        propertyMapInformation: '',
      })
    } else {
      setProposalSearchParams({
        ...proposalSearchParams,
        propertyCity: {
          value: value.value.toString(),
          label: value.label,
        },
      })
      getTown(proposalSearchParams.propertyPrefecture.label, value.value.toString())
    }
  }

  function onChangeTown(value: Option | null) {
    setProposalSearchParams({
      ...proposalSearchParams,
      propertyTown: {
        value: value ? value.value.toString() : '',
        label: value ? value.label : '',
      },
    })
  }

  const [searchAddressList, setAddressList] = React.useState([])
  const addSearchCondition = () => {
    const newCondition = {
      prefecture: proposalSearchParams.propertyPrefecture.label,
      city: proposalSearchParams.propertyCity.label,
      town: proposalSearchParams.propertyTown.label,
      chome: proposalSearchParams.propertyChome,
      mapInfomation: proposalSearchParams.propertyMapInformation,
    }

    const isEmptyCondition = Object.values(newCondition).every((value) => !value)
    if (isEmptyCondition) {
      return
    }

    const updatedSearchAddressList = [...searchAddressList, newCondition]
    setAddressList(updatedSearchAddressList)
    setProposalSearchParams((prevState) => ({
      ...prevState,
      propertyPrefecture: { value: '', label: '' },
      propertyCity: { value: '', label: '' },
      propertyTown: { value: '', label: '' },
      propertyChome: '',
      propertyMapInformation: '',
    }))
    onChangePref(null)
  }

  const removeSearchCondition = (index) => {
    const updatedList = searchAddressList.filter((_, i) => i !== index)
    setAddressList(updatedList)
  }

  return (
    <>
      <div className="">
        <button type="button" onClick={openModal} className={'mx-3'}>
          <AnchorButton
            className="text-primary-font"
            prefix={<SearchIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
          >
            <span className="hidden md:inline">絞り込み</span>
          </AnchorButton>
        </button>
      </div>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    {title}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeModal} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>

                  <form
                    id="properties_search"
                    action="/properties"
                    acceptCharset="UTF-8"
                    method="get"
                    className='pb-[75px]'
                  >
                    <div className="mt-2">
                      <div className="p-4 h-[80vh] overflow-y-scroll">
                        <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                          所在地
                        </div>
                        <div className="flex items-center">
                          <div
                              className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            都道府県
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                                className="text-sm"
                                name="q[map_prefecture_eq]"
                                id="q_map_prefecture_eq"
                                options={prefecture_options}
                                placeholder="---"
                                value={
                                    proposalSearchParams.propertyPrefecture?.label !== '' &&
                                    proposalSearchParams.propertyPrefecture
                                }
                                onChange={onChangePref}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div
                              className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            市区町村
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                                className="text-sm"
                                name="q[map_city_eq]"
                                id="q_map_city_eq"
                                hasBlank
                                blankLabel="---"
                                options={city_options}
                                value={
                                    proposalSearchParams.propertyCity.label !== '' &&
                                    proposalSearchParams.propertyCity
                                }
                                onChange={onChangeCity}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div
                              className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            町名
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                                className="text-sm"
                                name="q[map_town_eq]"
                                id="q_map_town_eq"
                                hasBlank
                                blankLabel="---"
                                options={town_options}
                                value={
                                    proposalSearchParams.propertyTown.label !== '' &&
                                    proposalSearchParams.propertyTown
                                }
                                onChange={onChangeTown}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div
                              className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            丁目以降（住居表示）
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <div className="flex">
                              <Input
                                  value={proposalSearchParams.propertyChome}
                                  name="q[property_chome_cont]"
                                  id="q_property_chome_cont"
                                  className={'w-full'}
                                  onChange={(e) =>
                                      setProposalSearchParams({
                                        ...proposalSearchParams,
                                        propertyChome: e.target.value,
                                      })
                                  }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div
                              className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            建物名
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <div className="flex">
                              <Input
                                  value={proposalSearchParams.propertyMapInformation}
                                  name="q[property_map_information_cont]"
                                  id="q_property_map_information_cont"
                                  className={'w-full'}
                                  onChange={(e) =>
                                      setProposalSearchParams({
                                        ...proposalSearchParams,
                                        propertyMapInformation: e.target.value,
                                      })
                                  }
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                            className="w-[100px] mb-2 self-center"
                            size="tiny"
                            type="button"
                            onClick={addSearchCondition}
                        >
                          所在地追加
                        </Button>
                        <div>
                          {searchAddressList.map((condition, index) => (
                              <div className="flex items-center justify-between" key={index}>
                                <div
                                    className="text-sm py-2 px-4 text-right whitespace-normal text-[#888] font-medium w-1/4">
                                  所在地条件{index + 1}
                                </div>
                                <div className="flex justify-between items-center py-2 px-4 w-3/4">
                                <span className="text-sm text-[#888]">
                                  {condition.prefecture} {condition.city} {condition.town}{' '}
                                  {condition.chome} {condition.mapInfomation}
                                </span>
                                  <button
                                      type="button"
                                      className="align-middle text-[#777]"
                                      onClick={() => removeSearchCondition(index)}
                                  >
                                    <DeleteOutlined fontSize="small"/>
                                  </button>
                                </div>
                              </div>
                          ))}
                        </div>
                        <input
                            type="hidden"
                            name="searchAddressList"
                            value={JSON.stringify(searchAddressList)}
                        />

                        <input type="hidden" name="q[prefecture_eq]" value={target_prefecture} />
                        <input type="hidden" name="q[city_eq]" value={target_city} />
                        <input type="hidden" name="q[town_eq]" value={target_town} />
                        <input type="hidden" name="q[chome_start]" value={target_chome} />
                        <input
                          type="hidden"
                          name="q[map_information_cont]"
                          value={target_map_information}
                        />
                        <input
                          type="hidden"
                          name="q[map_position_data_cont]"
                          value={target_map_position_data}
                        />
                        <div>
                          <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                            条件
                          </div>
                          <div
                              className="hidden md:block md:text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
                            ※
                            複数選択項目では、Ctrlキーを押しながらクリックすることで、選択解除や複数選択が可能です。
                          </div>

                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              情報入手者
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                  multiple
                                  className="text-sm bg-none"
                                  name="q[source_user_id_in][]"
                                  id="q_source_user_id_in"
                                  options={user_options}
                                  value={proposalSearchParams.sourceUserId}
                                  onChange={(e) => {
                                    const values = Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value
                                    )
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      sourceUserId: values,
                                    })
                                  }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              案件担当者
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                  multiple
                                  className="text-sm bg-none"
                                  name="q[property_user_id_in][]"
                                  id="q_property_user_id_in"
                                  options={user_options}
                                  value={proposalSearchParams.propertyUserId}
                                  onChange={(e) => {
                                    const values = Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value
                                    )
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyUserId: values,
                                    })
                                  }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              情報入手部署
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                  multiple
                                  className="text-sm bg-none"
                                  name="q[source_user_department_name_in][]"
                                  id="q_source_user_department_name_in"
                                  options={department_options}
                                  value={proposalSearchParams.sourceDepartmentName}
                                  onChange={(e) => {
                                    const values = Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value
                                    )
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      sourceDepartmentName: values,
                                    })
                                  }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              案件担当部署
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                  multiple
                                  className="text-sm bg-none"
                                  name="q[property_user_department_name_in][]"
                                  id="q_property_user_department_name_in"
                                  options={department_options}
                                  value={proposalSearchParams.propertyDepartmentName}
                                  onChange={(e) => {
                                    const values = Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value
                                    )
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyDepartmentName: values,
                                    })
                                  }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              入手日
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.createdAtGteq}
                                    type="date"
                                    name="q[sourced_at_gteq]"
                                    id="q_sourced_at_gteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          createdAtGteq: e.target.value,
                                        })
                                    }
                                    maxCalendarYear={3}
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.createdAtLteq}
                                    type="date"
                                    name="q[sourced_at_lteq]"
                                    id="q_sourced_at_lteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          createdAtLteq: e.target.value,
                                        })
                                    }
                                    maxCalendarYear={3}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              買付提出日
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.propertyPurchaceAppliedAtGteq}
                                    type="date"
                                    name="q[property_purchace_applied_at_gteq]"
                                    id="q_property_purchace_applied_at_gteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyPurchaceAppliedAtGteq: e.target.value,
                                        })
                                    }
                                    maxCalendarYear={3}
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.propertyPurchaceAppliedAtLteq}
                                    type="date"
                                    name="q[property_purchace_applied_at_lteq]"
                                    id="q_property_purchace_applied_at_lteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyPurchaceAppliedAtLteq: e.target.value,
                                        })
                                    }
                                    maxCalendarYear={3}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              仕入契約日
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.propertyPurchaceContractAtGteq}
                                    type="date"
                                    name="q[property_purchace_contract_at_gteq]"
                                    id="q_property_purchace_contract_at_gteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyPurchaceContractAtGteq: e.target.value,
                                        })
                                    }
                                    maxCalendarYear={3}
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.propertyPurchaceContractAtLteq}
                                    type="date"
                                    name="q[property_purchace_contract_at_lteq]"
                                    id="q_property_purchace_contract_at_lteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyPurchaceContractAtLteq: e.target.value,
                                        })
                                    }
                                    maxCalendarYear={3}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              仕入決済日
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.propertyPurchaceSettlementAtGteq}
                                    type="date"
                                    name="q[property_purchace_settlement_at_gteq]"
                                    id="q_property_purchace_settlement_at_gteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyPurchaceSettlementAtGteq: e.target.value,
                                        })
                                    }
                                    maxCalendarYear={3}
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.propertyPurchaceSettlementAtLteq}
                                    type="date"
                                    name="q[property_purchace_settlement_at_lteq]"
                                    id="q_property_purchace_settlement_at_lteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyPurchaceSettlementAtLteq: e.target.value,
                                        })
                                    }
                                    maxCalendarYear={3}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              ステータス
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                  multiple
                                  className="text-sm bg-none h-[60px]"
                                  name="q[ongoing_in][]"
                                  id="q_ongoing_in"
                                  options={[
                                    {
                                      text: '検討中',
                                      value: '検討中',
                                    },
                                    {
                                      text: '検討終了',
                                      value: '検討終了',
                                    },
                                  ]}
                                  value={proposalSearchParams.ongoingEq}
                                  onChange={(e) => {
                                    const values = Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value
                                    )
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      ongoingEq: values,
                                    })
                                  }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              タグ
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                  multiple
                                  className="text-sm bg-none"
                                  name="q[property_tag_name_in][]"
                                  id="q_property_tag_name_in"
                                  options={tag_options}
                                  value={proposalSearchParams.propertyTagNameEq}
                                  onChange={(e) => {
                                    const values = Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value
                                    )
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyTagNameEq: values,
                                    })
                                  }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              物件種目
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <Select
                                  multiple
                                  className="text-sm bg-none h-[80px]"
                                  name="q[property_property_type_in][]"
                                  id="q_property_property_type_in"
                                  options={[
                                    {
                                      text: '土地',
                                      value: '1',
                                    },
                                    {
                                      text: '土地＋建物（一棟）',
                                      value: '2',
                                    },
                                    {
                                      text: '土地＋建物（区分）',
                                      value: '3',
                                    },
                                  ]}
                                  value={proposalSearchParams.propertyTypeEq}
                                  onChange={(e) => {
                                    const values = Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value
                                    )
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyTypeEq: values,
                                    })
                                  }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              売主希望総額
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={proposalSearchParams.proposalsSuggestedTotalPriceGteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      proposalsSuggestedTotalPriceGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      proposalsSuggestedTotalPriceGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[suggested_total_price_gteq]"
                                  id="q_suggested_total_price_gteq"
                                  value={
                                    proposalSearchParams.proposalsSuggestedTotalPriceGteq
                                      ? intFormat(
                                          String(proposalSearchParams.proposalsSuggestedTotalPriceGteq)
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={proposalSearchParams.proposalsSuggestedTotalPriceLteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      proposalsSuggestedTotalPriceLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      proposalsSuggestedTotalPriceLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[suggested_total_price_lteq]"
                                  id="q_suggested_total_price_lteq"
                                  value={
                                    proposalSearchParams.proposalsSuggestedTotalPriceLteq
                                      ? intFormat(
                                          String(proposalSearchParams.proposalsSuggestedTotalPriceLteq)
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              現況表面利回り
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    proposalSearchParams.propertyCurrentProspectiveYieldGteq || ''
                                  }
                                  suffix="%"
                                  placeholder=""
                                  onChange={(e) =>
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyCurrentProspectiveYieldGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                      : ''
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyCurrentProspectiveYieldGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[current_prospective_yield_gteq]"
                                  id="q_current_prospective_yield_gteq"
                                  value={
                                    proposalSearchParams.propertyCurrentProspectiveYieldGteq
                                      ? dataPrecisionSetup(
                                          String(
                                            Math.round(
                                              Number(
                                                proposalSearchParams.propertyCurrentProspectiveYieldGteq
                                              ) * 100
                                            ) / 10000
                                          ),
                                          8,
                                          4
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    proposalSearchParams.propertyCurrentProspectiveYieldLteq || ''
                                  }
                                  suffix="%"
                                  placeholder=""
                                  onChange={(e) => {
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyCurrentProspectiveYieldLteq: e.target.value,
                                    })
                                  }}
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                      : ''
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyCurrentProspectiveYieldLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[current_prospective_yield_lteq]"
                                  id="q_current_prospective_yield_lteq"
                                  value={
                                    proposalSearchParams.propertyCurrentProspectiveYieldLteq
                                      ? dataPrecisionSetup(
                                          String(
                                            Math.round(
                                              Number(
                                                proposalSearchParams.propertyCurrentProspectiveYieldLteq
                                              ) * 100
                                            ) / 10000
                                          ),
                                          8,
                                          4
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              敷地面積
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                    style={{width: '142px'}}
                                    suffix="㎡"
                                    value={proposalSearchParams.propertyAreaM3Gteq}
                                    name="q[property_area_m3_gteq]"
                                    id="q_property_area_m3_gteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyAreaM3Gteq: e.target.value,
                                        })
                                    }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                    style={{width: '142px'}}
                                    suffix="㎡"
                                    value={proposalSearchParams.propertyAreaM3Lteq}
                                    name="q[property_area_m3_lteq]"
                                    id="q_property_area_m3_lteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyAreaM3Lteq: e.target.value,
                                        })
                                    }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              建物賃貸可能面積（専有面積）
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                    style={{width: '142px'}}
                                    suffix="㎡"
                                    value={proposalSearchParams.propertyOccupiedAreaGteq}
                                    name="q[property_occupied_area_gteq]"
                                    id="q_property_occupied_area_gteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyOccupiedAreaGteq: e.target.value,
                                        })
                                    }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                    style={{width: '142px'}}
                                    suffix="㎡"
                                    value={proposalSearchParams.propertyOccupiedAreaLteq}
                                    name="q[property_occupied_area_lteq]"
                                    id="q_property_occupied_area_lteq"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          propertyOccupiedAreaLteq: e.target.value,
                                        })
                                    }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              事業形態
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                  multiple
                                  className="text-sm bg-none"
                                  name="q[property_business_type_name_in][]"
                                  id="q_property_business_type_name_in"
                                  options={business_type_options}
                                  value={proposalSearchParams.propertyBusinessTypeEq}
                                  onChange={(e) => {
                                    const values = Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value
                                    )
                                    setProposalSearchParams({
                                      ...proposalSearchParams,
                                      propertyBusinessTypeEq: values,
                                    })
                                  }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              入手先
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <div className="flex gap-4">
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.sourceCont}
                                    name="q[source_cont]"
                                    id="q_source_cont"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          sourceCont: e.target.value,
                                        })
                                    }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                                className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              キーワード
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <div className="flex gap-4">
                                <Input
                                    style={{width: '190px'}}
                                    value={proposalSearchParams.byFreeword}
                                    name="q[property_by_freeword_from_proerties]"
                                    id="q_property_by_freeword_from_proerties"
                                    onChange={(e) =>
                                        setProposalSearchParams({
                                          ...proposalSearchParams,
                                          byFreeword: e.target.value,
                                        })
                                    }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="fixed bottom-0 left-0 right-0 border-t border-gray-150 flex gap-2 px-6 py-3 justify-end mr-[5%] bg-white z-10">
                      <AnchorButton
                          className="text-primary-font border-white w-[120px]"
                          prefix={<RefreshIcon fontSize="small"/>}
                          outline
                          size="small"
                          variant="primary"
                          href="/properties?showMap=true"
                      >
                        リセット
                      </AnchorButton>
                      <Button
                          className="text-white w-[120px]"
                          prefix={<SearchIcon fontSize="small"/>}
                          size="small"
                          variant="primary"
                      >
                        検索
                      </Button>
                    </div>

                    <input value={showMap ? 'true' : 'false'} type="hidden" name="showMap"/>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default PropertiesFilterModal
