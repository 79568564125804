import * as React from 'react'

import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { Textarea } from '@/components/Textarea'
import { Checkbox } from '@/components/Checkbox'
import { RadioButton } from '@/components/RadioButton'
import { Table } from '@/components/Table'
import { Pagination } from '@/components/Pagination'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Modal } from '@/components/Modal'
import { Button, AnchorButton } from '@/components/Button'
import { colors } from '@/utils/colors'

import {
  ListIcon,
  FilterIcon,
  BoxArrowInDownIcon,
  SearchIcon,
  ArrowClockwiseIcon,
  MapIcon,
  PlusIcon,
  ListUlIcon,
  PencilIcon,
  TrashIcon,
  InfomationIcon,
  LoadingSpinnerIcon,
} from '@/components/SvgIcon'

type Props = {
  title: string
  children: React.ReactNode
}

const ComponentBlock: React.FC<Props> = ({ title, ...props }) => {
  return (
    <div className="mb-10">
      <h2 className="mb-4 bg-gray-light py-2 px-4">{title}</h2>
      <div>{props.children}</div>
    </div>
  )
}

const Sample: React.FC = () => {
  return (
    <div className="max-w-screen-lg mx-auto py-5 md:py-10">
      <ComponentBlock title="input">
        <h3 className="my-3">base</h3>
        <Input onChange={(e) => console.log(e.target.value)} />
        <h3 className="my-3">with suffix</h3>
        <Input suffix="㎡" onChange={(e) => console.log(e.target.value)} />
        <h3 className="my-3">with prefix</h3>
        <Input prefix={<ListIcon fill="#333333" />} onChange={(e) => console.log(e.target.value)} />
        <h3 className="my-3">disabled</h3>
        <Input disabled />
      </ComponentBlock>

      <ComponentBlock title="checkbox">
        <h3 className="my-3">base</h3>
        <div className="flex gap-4">
          {[
            {
              text: 'option1',
              value: 1,
            },
            {
              text: 'option2',
              value: 2,
            },
            {
              text: 'option3',
              value: 3,
            },
          ].map((item, i) => (
            <Checkbox
              key={i}
              id={`item-checkbox-${i}`}
              text={item.text}
              value={item.value}
              onChange={(e) => console.log(e.target.checked)}
            />
          ))}
        </div>
        <h3 className="my-3">disabled</h3>
        <div className="flex gap-4">
          {[
            {
              text: 'option1',
              value: 1,
            },
            {
              text: 'option2',
              value: 2,
            },
            {
              text: 'option3',
              value: 3,
            },
          ].map((item, i) => (
            <Checkbox
              key={i}
              id={`item-checkbox-disabled-${i}`}
              text={item.text}
              value={item.value}
              disabled
            />
          ))}
        </div>
      </ComponentBlock>

      <ComponentBlock title="radio">
        <h3 className="my-3">base</h3>
        <div className="flex gap-4">
          {[
            {
              text: 'option1',
              value: 1,
            },
            {
              text: 'option2',
              value: 2,
            },
            {
              text: 'option3',
              value: 3,
            },
          ].map((item, i) => (
            <RadioButton
              key={i}
              name="sample"
              id={`item-radio-${i}`}
              text={item.text}
              value={item.value}
              onChange={(e) => console.log(e.target.checked)}
            />
          ))}
        </div>
        <h3 className="my-3">disabled</h3>
        <div className="flex gap-4">
          {[
            {
              text: 'option1',
              value: 1,
            },
            {
              text: 'option2',
              value: 2,
            },
            {
              text: 'option3',
              value: 3,
            },
          ].map((item, i) => (
            <RadioButton
              key={i}
              name="sample"
              id={`item-radio-disabled-${i}`}
              text={item.text}
              value={item.value}
              disabled
            />
          ))}
        </div>
      </ComponentBlock>

      <ComponentBlock title="select">
        <div className="mb-10">
          <h3 className="my-3">base</h3>
          <Select
            options={[
              {
                text: 'option1',
                value: 1,
              },
              {
                text: 'option2',
                value: 2,
              },
              {
                text: 'option3',
                value: 3,
              },
            ]}
            onChange={(e) => console.log(e.target.value)}
          />
          <h3 className="my-3">空の選択肢を表示</h3>
          <Select
            hasBlank
            options={[
              {
                text: 'option1',
                value: 1,
              },
              {
                text: 'option2',
                value: 2,
              },
              {
                text: 'option3',
                value: 3,
              },
            ]}
            onChange={(e) => console.log(e.target.value)}
          />
          <h3 className="my-3">disabled</h3>
          <Select
            disabled
            options={[
              {
                text: 'option1',
                value: 1,
              },
              {
                text: 'option2',
                value: 2,
              },
              {
                text: 'option3',
                value: 3,
              },
            ]}
            onChange={(e) => console.log(e.target.value)}
          />
        </div>
      </ComponentBlock>

      <ComponentBlock title="textarea">
        <h3 className="my-3">base</h3>
        <Textarea placeholder="入力してください" onChange={(e) => console.log(e.target.value)} />
        <h3 className="my-3">disabled</h3>
        <Textarea placeholder="入力してください" disabled />
      </ComponentBlock>

      <ComponentBlock title="ボタン">
        <div className="mb-10">
          <h3 className="my-3">base</h3>
          <AnchorButton href="/" target="_blank">
            リンクボタン
          </AnchorButton>
          <h3 className="my-3">width .w-[169px]</h3>
          <Button
            className="w-[169px]"
            onClick={() => {
              console.log('click')
            }}
          >
            ボタン
          </Button>
          <h3 className="my-3">wide</h3>
          <Button
            wide
            onClick={() => {
              console.log('click')
            }}
          >
            ボタン
          </Button>
          <h3 className="my-3">outline</h3>
          <div className="flex gap-4">
            <Button
              outline
              onClick={() => {
                console.log('click')
              }}
            >
              ボタン
            </Button>
            <Button
              outline
              variant="primaryLight"
              onClick={() => {
                console.log('click')
              }}
            >
              ボタン
            </Button>
          </div>
          <h3 className="my-3">with prefix icon</h3>
          <Button
            prefix={<ListIcon fill="#ffffff" />}
            onClick={() => {
              console.log('click')
            }}
          >
            ボタン
          </Button>
          <h3 className="my-3">with suffix icon</h3>
          <Button
            outline
            variant="gray"
            suffix={<ListIcon fill={colors.gray} />}
            onClick={() => {
              console.log('click')
            }}
          >
            ボタン
          </Button>
          <h3 className="my-3">variant</h3>
          <div className="flex flex-wrap gap-4">
            <Button
              variant="primary"
              onClick={() => {
                console.log('click')
              }}
            >
              primary
            </Button>
            <Button
              variant="primaryLight"
              onClick={() => {
                console.log('click')
              }}
            >
              primaryLight
            </Button>
            <Button
              variant="red"
              onClick={() => {
                console.log('click')
              }}
            >
              red
            </Button>
            <Button
              variant="redLight"
              onClick={() => {
                console.log('click')
              }}
            >
              redLight
            </Button>
            <Button
              variant="gray"
              onClick={() => {
                console.log('click')
              }}
            >
              gray
            </Button>
            <Button
              variant="grayLight"
              onClick={() => {
                console.log('click')
              }}
            >
              grayLight
            </Button>
            <Button
              variant="text"
              onClick={() => {
                console.log('click')
              }}
            >
              text
            </Button>
          </div>
          <h3 className="my-3">disabled</h3>
          <div className="flex flex-wrap gap-4">
            <Button variant="primary" disabled>
              primary
            </Button>
            <Button variant="primaryLight" disabled>
              primaryLight
            </Button>
            <Button variant="red" disabled>
              red
            </Button>
            <Button variant="redLight" disabled>
              redLight
            </Button>
            <Button variant="gray" disabled>
              gray
            </Button>
            <Button variant="grayLight" disabled>
              grayLight
            </Button>
            <Button variant="text" disabled>
              text
            </Button>
          </div>
          <h3 className="my-3">size</h3>
          <div className="flex gap-4 items-center">
            <Button
              variant="primary"
              onClick={() => {
                console.log('click')
              }}
            >
              default
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                console.log('click')
              }}
            >
              small
            </Button>
          </div>
        </div>
      </ComponentBlock>

      <ComponentBlock title="テーブル">
        <div className="mb-10">
          <h3 className="my-3">base</h3>
          <Table>
            <thead className="bg-gray-150 text-sm">
              <tr>
                <th scope="col" colSpan={2} className="py-2 px-4 font-medium">
                  No.
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  案件名
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  物件ID
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  入手日
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  th
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  th
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white hover:bg-150">
                <td className="py-2 px-4 text-right">1</td>
                <td className="py-2 px-4">東京都 新宿区 市ヶ谷加賀町</td>
                <td className="py-2 px-4">案件名</td>
                <td className="py-2 px-4">yyyy/mm/dd</td>
                <td className="py-2 px-4">Table</td>
                <td className="py-2 px-4">Table</td>
                <td className="py-2 px-4">Table</td>
              </tr>
              <tr className="bg-gray-light">
                <td className="py-2 px-4 text-right">2</td>
                <td className="py-2 px-4">東京都 新宿区 市ヶ谷加賀町</td>
                <td className="py-2 px-4">案件名</td>
                <td className="py-2 px-4">yyyy/mm/dd</td>
                <td className="py-2 px-4">Table</td>
                <td className="py-2 px-4">Table</td>
                <td className="py-2 px-4">Table</td>
              </tr>
              <tr className="bg-white">
                <td className="py-2 px-4 text-right">3</td>
                <td className="py-2 px-4">東京都 新宿区 市ヶ谷加賀町</td>
                <td className="py-2 px-4">案件名</td>
                <td className="py-2 px-4">yyyy/mm/dd</td>
                <td className="py-2 px-4">Table</td>
                <td className="py-2 px-4">Table</td>
                <td className="py-2 px-4">Table</td>
              </tr>
            </tbody>
          </Table>
          <h3 className="my-3">form</h3>
          <Table
            header={
              <div className="bg-gray-700 text-white text-xl font-medium py-3 px-4">所在地</div>
            }
          >
            <tbody>
              <tr className="bg-gray-light">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4"
                >
                  都道府県
                  <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                    必須
                  </span>
                </th>
                <td className="py-2 px-4 w-3/4">
                  <Input className="max-w-[246px]" />
                </td>
              </tr>
              <tr className="bg-white">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4"
                >
                  順番
                </th>
                <td className="py-2 px-4 w-3/4">
                  <Select
                    className="max-w-[246px]"
                    options={[
                      {
                        text: 'option1',
                        value: 1,
                      },
                      {
                        text: 'option2',
                        value: 2,
                      },
                      {
                        text: 'option3',
                        value: 3,
                      },
                    ]}
                    onChange={(e) => console.log(e.target.value)}
                  />
                </td>
              </tr>
              <tr className="bg-gray-light">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4"
                >
                  都道府県
                </th>
                <td className="py-2 px-4 w-3/4">
                  <Input className="max-w-[246px]" />
                </td>
              </tr>
              <tr className="bg-white">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4"
                >
                  順番
                </th>
                <td className="py-2 px-4 w-3/4">
                  <Select
                    className="max-w-[246px]"
                    options={[
                      {
                        text: 'option1',
                        value: 1,
                      },
                      {
                        text: 'option2',
                        value: 2,
                      },
                      {
                        text: 'option3',
                        value: 3,
                      },
                    ]}
                    onChange={(e) => console.log(e.target.value)}
                  />
                </td>
              </tr>
              <tr className="bg-gray-light">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4"
                >
                  都道府県
                </th>
                <td className="py-2 px-4 w-3/4">
                  <Input className="max-w-[246px]" />
                </td>
              </tr>
              <tr className="bg-white">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4"
                >
                  順番
                </th>
                <td className="py-2 px-4 w-3/4">
                  <Select
                    className="max-w-[246px]"
                    options={[
                      {
                        text: 'option1',
                        value: 1,
                      },
                      {
                        text: 'option2',
                        value: 2,
                      },
                      {
                        text: 'option3',
                        value: 3,
                      },
                    ]}
                    onChange={(e) => console.log(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </ComponentBlock>

      <ComponentBlock title="検索エリア">
        <h3 className="mb-6 text-[1.75rem] leading-[2.5rem] text-black">物件検索</h3>
        <div className="flex flex-wrap md:flex-nowrap items-center">
          <div className="p-4 w-full">
            <div className="mb-3 text-base leading-none text-black-base whitespace-nowrap">
              都道府県
            </div>
            <Select
              className="w-full"
              options={[
                {
                  text: 'option1',
                  value: 1,
                },
                {
                  text: 'option2',
                  value: 2,
                },
                {
                  text: 'option3',
                  value: 3,
                },
              ]}
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div className="p-4 w-full">
            <div className="mb-3 text-base leading-none text-black-base whitespace-nowrap">
              市区町村
            </div>
            <Select
              className="w-full"
              options={[
                {
                  text: 'option1',
                  value: 1,
                },
                {
                  text: 'option2',
                  value: 2,
                },
                {
                  text: 'option3',
                  value: 3,
                },
              ]}
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div className="p-4 w-full">
            <div className="mb-3 text-base leading-none text-black-base whitespace-nowrap">
              丁目
            </div>
            <Select
              className="w-full"
              options={[
                {
                  text: 'option1',
                  value: 1,
                },
                {
                  text: 'option2',
                  value: 2,
                },
                {
                  text: 'option3',
                  value: 3,
                },
              ]}
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div className="p-4 w-full">
            <div className="mb-3 text-base leading-none text-black-base whitespace-nowrap">
              丁目以降（住居表示）
            </div>
            <Select
              className="w-full"
              options={[
                {
                  text: 'option1',
                  value: 1,
                },
                {
                  text: 'option2',
                  value: 2,
                },
                {
                  text: 'option3',
                  value: 3,
                },
              ]}
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-4 items-center justify-center">
          <Button
            className="w-[169px]"
            prefix={<ArrowClockwiseIcon fill={colors.gray} />}
            variant="text"
          >
            リセット
          </Button>
          <Button
            className="w-[169px]"
            prefix={<SearchIcon fill={colors.white} />}
            variant="primary"
          >
            検索
          </Button>
        </div>
      </ComponentBlock>

      <ComponentBlock title="フィルターエリア">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center gap-1 whitespace-nowrap">
            <span className="text-primary-font text-2xl font-medium">146,673</span>
            <span className="text-gray-800 text-sm mt-0.5">件中 1～30件 表示</span>
          </div>
          <div className="flex flex-wrap items-center gap-6">
            <div className="flex gap-2 items-center">
              <span className="text-xs text-gray-800 whitespace-nowrap">並び替え</span>
              <Select
                className="min-w-24"
                size="small"
                options={[
                  {
                    text: 'option1',
                    value: 1,
                  },
                  {
                    text: 'option2',
                    value: 2,
                  },
                  {
                    text: 'option3',
                    value: 3,
                  },
                ]}
                onChange={(e) => console.log(e.target.value)}
              />
            </div>
            <div className="flex gap-2 items-center">
              <span className="text-xs text-gray-800 whitespace-nowrap">表示建物数</span>
              <Select
                className="min-w-24"
                size="small"
                options={[
                  {
                    text: 'option1',
                    value: 1,
                  },
                  {
                    text: 'option2',
                    value: 2,
                  },
                  {
                    text: 'option3',
                    value: 3,
                  },
                ]}
                onChange={(e) => console.log(e.target.value)}
              />
            </div>
            <Button prefix={<FilterIcon fill={colors.primary} />} outline size="small">
              絞り込み
            </Button>
            <Button
              prefix={<BoxArrowInDownIcon fill={colors.primary} />}
              outline
              size="small"
              variant="primaryLight"
            >
              CSVダウンロード
            </Button>
          </div>
        </div>
      </ComponentBlock>

      <ComponentBlock title="パンくずリスト">
        <Breadcrumb />
      </ComponentBlock>

      <ComponentBlock title="ページネーション">
        <Pagination pagy={1} />
      </ComponentBlock>

      <ComponentBlock title="アイコン">
        <div className="flex gap-2">
          <ListIcon />
          <FilterIcon />
          <BoxArrowInDownIcon />
          <SearchIcon />
          <ArrowClockwiseIcon />
          <MapIcon />
          <PlusIcon />
          <ListUlIcon />
          <PencilIcon />
          <TrashIcon />
          <InfomationIcon />
          <LoadingSpinnerIcon />
        </div>
      </ComponentBlock>

      <ComponentBlock title="モーダル">
        <Modal title="open dialog" />
      </ComponentBlock>
    </div>
  )
}

export default Sample
