import * as React from 'react'

import { Button } from '@/components/Button'
import { Tab } from '@headlessui/react'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import {
  PropertyMarketDataReport,
  PropertyMarketDataReportProps,
} from './Surrounding/PropertyMarketDataReport'

import { Table } from '@/components/Table'
import { filenameFromHeaders } from '@/utils/fetch'
import { cover_enterprise_pricing_type } from '@/utils/policy'

import { MarketDatum } from '@/types/marketDatum'
import { saveAs } from 'file-saver'
import { toJpeg } from 'html-to-image'

import { LoadSpinner } from '@/components/Loading/LoadSpinner'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'

export type TabType = 'for_sale' | 'for_rent'
export type CheckedDataReport = { for_sale: []; for_rent: [] }
type Step = 'select' | 'edit' | 'download'

const PropetyMarketDatumReport: React.FC<
  PropertyMarketDataReportProps & { children: React.ReactNode }
> = ({ children, ...others }) => {
  const initialTab =
    (new URL(window.location.href).searchParams.get('data_type') as TabType) || 'for_sale'
  const [checkedDataReport, setCheckedDataReport] = React.useState<CheckedDataReport>({
    for_sale: [],
    for_rent: [],
  })

  const [step, setStep] = React.useState<Step>('select')
  const [tab, setTab] = React.useState<TabType>(initialTab)
  const [downloadDisabled, setDownloadDisabled] = React.useState<boolean>(false)

  const [isLoading, setIsLoading] = React.useState(false)

  const forSale = tab === 'for_sale'
  const forRent = tab === 'for_rent'

  const download = () => {
    setDownloadDisabled(true)
    document
      .querySelectorAll('.custom-map-control-button, .gmnoprint, .gm-fullscreen-control')
      .forEach((element) => {
        element.remove()
      })

    const map = document.getElementById('property_market_data_report_map')

    toJpeg(map, { cacheBust: true, backgroundColor: 'white', includeQueryParams: true })
      .then(async (dataUrl) => {
        await downloadReport(dataUrl)
        setIsLoading(false)
        window.location.reload()
      })
      .catch((error) => {
        console.error(error)
        alert('帳票を出力できません。')
        window.location.reload()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const prepareReport = () => {
    const lenForSale = checkedDataReport['for_sale'].length
    const lenForRent = checkedDataReport['for_rent'].length

    if ((forSale && lenForSale === 0) || (forRent && lenForRent === 0)) {
      alert('周辺事例をチェックしてください')
      return
    }

    setStep('edit')
    const box = document.getElementById('property_market_data_report_map')
    box.style.width = '100%'
    const map = box.lastChild as HTMLDivElement
    map.style.width = '100%'
    map.style.height = ''
    map.style.aspectRatio = '2'
  }

  const downloadReport = (base64_image: string) => {
    const marketData = checkedDataReport[tab]
    const data = {}

    marketData.forEach((marketDatum) => {
      const key = marketDatum['id']
      const value = marketDatum['selectNo']

      data[key] = value
    })

    return fetch('/market_datum_report', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': others.authenticity_token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data,
        base64_image, // image/png
        property_id: others.property.id,
        data_type: tab,
      }),
    })
      .then(async (res) => {
        if (res.ok) {
          const filename = filenameFromHeaders(res)
          const blob = await res.blob()
          saveAs(blob, filename)
        } else {
          alert('帳票を出力できません。')
        }
      })
      .finally(() => {
        setDownloadDisabled(false)
        setStep('select')
      })
  }

  return (
    <>
      <div className={`block ${step === 'edit' || isLoading ? 'w-full' : ''}`}>
        <LoadingOverlay isOpen={isLoading} />
        <div id="property_market_data_report_map" className="">
          {step === 'edit' && (
            <ReportDownloadBox
              downloadDisabled={downloadDisabled}
              callback={() => {
                setStep('download')
                setIsLoading(true)
                setTimeout(download, 100)
                setTimeout(() => setIsLoading(false), 60 * 1000)
              }}
            />
          )}
          {children}
          {step === 'download' && <NoteBox forSale={forSale} />}
        </div>
      </div>

      {step === 'select' && (
        <ReportSelectBox
          checkedDataReport={checkedDataReport}
          setCheckedDataReport={setCheckedDataReport}
          prepareReport={prepareReport}
          forSale={forSale}
          forRent={forRent}
          tab={tab}
          setTab={setTab}
          downloadDisabled={downloadDisabled}
          {...others}
        />
      )}
    </>
  )
}

const ReportDownloadBox = (props: { downloadDisabled; callback }) => {
  return (
    <div className="flex flex-row py-4">
      <div className="flex-none text-md px-4 whitespace-normal text-primary font-small">
        位置を調節してください
      </div>
      <div className="flex-grow" />
      <Button
        className="flex-none text-primary-font py-2 h-[38px]"
        prefix={<CloudDownloadIcon fontSize="small" />}
        outline
        size="small"
        variant="primary"
        disabled={props.downloadDisabled}
        onClick={props.callback}
      >
        <span className="hidden md:inline">帳票出力</span>
      </Button>
    </div>
  )
}

const ReportSelectBox = ({
  property,
  mapRef,
  current_user,
  authenticity_token,
  market_datum_report_layouts,
  market_datum_report_column_names,
  forSale,
  forRent,
  prepareReport,
  tab,
  setTab,
  checkedDataReport,
  setCheckedDataReport,
  downloadDisabled,
  prefecture_options,
  transportation_options,
  station_options,
  floor_plan_options,
  contractor_options,
  room_type_options,
}: {
  forSale: boolean
  forRent: boolean
  prepareReport: () => void
  tab: TabType
  setTab: React.Dispatch<React.SetStateAction<any>>
  checkedDataReport: CheckedDataReport
  setCheckedDataReport: React.Dispatch<React.SetStateAction<any>>
  downloadDisabled: boolean
} & PropertyMarketDataReportProps) => {
  const initialTab = new URL(window.location.href).searchParams.get('data_type') || 'for_sale'
  const tabIndexForSale = 0
  const tabIndexForRent = tabIndexForSale + 1
  const defaultIndex = initialTab === 'for_sale' ? tabIndexForSale : tabIndexForRent

  const setCheckedDataReportDataType = (data_type: any, marketData: MarketDatum[]) => {
    const newData = { ...checkedDataReport }

    newData[data_type] = marketData

    setCheckedDataReport(newData)
  }

  const onChangeTab = (index: number) => {
    setTab(tabFromIndex(index))

    // タブ切り替え時に表示していた周辺事例のピンをクリアする
    if (mapRef?.current) {
      mapRef.current.clearMarketData()
    }
    // タブ切り替え時には検索条件を全てリセットする
    const searchParams = new URLSearchParams(window.location.search.replace(/\?.*$/, ''))
    const dataTypeName = index === 0 ? 'for_sale' : 'for_rent'
    searchParams.set('data_type', dataTypeName)
    searchParams.set('tab', 'market_datum_report')
    location.search = searchParams.toString()
  }

  return (
    <div className="ml-4 overflow-x-auto">
      <Tab.Group defaultIndex={defaultIndex} onChange={onChangeTab}>
        <div className="flex justify-between text-sm overflow-x-auto whitespace-nowrap">
          <Tab.List className="flex items-center border-b-[1px] border-primary mb-2">
            {cover_enterprise_pricing_type(current_user) && (
              <Tab
                className={`${styles['tab']} ${styles[forSale ? 'true' : 'false']} border-l-[1px]`}
              >
                周辺売買事例
              </Tab>
            )}
            {cover_enterprise_pricing_type(current_user) && (
              <Tab className={`${styles['tab']} ${styles[forRent ? 'true' : 'false']}`}>
                周辺賃貸事例
              </Tab>
            )}
          </Tab.List>
          <Button
            className="text-primary-font py-2 h-[38px]"
            prefix={<CloudDownloadIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            disabled={downloadDisabled}
            onClick={prepareReport}
          >
            <span className="hidden md:inline">帳票出力</span>
          </Button>
        </div>
        <Tab.Panels>
          {cover_enterprise_pricing_type(current_user) && (
            <Tab.Panel unmount={false}>
              <PropertyMarketDataReport
                property={property}
                data_type={'for_sale'}
                tabIndex={tabIndexForSale}
                tab={tab}
                mapRef={mapRef}
                current_user={current_user}
                authenticity_token={authenticity_token}
                market_datum_report_layouts={market_datum_report_layouts}
                market_datum_report_column_names={market_datum_report_column_names}
                setCheckedDataReport={setCheckedDataReportDataType}
                prefecture_options={prefecture_options}
                transportation_options={transportation_options}
                station_options={station_options}
                floor_plan_options={floor_plan_options}
                contractor_options={contractor_options}
                room_type_options={room_type_options}
              />
            </Tab.Panel>
          )}
          {cover_enterprise_pricing_type(current_user) && (
            <Tab.Panel unmount={false}>
              <PropertyMarketDataReport
                property={property}
                data_type={'for_rent'}
                tabIndex={tabIndexForRent}
                tab={tab}
                mapRef={mapRef}
                current_user={current_user}
                authenticity_token={authenticity_token}
                market_datum_report_layouts={market_datum_report_layouts}
                market_datum_report_column_names={market_datum_report_column_names}
                setCheckedDataReport={setCheckedDataReportDataType}
                prefecture_options={prefecture_options}
                transportation_options={transportation_options}
                station_options={station_options}
                floor_plan_options={floor_plan_options}
                contractor_options={contractor_options}
                room_type_options={room_type_options}
              />
            </Tab.Panel>
          )}
          <Tab.Panel unmount={false}></Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

const sale_rows = [
  ['物件番号', '状態'],
  ['価格', '構造'],
  ['表面利回り', '築年数'],
]

const rent_rows = [
  ['物件番号', '状態'],
  ['賃料(管理費込)', '賃料坪単価(管理費込)'],
  ['専有面積', '築年数', '構造'],
]

export const NoteBox = ({ forSale }: { forSale: boolean }) => {
  const rows = forSale ? sale_rows : rent_rows
  return (
    <div className="text-sm">
      <style>{`
        .no-scrollbar::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Edge */
        }
        .no-scrollbar {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
      `}</style>
      <div className="flex flex-col whitespace-nowrap justify-center items-center">
        <Table className="border no-scrollbar w-[35%]">
          <tr>
            <th className="flex h-[2rem] pb-4 justify-center bg-gray-150">凡例</th>
          </tr>
          {rows.map((row, index) => (
            <tr className="flex h-[2rem] pb-4">
              <td className={`flex md:w-1/3 px-6`}>{row[0]}</td>
              <td className={`flex md:w-1/${rows[index].length} px-6`}>{row[1]}</td>
              {row[2] && <td className={`flex md:w-1/${rows[index].length} px-6`}>{row[2]}</td>}
            </tr>
          ))}
        </Table>
      </div>
    </div>
  )
}

const styles = {
  tab: `
    inline-flex
    outline-none
    select-none
    text-sm
    py-2
    px-8
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
  true: `
    bg-primary
    text-white
  `,
  false: `
    text-primary-font
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
}

const tabFromIndex = (index: number): TabType => {
  switch (index) {
    case 0:
      return 'for_sale'
    case 1:
      return 'for_rent'
  }
}

const chunkArray = (arr) => {
  const chunkSize = 10
  const chunkedArray = []

  for (let i = 0; i < arr.length; i += chunkSize) {
    chunkedArray.push(arr.slice(i, i + chunkSize) || {})
  }
  return chunkedArray
}

export default PropetyMarketDatumReport
