import * as React from 'react'

import { Checkbox } from '@/components/Checkbox'
import { ChildOption, Option } from '@/components/DropDown/Index'
import { MapHandles } from '@/components/GoogleMapViewer'
import { SearchModal } from '@/components/Page/MarketData/SearchModal'
import { Pagination } from '@/components/Pagination'
import { Table } from '@/components/Table'
import { Pagy } from '@/types/pagy'

import type { MarketDatum } from '@/types/marketDatum'
import type { AttachedFile, Property } from '@/types/property'
import type { User } from '@/types/user'

import { cammedFormat } from '@/utils/cammedFormat'
import { boundingBox } from '@/utils/geo'
import { TabType } from '../PropetyMarketDatumReport'

type DataType = 'for_sale' | 'for_rent'

type Response = {
  market_data: MarketDatum[]
  attached_files: {
    [key: number]: AttachedFile
  }
}

export type PropertyMarketDataReportProps = {
  property: Property
  data_type: DataType
  tabIndex: number
  tab: TabType
  mapRef?: React.RefObject<MapHandles>
  current_user: User
  authenticity_token: string
  market_datum_report_layouts?: any
  market_datum_report_column_names?: string[]
  setCheckedDataReport?
  prefecture_options: Option[]
  transportation_options: Option[]
  station_options: ChildOption[]
  floor_plan_options: any[]
  contractor_options: any[]
  room_type_options: any[]
}

export const PropertyMarketDataReport: React.FC<PropertyMarketDataReportProps> = ({
  property,
  data_type,
  tabIndex,
  tab,
  mapRef,
  authenticity_token,
  market_datum_report_layouts,
  market_datum_report_column_names,
  setCheckedDataReport,
  prefecture_options,
  transportation_options,
  station_options,
  floor_plan_options,
  contractor_options,
  room_type_options,
}) => {
  const dataTypeText = dataTypeToText[data_type]

  const [tabInit, setTabInit] = React.useState<boolean>(false)
  const [data, setData] = React.useState<Response>({
    market_data: [],
    attached_files: {},
  })
  const [marketData, setMarketData] = React.useState<MarketDatum[]>([])
  const [marketDataIds, setMarketDataIds] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(true)
  const [message, setMessage] = React.useState<string>(`${dataTypeText}のデータを取得中です。`)
  const [pagy, setPagy] = React.useState<Pagy>(pagyInitial)
  const [pagingData, setPagingData] = React.useState<MarketDatum[]>([])
  const [setOpen] = React.useState<boolean>(false)
  const [checked, setChecked] = React.useState<boolean>(false)
  const [checkedData, setCheckedData] = React.useState<MarketDatum[]>([])
  const [selectedOrder, setSelectedOrder] = React.useState<number[]>([])

  const pagyOrFiltered = pagy

  const navigate = (page: number) => {
    const pagy = pagyFromData(marketData, page)
    const pagingData = pagingDataFromPagy(marketData, pagy)

    setPagy(pagy)
    setPagingData(pagingData)
  }

  // const onClose = (selectedOption?: Option) => {
  //   setOpen(false)

  //   if (selectedOption) {
  //     // 選択した状態で絞り込み
  //     const marketData = filterStatus(data.market_data, selectedOption)
  //     const pagy = pagyFromData(marketData)
  //     const pagingData = pagingDataFromPagy(marketData, pagy)
  //     const checkedData = marketData.filter((datum) => datum.checked)

  //     setStatusOption(selectedOption)
  //     setMarketData(marketData)
  //     setPagy(pagy)
  //     setPagingData(pagingData)

  //     // 絞り込んだ状態のマーカーを表示
  //     mapRef.current.clearMarketData()

  //     setCheckedData(checkedData)
  //     setCheckedDataReport(data_type, marketData)
  //     mapRef.current.setMarketDataReport(checkedData)
  //   }
  // }

  const onCheckAll = (nextState: boolean) => {
    setChecked(nextState)

    const data = [...marketData]
    let newOrder = [...selectedOrder]

    data.forEach((marketDatum, index) => {
      marketDatum.checked = nextState
      if (nextState) {
        // チェックが入った場合、配列の最後に追加
        newOrder = [...newOrder, marketDatum.id]
        marketDatum.selectNo = index + 1
      } else {
        // チェックが外れた場合、該当するIDを削除
        marketDatum.selectNo = null
      }
    })

    setMarketData(data)
    mapRef.current.clearMarketData()

    if (nextState) {
      setCheckedData(data)
      setCheckedDataReport(data_type, data)
      mapRef.current.setMarketDataReport(marketData)
    } else {
      setCheckedData([])
      setCheckedDataReport(data_type, [])
      newOrder = []
    }
    setSelectedOrder(newOrder)
  }

  const onCheck = (nextState: boolean, marketDatum: MarketDatum) => {
    marketDatum.checked = nextState

    const data = marketData.filter((datum) => datum.checked)
    const uncheckedData = marketData.filter((datum) => !datum.checked)

    let newOrder = [...selectedOrder]

    if (nextState) {
      // チェックが入った場合、配列の最後に追加
      newOrder = [...newOrder, marketDatum.id]
    } else {
      // チェックが外れた場合、該当するIDを削除
      newOrder = newOrder.filter((orderId) => orderId !== marketDatum.id)
    }
    setSelectedOrder(newOrder)

    // 配列の順に従ってselectNoカラムを更新
    data.forEach((record) => {
      const index = newOrder.indexOf(record.id)
      if (index !== -1) {
        record.selectNo = index + 1
      } else {
        record.selectNo = null
      }
    })
    // チェックが入っていないデータはselectNoカラムを全てnullに
    uncheckedData.forEach((record) => {
      record.selectNo = null
    })

    setCheckedData(data)
    setCheckedDataReport(data_type, data)
    // ヘッダーのチェックボックスを連動させる
    if (marketData.every((item) => item.checked === true)) {
      setChecked(true)
    } else {
      setChecked(false)
    }

    mapRef.current.clearMarketData()
    mapRef.current.setMarketDataReport(data)
  }

  React.useEffect(() => {
    // タブがアクティブになった場合
    if (data_type === tab && mapRef) {
      // Mapにピンを表示（タブ切替時も絞り込みを反映）
      mapRef.current.setMarketDataReport(checkedData || marketData)
    }
  }, [tab, mapRef, marketDataIds])

  React.useEffect(() => {
    if (data_type === tab && !tabInit) {
      setTabInit(true)
      const currentParams = new URLSearchParams(window.location.search)
      // 円範囲のパラメータが設定されている場合、検索領域をデフォルトから変換する
      const distanceParam = currentParams.get('distance')
      const distanceParamNum = parseInt(distanceParam)

      const sendDistance =
        distanceParamNum !== null && !isNaN(distanceParamNum) ? distanceParamNum + 10 : distance

      // 円範囲の中心と外接する矩形領域の東西の経度と南北の緯度を指定
      const latlng = boundingBox(property.lat, property.lng, sendDistance)
      const latlngParams = new URLSearchParams(latlng)

      // is_search が true の場合、検索用パラメーターを取得
      const isSearch = currentParams.get('is_search')
      const sendParams =
        isSearch === 'true'
          ? new URLSearchParams([...latlngParams, ...currentParams])
          : latlngParams
      const path = `/api/market_data/${data_type}?${sendParams.toString()}`

      // 地図に対象となるピンを全て表示する必要があるため、最初にデータを一括して取得します
      fetch(path, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticity_token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 'OK') {
            if (json.data && json.data.market_data && json.data.market_data.length > 0) {
              const data = json.data
              const marketData = data.market_data
              const marketDataIds = marketData.map((market_datum) => market_datum.id).join(',')
              const pagy = pagyFromData(marketData)
              const pagingData = pagingDataFromPagy(marketData, pagy)

              setMessage('')
              setData(data)
              setMarketData(marketData)
              setMarketDataIds(marketDataIds)
              setPagy(pagy)
              setPagingData(pagingData)
              setLoading(false)
            } else {
              setMessage(`${dataTypeText}のデータはありません。`)
            }
          } else {
            console.log(`${dataTypeText}の取得に失敗しました。message=${json.message}`)
            setMessage(`${dataTypeText}の取得に失敗しました。`)
          }
        })
        .catch((e) => {
          console.log(`${dataTypeText}の取得に失敗しました。`)
          setMessage(`${dataTypeText}の取得に失敗しました。`)
        })
    }
  })

  return (
    <div className="mb-2">
      <div className="flex flex-wrap items-end justify-between">
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          <span className="text-primary-font text-xl font-medium">
            {pagy.count.toLocaleString()}
          </span>
          <span className="text-gray-800 text-sm mt-0.5">
            件中 {pagyOrFiltered.from}～{pagyOrFiltered.to}件 表示
          </span>
          <span className="ml-2">
            <Pagination pagy={pagyOrFiltered} navigate={navigate} />
          </span>
        </div>

        <div className="flex ml-auto gap-1 pt-1 md:gap-2 md:pt-0">
          <SearchModal
            title="条件絞り込み"
            prefecture_options={prefecture_options}
            transportation_options={transportation_options}
            station_options={station_options}
            floor_plan_options={floor_plan_options}
            contractor_options={contractor_options}
            room_type_options={room_type_options}
            page_type={data_type}
            is_properties={true}
            search_location={window.location}
          ></SearchModal>
        </div>
      </div>

      <div className="my-2">
        <Table
          borderLine={false}
          topScrollbar={window.innerWidth > 768}
          scrollDep={data[0]}
          className="h-[495px]"
        >
          <thead className="bg-gray-150 text-sm sticky top-0 z-10">
            <tr className="whitespace-nowrap">
              <th scope="col" className="py-2 px-4 font-medium" key={'th-check'}>
                <span className="flex justify-between items-center">
                  <Checkbox
                    border={'gray-700'}
                    classNameBox={'m-0-i'}
                    checked={checked}
                    onChange={(e) => {
                      const nextState = !checked

                      onCheckAll(nextState)
                    }}
                  />
                </span>
              </th>
              {columnsReportDataType(data_type, market_datum_report_layouts).map((column) => (
                <th scope="col" className="py-2 px-4 font-medium" key={`th-${column}`}>
                  {market_datum_report_column_names[column] || columnNames[column]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {loading ? (
              <tr>
                <td></td>
                <td className="py-8" colSpan={26}>
                  {message}
                </td>
              </tr>
            ) : (
              pagingData.map((marketDatum) => (
                <tr
                  className="whitespace-nowrap hover:bg-targetLine"
                  key={`market-datum-${marketDatum['no']}`}
                >
                  <td
                    scope="col"
                    className="py-2 px-4 font-medium"
                    key={`market-datum-td-${marketDatum['no']}-check`}
                  >
                    <Checkbox
                      border={'gray-700'}
                      classNameBox={'m-0-i'}
                      checked={marketDatum.checked}
                      onChange={(e) => {
                        const nextState = !marketDatum.checked

                        onCheck(nextState, marketDatum)
                      }}
                    />
                  </td>
                  {columnsReportDataType(data_type, market_datum_report_layouts).map((column) =>
                    column === 'attached_file' && data.attached_files[marketDatum.id] ? (
                      <td
                        scope="col"
                        className="py-2 px-4 font-medium"
                        key={`market-datum-td-${marketDatum['no']}-${column}`}
                      >
                        {formatColumn(marketDatum, column, data.attached_files)}
                      </td>
                    ) : (
                      <td
                        scope="col"
                        className="py-0"
                        key={`market-datum-td-${marketDatum['no']}-${column}`}
                      >
                        <a
                          className="block w-full min-h-[36px] py-2 px-4 font-medium"
                          href={detailPath(marketDatum, property, tabIndex)}
                          target="_blank"
                        >
                          {formatColumn(marketDatum, column, data.attached_files)}
                        </a>
                      </td>
                    )
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

// const FilterModal: React.FC<{
//   open: boolean
//   statusOption: Option
//   onClose: (selectedOption?: Option) => void
// }> = ({ open, statusOption, setStatusOption, onClose }) => {
//   const [selectedOption, setSelectedOption] = React.useState<Option>(statusOption)

//   return (
//     <Modal
//       title={'条件絞り込み'}
//       open={open}
//       onClose={() => {
//         setSelectedOption(statusOption)
//         onClose(null)
//       }}
//     >
//       <div className="mt-2">
//         <div className="p-4">
//           <div>
//             <div className="flex items-center">
//               <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
//                 状態
//               </div>
//               <div className="py-2 px-4 w-3/4">
//                 <DropDown
//                   className="text-sm"
//                   defaultValue={selectedOption}
//                   options={statusOptions}
//                   onChange={(statusOption) => {
//                     setSelectedOption(statusOption)
//                   }}
//                   isClearable={false}
//                   isSearchable={false}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="border-t border-gray-150"></div>
//       <div className="flex gap-2 px-6 py-3 justify-end">
//         <AnchorButton
//           className="text-primary-font border-white w-[120px]"
//           prefix={<RefreshIcon fontSize="small" />}
//           outline
//           size="small"
//           variant="primary"
//           onClick={() => {
//             setSelectedOption(statusOptionInitial)
//             onClose(statusOptionInitial)
//           }}
//         >
//           リセット
//         </AnchorButton>
//         <Button
//           className="text-white w-[120px]"
//           prefix={<SearchIcon fontSize="small" />}
//           size="small"
//           variant="primary"
//           onClick={() => {
//             onClose(selectedOption)
//           }}
//         >
//           検索
//         </Button>
//       </div>
//     </Modal>
//   )
// }

const statusOptions = [
  { label: '募集中 + 成約', value: '' },
  { label: '募集中', value: 'open' },
  { label: '成約', value: 'close' },
]
const statusOptionInitial = statusOptions[0]

// 物件の緯度経度から3000m（徒歩約20分圏内）+ 10m（数メートルの誤差を考慮）
const distance = 3000 + 10

// 1ページ20件
const perPage = 20

const pagyInitial = {
  page: 1,
  pages: 1,
  count: 0,
  from: 0,
  to: 0,
  prev: false,
  next: false,
}
const pagyFiltered = {
  page: 1,
  pages: 1,
  count: 1,
  from: 1,
  to: 1,
  prev: false,
  next: false,
}

const dataTypeToText = {
  for_sale: '売買事例',
  for_rent: '賃貸事例',
}

const columns = {
  for_sale: [
    'selectNo',
    'name',
    'address',
    'property_type',
    'building_type_text',
    'status_text',
    'current_rent',
    'current_noi',
    'full_prospective_rent',
    'full_prospective_noi',
    'sale_price',
    'sale_prospective_yield',
    'sale_prospective_noi_yield',
    'contract_prospective_noi_yield',
    'building_price',
    'prospective_yield',
    'area_m3',
    'area_m3_tsubo',
    'effective_area',
    'effective_area_tsubo',
    'total_floor_area',
    'total_floor_area_tsubo',
    'occupied_area',
    'occupied_area_tsubo',
    'floor_plan',
    'floors',
    'floor',
    'building_structure_short',
    'build_date',
    'build_age',
    'contractor',
    'number_of_units',
    'transportation_names',
    'road_access_names',
    'elevator',
    'auto_lock',
    'publication_date',
    'contract_date',
    'attached_file',
    'user_name_with_department',
  ],
  for_rent: [
    'selectNo',
    'name',
    'address',
    'property_type',
    'building_type_text',
    'status_text',
    'rent',
    'maintenance_fee',
    'rent_maintenance_fee',
    'rent_maintenance_fee_unit_price',
    'rent_maintenance_fee_unit_price_tsubo',
    'security_deposit',
    'key_money',
    'area_m3',
    'area_m3_tsubo',
    'effective_area',
    'effective_area_tsubo',
    'total_floor_area',
    'total_floor_area_tsubo',
    'occupied_area',
    'occupied_area_tsubo',
    'floor_plan',
    'floors',
    'floor',
    'build_date',
    'build_age',
    'contractor',
    'location_of_division',
    'orientation_text',
    'room_type',
    'building_structure_short',
    'transportation_names',
    'road_access_names',
    'elevator',
    'auto_lock',
    'balcony',
    'free_internet',
    'furniture_and_appliances',
    'publication_date',
    'contract_date',
    'attached_file',
    'user_name_with_department',
  ],
}

const columnNames = {
  selectNo: 'No',
  name: '物件名',
  address: '所在地',
  property_type: '物件種目',
  building_type_text: '物件タイプ',
  status_text: '状態',
  floor_plan: '間取り',
  building_structure_short: '構造',
  transportation_names: '交通機関',
  road_access_names: '道路',
  elevator: 'エレベーター',
  auto_lock: 'オートロック',
  publication_date: '掲載日',
  contract_date: '成約日',
  attached_file: '関連資料',
  user_name_with_department: '登録者',
  current_rent: '現況賃料(税込)/月額',
  current_noi: '現況NOI/月額',
  full_prospective_rent: '満室想定賃料(税込)/月額',
  full_prospective_noi: '満室想定NOI/月額',
  sale_price: '売出価格',
  sale_prospective_yield: '売出表面利回り',
  sale_prospective_noi_yield: '売出NOI利回り',
  building_price: '成約価格',
  prospective_yield: '成約表面利回り',
  contract_prospective_noi_yield: '成約NOI利回り',
  floors: '建物階数',
  floor: '所在階',
  number_of_units: '総戸数',
  rent: '賃料',
  maintenance_fee: '管理費',
  rent_maintenance_fee: '賃料（管理費込み）',
  rent_maintenance_fee_unit_price: '賃料（管理費込み）㎡単価',
  rent_maintenance_fee_unit_price_tsubo: '賃料（管理費込み）坪単価',
  security_deposit: '敷金',
  key_money: '礼金',
  area_m3: '敷地面積 (㎡)',
  area_m3_tsubo: '敷地面積 (坪)',
  effective_area: '有効敷地面積 (㎡)',
  effective_area_tsubo: '有効敷地面積 (坪)',
  total_floor_area: '延床面積 (㎡)',
  total_floor_area_tsubo: '延床面積 (坪)',
  occupied_area: '専有面積 (㎡)',
  occupied_area_tsubo: '専有面積 (坪)',
  build_date: '竣工年月',
  build_age: '築年数',
  contractor: '施工会社',
  location_of_division: '部屋番号',
  orientation_text: '開口向き',
  room_type: '部屋タイプ',
  balcony: 'バルコニー',
  free_internet: 'インターネット無料',
  furniture_and_appliances: '家具家電',
}
const formatter = new Intl.NumberFormat('ja-JP')

const columnsReport = {
  for_sale: columns.for_sale.filter((column) => column !== 'attached_file'),
  for_rent: columns.for_rent.filter((column) => column !== 'attached_file'),
}

const columnsReportDataType = (data_type: DataType, market_datum_report_layouts) => {
  const columns = market_datum_report_layouts[data_type]

  return columns && columns.length > 0
    ? ['selectNo', 'name', 'address'].concat(columns)
    : columnsReport[data_type]
}

const detailPath = (marketDatum, property, tabIndex) => {
  return `/market_data/${marketDatum['hashid']}/?property_id=${property.hashid}&tab_index=${tabIndex}`
}

const formatColumn = (marketDatum: MarketDatum, column: string, attached_files: AttachedFile[]) => {
  const value = marketDatum[column]

  if (endsWithNumber(column)) {
    return value
  } else if (
    [
      'building_price',
      'rent',
      'maintenance_fee',
      'rent_maintenance_fee',
      'rent_maintenance_fee_unit_price',
      'rent_maintenance_fee_unit_price_tsubo',
      'security_deposit',
      'key_money',
      'current_rent',
      'current_noi',
      'full_prospective_rent',
      'full_prospective_noi',
      'sale_price',
    ].includes(column) &&
    (value || value === 0)
  ) {
    return `${formatter.format(value)}円`
  } else if (column === 'attached_file' && attached_files[marketDatum.id]) {
    const attachedFile = attached_files[marketDatum.id]

    return (
      <a className="text-primary" href={attachedFile.url} target="_blank">
        {attachedFile.filename}
      </a>
    )
  } else if (
    [
      'prospective_yield',
      'sale_prospective_yield',
      'sale_prospective_noi_yield',
      'contract_prospective_noi_yield',
    ].includes(column) &&
    value
  ) {
    return `${formatter.format(Math.round(value * 10000) / 100)}%`
  } else if (
    ['area_m3', 'effective_area', 'total_floor_area', 'occupied_area'].includes(column) &&
    value
  ) {
    return `${cammedFormat(value, 2)}㎡`
  } else if (
    [
      'area_m3_tsubo',
      'effective_area_tsubo',
      'total_floor_area_tsubo',
      'occupied_area_tsubo',
    ].includes(column) &&
    value
  ) {
    return `${cammedFormat(value, 2)}坪`
  } else if (['number_of_units'].includes(column) && value) {
    return `${value}戸`
  } else if (['build_age'].includes(column) && value) {
    return value === 0 || value === '新築' ? '新築' : `${value}`
  } else if (['walk', 'bus'].includes(column) && (value || value === 0)) {
    return `${value}分`
  } else if (column === 'transportation_names') {
    return marketDatum[column]?.length > 0 ? (
      <>
        {marketDatum[column].map((name, i) => (
          <span key={`transportation-${i}`}>
            {i === 0 ? (
              name
            ) : (
              <>
                <br />
                {name}
              </>
            )}
          </span>
        ))}
      </>
    ) : (
      <span className="block w-full h-full py-2 px-4"></span>
    )
  } else if (column === 'road_access_names') {
    return marketDatum[column].length > 0 ? (
      <>
        {marketDatum[column].map((name, i) => (
          <span key={`road_access-${i}`}>
            {i === 0 ? (
              name
            ) : (
              <>
                <br />
                {name}
              </>
            )}
          </span>
        ))}
      </>
    ) : (
      <span className="block w-full h-full py-2 px-4"></span>
    )
  } else if (column === 'building_type_text') {
    return (
      marketDatum['custom_building_type'] ||
      (marketDatum['building_type'] ? marketDatum['building_type_text'] : undefined)
    )
  } else if (column === 'floor' && value) {
    return marketDatum['floor_type_text']
      ? `${marketDatum['floor_type_text']}${value}階`
      : `${value}階`
  } else {
    return value || <span className="block w-full h-full py-2 px-4"></span>
  }
}

const endsWithNumber = (column: string): boolean => {
  return (
    column.endsWith('_0') || column.endsWith('_3') || column.endsWith('_4') || column.endsWith('_6')
  )
}

const filterStatus = (data: MarketDatum[], statusOption: Option): MarketDatum[] => {
  return statusOption.value
    ? data.filter((market_datum: MarketDatum) => market_datum.status === statusOption.value)
    : data
}

const pagyFromData = (data: MarketDatum[], page = 1): Pagy => {
  const count = data.length
  const pages = Math.ceil(count / perPage)
  const from = count === 0 ? 0 : perPage * (page - 1) + 1
  const remainCount = count - perPage * (page - 1)
  const to = count === 0 ? 0 : remainCount >= perPage ? from + perPage - 1 : from + remainCount - 1
  const prev = page > 1
  const next = pages - page > 0

  return {
    page: page,
    pages: pages,
    count: count,
    from: from,
    to: to,
    prev: prev,
    next: next,
  }
}

const pagingDataFromPagy = (data: MarketDatum[], pagy: Pagy): MarketDatum[] => {
  const from = pagy.from - 1
  const to = pagy.to
  return data.slice(from, to)
}
