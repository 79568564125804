import * as React from 'react'
import InputForm from './InputForm'
import { UserContext } from '@/components/Layout/Base'

type Props = {
  property: any
  plan_number: string
  onChangeParameters?: () => void
  ref_volume_check_parameters?: any
}

const FundamentalsForm: React.FC<Props> = ({
  property,
  plan_number,
  onChangeParameters,
  ref_volume_check_parameters,
}) => {
  const address = property.city + property.town + property.chome
  const projectName = property.name || address
  const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null
  const ref_project_name = parameters ? parameters.project_name : null
  const ref_plan_name = parameters ? parameters.plan_name : null
  const ref_plan_number = parameters ? parameters.plan_number : null
  const ref_manager_name = parameters ? parameters.manager_name : null
  const ref_building_use = parameters ? parameters.building_use : null
  const ref_building_structure = parameters ? parameters.building_structure : null
  const ref_unit_area = parameters ? parameters?.building_setting?.unit_area : null
  const { user } = React.useContext(UserContext)

  const [fundamentals, setFundamentals] = React.useState({
    project_name: ref_project_name ? ref_project_name : projectName,
    plan_name: ref_plan_name ? ref_plan_name : '',
    plan_number: ref_plan_number ? ref_plan_number : plan_number,
    manager_name: ref_manager_name ? ref_manager_name : user.name,
    building_use: ref_building_use ? ref_building_use : '共同住宅',
    building_structure: ref_building_structure ? ref_building_structure : 'RC',
    unit_area: ref_unit_area ? ref_unit_area : '25',
  })

  return (
    <div>
      <InputForm
        name="project_name"
        title="案件名"
        value={fundamentals.project_name}
        placeholder={`例）${projectName}（5階建)`}
        onChange={(e) => {
          setFundamentals({ ...fundamentals, project_name: e.target.value })
          onChangeParameters()
        }}
      />
      <InputForm
        name="plan_name"
        title="建築プラン名"
        value={fundamentals.plan_name}
        placeholder="例）5階建"
        onChange={(e) => {
          setFundamentals({ ...fundamentals, plan_name: e.target.value })
          onChangeParameters()
        }}
      />
      <InputForm
        name="plan_number"
        title="プランNo."
        value={fundamentals.plan_number}
        onChange={(e) => {
          setFundamentals({ ...fundamentals, plan_number: e.target.value })
          onChangeParameters()
        }}
      />
      <InputForm
        name="manager_name"
        title="作成者名"
        value={fundamentals.manager_name}
        onChange={(e) => {
          setFundamentals({ ...fundamentals, manager_name: e.target.value })
          onChangeParameters()
        }}
      />
      <InputForm
        name="building_use"
        title="建物用途"
        value={fundamentals.building_use}
        readOnly={true}
        onChange={(e) => {
          setFundamentals({ ...fundamentals, building_use: e.target.value })
          onChangeParameters()
        }}
      />

      <InputForm
        name="building_structure"
        title="建物構造"
        value={fundamentals.building_structure}
        readOnly={true}
        onChange={(e) => {
          setFundamentals({ ...fundamentals, building_structure: e.target.value })
          onChangeParameters()
        }}
      />
      <InputForm
        name="unit_area"
        title="希望住戸面積"
        value={fundamentals.unit_area}
        unit="㎡"
        placeholder="18〜40に入力してください"
        onChange={(e) => {
          setFundamentals({ ...fundamentals, unit_area: e.target.value })
          onChangeParameters()
        }}
        onBlur={(e) => {
          let value = parseFloat(e.target.value)
          if (isNaN(value) || value < 18) value = 18
          if (value > 40) value = 40
          setFundamentals({ ...fundamentals, unit_area: value.toString() })
        }}
      />
    </div>
  )
}

export default FundamentalsForm
